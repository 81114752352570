import React from "react";
import moment from "moment";

const formatDate = date => {
    if (date) {
        let dateSplit = date.split(" ");
        return (
            moment(dateSplit[0]).format("MM/DD/YY") +
            " " +
            moment(dateSplit[1], "HH:mm:ss").format("hh:mm A")
        );
    }
};


const MediaStatisticsComponent = ({media}) => {
    return (
        <div className="media-statistics-wrapper">
            <ul>
                <li>
                    <div className="media-stats-icon-wrapper">
                        <img className="media-stats-icon"
                            src={`${process.env.PUBLIC_URL}/editicons/viewsicon.png`} alt=""/>
                    </div>
                    <div>
                        {`Views  ${media.mediaviewcount}`}
                    </div>
                </li>
                <li>
                    <div className="media-stats-icon-wrapper">
                        <img className="media-stats-icon"
                             src={`${process.env.PUBLIC_URL}/editicons/recordedicon.png`} alt=""/>
                    </div>
                    <div>
                        {`Recorded  ${formatDate(media.mediacreateddate)}`}
                    </div>
                </li>
                <li>
                    <div className="media-stats-icon-wrapper">
                        <img className="media-stats-icon"
                             src={`${process.env.PUBLIC_URL}/editicons/uploadedicon.png`} alt=""/>
                    </div>
                    <div>
                        {`Uploaded  ${formatDate(media.mediapublisheddate)}`}
                    </div>
                </li>
                <li>
                    <div className="media-stats-icon-wrapper">
                        <img className="media-stats-icon"
                             src={`${process.env.PUBLIC_URL}/editicons/publishedicon.png`} alt=""/>
                    </div>
                    <div>
                        {`Published`}
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default MediaStatisticsComponent;
