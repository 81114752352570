import React from "react"
import {compose, withProps} from "recompose"
import {adjustMap} from "../Networks/mapHelperFunctions/index"
import {OverlayView, Polyline, withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"

const API_KEY = "AIzaSyDz6O9SSK16WR6h3xA_h4SsS1MdkkwF_lI";

const MyMapComponent = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `100%`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <div>
        <GoogleMap
            defaultZoom={props.zoom}
            center={props.initialCenter}
            ref={props.handleMount}
            isMarkerShown={true}
        >
            {props.polyLines.map((line, i) =>
                <Polyline
                    path={line.polyLine[0]}
                    options={{
                        strokeColor: line.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 5
                    }}
                    key={i}
                />
            )}
            {props.multipleMarkersArray ?
                <div>
                    {props.multipleMarkersArray.map((marker, i) =>
                        <div key={Math.random()}>
                            {marker.dbm &&
                            <OverlayView
                                key={marker.cid}
                                position={{
                                    lat: parseFloat(marker.lat),
                                    lng: parseFloat(marker.lng)
                                }}
                                mapPaneName={"overlayMouseTarget"}
                            >
                                <div>
                                    <div className="signal-container-overlay">
                                        <div
                                            style={{width: '100%'}}
                                            className="signal-display-wrapper"
                                        >
                                            <div className="signal-orange"
                                                 style={{width: props.calcSignal('orange', marker.dbm)}}
                                            >
                                            </div>
                                            <div className="signal-white"
                                                 style={{width: props.calcSignal('white', marker.dbm)}}
                                            >
                                            </div>
                                            <div className="signal-text-overlay">
                                                <div id="text-signal">{marker.dbm}(dBm)</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OverlayView>
                            }
                            <Marker
                                position={{
                                    lat: parseFloat(marker.lat),
                                    lng: parseFloat(marker.lng)
                                }}
                                icon={{
                                    scaledSize: new window.google.maps.Size(25, 35),
                                    url: props.iconUrl ? props.iconUrl : '',
                                    labelOrigin: new window.google.maps.Point(12, 45),
                                    // anchor: new window.google.maps.Point(65, 65),
                                }}
                                // label={{
                                //     text: marker.cid ? "CID: #" + marker.cid : marker.name,
                                //     color: "white",
                                //     fontSize: "12px",
                                //     height: "50px",
                                //     width: "150px",
                                //     background: "black"
                                // }}
                            />
                            <OverlayView
                                key={Math.random()}
                                position={{
                                    lat: parseFloat(marker.lat),
                                    lng: parseFloat(marker.lng)
                                }}
                                mapPaneName={"overlayMouseTarget"}
                            >
                                <div className="label-background">
                                    <div className="label-text">
                                        {marker.cid ? "CID: #" + marker.cid : marker.name}
                                    </div>
                                </div>
                            </OverlayView>
                        </div>
                    )
                    }
                </div>
                :
                <div>
                    {
                        //Marker for Location Component Map
                    }
                    <Marker
                        icon={{
                            scaledSize: new window.google.maps.Size(20, 20),
                            url: props.iconUrl ? props.iconUrl : '',
                            labelOrigin: new window.google.maps.Point(12, 28),
                            anchor: new window.google.maps.Point(14, 14),
                        }}
                        ref={props.handleMarkerMount}
                        position={props.initialCenter}
                    />
                </div>
            }
        </GoogleMap>
    </div>
);

class ReactGoogleMaps extends React.PureComponent {
    state = {
        isMarkerShown: false,
        polyLines: []
    };

    componentDidMount() {
        this.delayedShowMarker();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.polylineColor) {
                if (this.props.polylineColor === 'blue') {
                    this.generateSegments('blue', this.props.polylineColor.summaryFrameData);
                } else {
                    this.generateSegments('green', this.props.polylineColor.summaryFrameData);
                    this.generateSegments('yellow', this.props.polylineColor.summaryFrameData);
                }
            }
        }
    }

    generateTitle = (array) => {
        if (array.name) {
            return array.name
        }
        if (array.network) {
            return array.network
        }
    };

    generateSegments = (color, colorArray) => {
        let segments = [];

        if (colorArray) {
            for (let i = 0; i < colorArray.length; i++) {
                if (colorArray[i] !== colorArray[i + 1]) {
                    segments.push({
                        color: colorArray[i - 1],
                        index: i
                    });
                }
            }
        }
        this.drawPolyline(segments);
    };

    drawPolyline = (segments) => {
        let cordOutput = [];
        let section = [];

        if (segments) {
            for (let i = 0; i < segments.length; i++) {
                if (i === 0) {
                    let copy = [...this.props.polylineCoordinates];
                    section = copy.slice(0, segments[i]['index']);
                } else {
                    let copy = [...this.props.polylineCoordinates];
                    section = copy.slice(segments[i - 1]['index'], segments[i]['index']);
                }

                let polylineArray = [];

                polylineArray.push(section);
                cordOutput.push({
                    color: segments[i]['color'],
                    polyLine: polylineArray
                })
            }
            this.setState({
                polyLines: cordOutput
            });
        }
    };

    convertToNum = (coordObj) => {
        let numCoord = {};
        if (coordObj) {
            numCoord['lng'] = Number(coordObj['lng']);
            numCoord['lat'] = Number(coordObj['lat']);
            return numCoord
        }
    };

    calcSignal = (color, dmb) => {
        let num = parseInt(dmb);
        let numPos = Math.abs(num);
        let signalStr = Math.round((numPos / 110) * 100);

        if (color === 'orange') {
            return signalStr + '%'
        }
        if (color === 'white') {
            return 100 - signalStr + '%'
        }
    };

    handleMount = map => {
        const {multipleMarkersArray} = this.props;
        this.map = map;

        if (this.map) {
            adjustMap(multipleMarkersArray, this.map)
        }
    };
    
    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({isMarkerShown: true})
        }, 3000)
    };

    render(props) {
        return (
            <MyMapComponent
                isMarkerShown={this.state.isMarkerShown}
                zoom={this.props.zoom}
                initialCenter={this.convertToNum(this.props.initialCenter)}
                generateTitle={this.generateTitle}
                iconUrl={this.props.iconUrl}
                multipleMarkersArray={this.props.multipleMarkersArray}
                calcSignal={this.calcSignal}
                handleMount={this.handleMount}
                handleMarkerMount={this.handleMarkerMount}
                polyLines={this.state.polyLines}
            />
        )
    }
}

export default ReactGoogleMaps
