import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./networks.css"
import ToggleAccordion from "./ToggleAccordion";
import ReactGoogleMaps from "../Location/ReactGoogleMaps";
import {getTraceData} from "../../MediaSingle/actions"
import TraceRoutesMap from "./TraceRoutesMap";


class Networks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartCreated: false,
            traceRouteFetched: false
        };
    }

    // shouldComponentUpdate(nextProps) {
    //     const {equals} = this;
    //     return equals(nextProps);
    // }

    equals = (next) => {
        // If incoming props are null or empty dont re-render
        if (next.sliderValue !== this.props.sliderValue) {
            return true
        }
        if (next.connectionData.satellites.length === 0) {
            return false
        } else {
            return true
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {traceroute} = this.props.connectionData;
        const {getTraceData} = this.props;
        const {traceRouteFetched} = this.state;

        if (!traceRouteFetched && traceroute) {
            getTraceData(this.cleanTraceStr(traceroute));
            this.setState({
                traceRouteFetched: true
            })
        }
    }

    cleanTraceStr = (str) => {
        let traceRoutes = str.split("\n");
        let spacesRemovedArray = traceRoutes.join(" ");
        return spacesRemovedArray
    };

    generateCellTowerCenter = (cellArray) => {
        //Only generating for a non array of towers
        if (cellArray) {
            if (cellArray.length > 1) {
            } else {
                return {
                    lat: cellArray[0].lat,
                    lng: cellArray[0].lng
                }
            }
        }
    };

    calcSignal = (color, dmb) => {
        let num = parseInt(dmb);
        let numPos = Math.abs(num);
        let signalStr = Math.round((numPos / 110) * 100);

        if (color === 'orange') {
            return signalStr + '%'
        }
        if (color === 'white') {
            return 100 - signalStr + '%'
        }
    };

    generateSatData = () => {
        const {satellites} = this.props.connectionData;

        return (
            <div className="time-sat-alt-wrapper">
                {satellites.map(({name, altitude, number, head, lat, lng}) =>
                    <div className="satellite-data-group"
                         key={name}
                    >
                        <div className="satellite-wrapper">
                            <div className="satellite-row labels-row">
                                <div>Name:</div>
                                <div>Latitude:</div>
                                <div>Longitude:</div>
                                <div>Altitude:</div>
                                <div>Heading:</div>
                            </div>
                        <div className="satellite-row">
                                <div>{name}</div>
                                <div>{lat}</div>
                                <div>{lng}</div>
                                <div>{altitude} Meters</div>
                                <div>{Math.round(head)}°</div>
                        </div>
                        </div>
                    </div>
                )}
            </div>
        )
    };

    generateSignalBar = (celltower) => {
        return (
            <div className="signal-container">
                <div className="signal-title">Signal:</div>
                <div
                    style={{width: '100%'}}
                    className="signal-display-wrapper"
                >
                    <div className="signal-orange"
                         style={{width: this.calcSignal('orange', celltower.dbm)}}
                    >
                    </div>
                    <div className="signal-white"
                         style={{width: this.calcSignal('white', celltower.dbm)}}
                    >
                    </div>
                    <div className="signal-text-wrapper">
                    <div className="signal-text">
                        <div id="text-signal">{celltower.dbm}(dBm)</div>
                    </div>
                </div>
            </div>
            </div>
        )
    };

    generateCellData = () => {
        const {celltowers} = this.props.connectionData;
        let lightMode = 'black';
        let darkMode = 'white';

        return (
            <div>
                {celltowers.map(celltower =>
                    <div className="satellite-location-data"
                         key={celltower.cid}
                    >
                        <div className="satellite-location-data-col-1">
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                CID:&nbsp;
                                <span
                                    style={{
                                        fontSize: '.8rem',
                                        fontWeight: '700'
                                    }}>
                                    {celltower.cid}
                                </span>
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                Operator:&nbsp;
                                <span
                                    style={{
                                        fontSize: '.8rem',
                                        fontWeight: '700'
                                    }}>
                                {celltower.network}
                                </span>
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                Country:&nbsp;
                                <span
                                    style={{
                                        fontSize: '.8rem',
                                        fontWeight: '700'
                                    }}>
                                {celltower.country}
                                </span>
                            </div>
                        </div>
                        <div className="satellite-location-data-col-2">
                            {this.generateSignalBar(celltower)}
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                Type:&nbsp;
                                <span
                                    style={{
                                        fontSize: '.8rem',
                                        fontWeight: '700'
                                    }}>
                                {celltower.type}
                                </span>
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                LAC:&nbsp;
                                <span
                                    style={{
                                        fontSize: '.8rem',
                                        fontWeight: '700'
                                    }}>
                                    {celltower.lac}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    };

    generateInternetData = () => {
        const {traceRoutesData} = this.props;

        return (
            <div className="internet-data-wrapper">
                <div className="internet-date-time-wrapper">
                    {/*<div>12/25/2009 99:99:00</div>*/}
                </div>
                <div className="trace-grid-container">
                    <div>Hop</div>
                    <div>Router</div>
                    <div>Location</div>
                </div>
                {
                    //slicing first index of array null ip data currently
                }
                {Object.keys(traceRoutesData).slice(1).map((trace, i) =>
                    <div className="trace-grid-data"
                         key={i}
                    >
                        <div className="hop">{i + 1}</div>
                        <div>{trace}</div>
                        <div>{traceRoutesData[trace]["loc"]}</div>
                    </div>
                )}
            </div>
        )
    };

    generateStartFinishMarkers = (traceArray) => {
        let startFinishMarkers = [];

        if (traceArray) {
            startFinishMarkers.push(traceArray[0]);
            startFinishMarkers[0]['iconUrl'] = `${process.env.PUBLIC_URL}/PinGreen2x.png`;
            startFinishMarkers.push(traceArray[traceArray.length - 1]);
            startFinishMarkers[1]['iconUrl'] = `${process.env.PUBLIC_URL}/PinRed2x.png`;
        }

        return startFinishMarkers
    };

    handleCellMessage = (OS,celltowers) =>{
        if(OS !== 'iOS' && !celltowers){
            return <div style={{textAlign:"center"}} className="satellite-android">Cell tower information is missing</div>
        }
        return <div className="satellite-android">Cell tower information is not available for
            recordings created by IPhones</div>
    };

    render() {
        const {generateSatData, generateCellData, generateInternetData, generateStartFinishMarkers} = this;
        const {
            encryptionData: {
                devicetime
            },
            connectionData: {
                satellites,
                osname,
                celltowers,
                worldclockdate
            },
            sliderValue,
            traceRoutePolyline
        } = this.props;
        let lightMode = 'black';
        let darkMode = 'white';


        return (
            <div className="networks-container"
                 style={{background: !this.props.lightView ? `rgb(203,76,87,${sliderValue * .01})` : 'white'}}
            >
                <div
                >
                    <div className="component-header-wrapper">
                        <div
                            className="component-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            NETWORKS
                        </div>
                        <div className="networks-component-icon">
                            {/*<i*/}
                            {/*    className="fa fa-cloud-upload"*/}
                            {/*    aria-hidden="true"*/}
                            {/*    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                            {/*</i>*/}
                            <img src={`${process.env.PUBLIC_URL}/networks-full.png`} alt=""/>
                        </div>
                    </div>
                    <div className="satellite-map-title">
                        <div>Internet</div>
                    </div>
                    <div className="location-map-wrapper">
                        <div className="satellite-map-container">
                            {traceRoutePolyline.length > 0 ?
                                <TraceRoutesMap
                                    zoom={4}
                                    polylineCoordinates={traceRoutePolyline}
                                    startFinishMarkers={generateStartFinishMarkers(traceRoutePolyline)}
                                /> : null
                            }
                        </div>
                    </div>
                    <ToggleAccordion
                        contentBlock={() => generateInternetData()}
                    />
                    <div className="satellite-map-title">
                        <div>Satellites</div>
                        <div>{worldclockdate + " " + devicetime}</div>
                    </div>
                    <div className="location-map-wrapper">
                        <div className="satellite-map-container">
                            {satellites.length > 0 ?
                                <ReactGoogleMaps
                                    zoom={4}
                                    multipleMarkersArray={satellites}
                                    iconUrl={`${process.env.PUBLIC_URL}/SatelliteIcon.png`}
                                /> : null
                            }
                        </div>
                    </div>
                    <ToggleAccordion
                        contentBlock={() => generateSatData()}
                    />
                    <div className="satellite-map-title">
                        <div>Cell Towers</div>
                        <div>{worldclockdate + " " + devicetime}</div>
                    </div>
                    <div className="location-map-wrapper">
                        <div className="satellite-map-container">
                            {
                                (osname !== 'iOS' && celltowers) ?
                                    <ReactGoogleMaps
                                        zoom={10}
                                        initialCenter={
                                            this.generateCellTowerCenter(celltowers)
                                        }
                                        multipleMarkersArray={celltowers}
                                        iconUrl={`${process.env.PUBLIC_URL}/CellTowerBase.png`}
                                    />
                                    :
                                    this.handleCellMessage(osname,celltowers)
                                    // <div className="satellite-android">Cell tower information is not available for
                                    //     recordings created by IPhones</div>
                            }
                        </div>
                    </div>
                    {
                        osname !== 'iOS' && celltowers &&
                        <ToggleAccordion
                            contentBlock={() => generateCellData()}
                        />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        sliderValue: state.mediaSingleReducer.sliderValue,
        traceRoutePolyline: state.mediaSingleReducer.traceRoutesCoord,
        traceRoutesData: state.mediaSingleReducer.traceRoutes
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTraceData: (payload) => {
            dispatch(getTraceData(payload))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Networks))
