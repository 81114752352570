import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {toggleEdit} from "../actions/index";
import "./mediacomponent.css";
import moment from "moment";
import ReputationScore from "../ReputationScore/ReputationScore";
import EditShareModal from "../EditShareModal/EditShareModal";

class ListMediaComponent extends React.Component {
    componentDidMount() {
    }

    handleChannel = () => {
        const {clientchannel} = this.props
        if (clientchannel) {
            return `/${clientchannel}`
        }
        return ''
    };

    formatDate = date => {
        if (date !== null) {
            let dateSplit = date.split(" ");
            return (
                moment(dateSplit[0]).format("MM/DD/YY") +
                " " +
                moment(dateSplit[1], "HH:mm:ss").format("hh:mm A")

            );
        }
    };

    shortenLength = (str, type) => {
        let limit = type === "title" ? 40 : 70;
        if (str) {
            if (str.length > limit) {
                let shortenedDesc = str.substring(0, limit);
                return shortenedDesc + ' ...';
            } else {
                return str
            }
        }
    };

    capitalizeFirstLetter = (string) => {
        if (string)
            return string.charAt(0).toUpperCase() + string.slice(1);
    };

    addDefaultSrc = (ev) => {
        ev.target.src = `${process.env.PUBLIC_URL}/videoCoverTemp.png`
    };

    render() {
        const {media, toggleEdit, clientchannel} = this.props;
        const {formatDate, shortenLength, capitalizeFirstLetter} = this;

        return (
            <div className="listview-container">
                <div className="list-view-wrapper">
                  <div className="left-media-image">
                      <Link
                          to={`${this.handleChannel()}/${media.mediatype[0]}/${
                              media.mediakey
                              }`}
                          onClick={this.handleMediaToken}
                      >
                          <div className="list-media-img-duration-wrap">
                                  <img
                                      onError={this.addDefaultSrc}
                                      className="media-cover-image"
                                      src={
                                          media.mediacoverurl
                                              ? media.mediacoverurl
                                              : `${process.env.PUBLIC_URL}/videoCoverTemp.png`
                                      }
                                      alt="Video"
                                  />
                              {media.mediatype !== 'image' &&
                                  <div className="list-media-component-timeclock">
                                      {media.mediaduration}
                                  </div>
                              }
                              {media.subbarurl &&
                                  <div className="list-subbar-wrapper">
                                      <img className="list-bar-img" src={media.subbarurl} alt="" />
                                  </div>
                              }
                          </div>
                      </Link>
                  </div>
                    <div className="">
                        <div className="media-title-list">
                            <div className='listview-title-wrapper'>
                                {this.props.media.mediatitle ? shortenLength(this.props.media.mediatitle, "title") : 'No Title'}
                                {/*<Link*/}
                                {/*    to={`${this.handleChannel()}/${media.mediatype[0]}/${*/}
                                {/*        media.mediakey*/}
                                {/*    }`}*/}
                                {/*    onClick={toggleEdit}*/}
                                {/*>*/}
                                {/*    <i className="fa fa-ellipsis-v fa-2x edit-ellipsis" aria-hidden="true"*/}
                                {/*       style={{color: 'black'}}> </i>*/}
                                {/*</Link>*/}
                                <EditShareModal
                                    watchurl={`/${clientchannel}/${media.mediatype[0] }/${media.mediakey}`}
                                    shareurl={media.mediasocialshareurl}
                                    mediasharemethod={media.mediasharemethod}
                                    iconstyle={{color:"darkgrey",fontSize:'1.5rem'}}

                                />
                            </div>
                            <div className="media-list-description">
                                {shortenLength(media.mediadescription) ? shortenLength(media.mediadescription, "description") : 'No Description'}
                            </div>
                            <div className="media-list-date-time">
                                Published: {formatDate(media.mediapublisheddate)}
                            </div>
                            <div className="media-list-date-time">
                                Created: {formatDate(media.mediacreateddate)}
                            </div>
                            <div className="public-views-wrapper">
                                {capitalizeFirstLetter(media.mediasharemethod)}
                                <span
                                    style={{
                                        color: 'black',
                                        padding: '2px'
                                    }}>|</span>
                                {media.mediaviewcount} views
                            </div>
                        </div>
                    </div>
                </div>
                <ReputationScore summaryBullets={media.summarybullet} viewCss={'reputation-list-view'}/>
            </div>

        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        params: params,
        loginToken: state.loginReducer.authentication.loginToken,
        clientchannel: state.loginReducer.authentication.clientchannel
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleEdit: () => {
            dispatch(toggleEdit())
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ListMediaComponent)
);
