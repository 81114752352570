import React from "react";
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom'

import MediaInformationComponent from "./MediaInformationComponent";
import EditThumbnailComponent from './EditThumbnailComponent';

import {deleteMedia, toggleEdit, updateMedia, clearQuery} from "../actions/index";
import EditInputComponent from "../EditInputComponent/EditInputComponent";
import EditShareModal from "../EditShareModal/EditShareModal";
import {toggleMobileSocialShare} from "../EditShareModal/toggleMobileSocialShare";


class DeskTopMediaEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shareurl:""
        };
    };

    render() {
        const {
            media, createTimeStampArray, handleSelectImageThumbnail,
            handleEdit, clientchannel, handleDeleteCheckbox, error, updateMessage,
            handleUpdate, handleUndoChanges, clearQuery
        } = this.props;
        const {formFields, duration, deleteCheckboxValue, editing} = this.props.editState;
        return (
            <div className="edit-cont-center">
                <div className="edit-nav-header row">
                    <div className="return-nav-wrapper">
                        <Link to={`/${clientchannel ? clientchannel : "login"}`}
                        onClick={clearQuery}
                        >
                            <div className="return-nav btn">
                                <div className="return-arrow-icon">
                                    <i className="fa fa-arrow-left" aria-hidden="true">
                                    </i>
                                    <div className="return-title">VIDEOLIST</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="save-changes-wrapper">
                        <div className="save-changes">
                            <button className={`btn btn1 ${editing ? "editing-button1" : ""}`}
                                    onClick={handleUndoChanges}>UNDO CHANGES
                            </button>
                            <button className={`btn btn2 ${editing ? "editing-button2" : ""}`} name="btn2"
                                    onClick={handleUpdate}>SAVE
                            </button>
                            {/*<i className="fa fa-ellipsis-v fa-2x " aria-hidden="true"*/}
                            {/*   style={{color: 'darkgrey'}}> </i>*/}
                            <EditShareModal
                                watchurl={`/${clientchannel}/${media.mediatype[0] }/${media.mediakey}`}
                                shareurl={media.mediasocialshareurl}
                                setShareUrl={this.setShareUrl}
                                mediasharemethod={media.mediasharemethod}
                                iconstyle={{color:"darkgrey"}}
                                containerStyle={{width: "100%"}}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-content-wrapper">
                    <div className="col-sm-8">
                        <div className="media-component-info-list">
                            <EditInputComponent
                                stateFormFields={formFields.mediatitle}
                                changeField={handleEdit}
                                name='mediatitle'
                                placeholder="Title (required)"
                                maxLength={"140"}
                                setHeight={20}
                            />
                            <EditInputComponent
                                stateFormFields={formFields.mediadescription}
                                changeField={handleEdit}
                                name='mediadescription'
                                placeholder="Description"
                                maxLength={"400"}
                                setHeight={44.6}
                            />
                        </div>
                        <EditThumbnailComponent
                            media={media}
                            createTimeStampArray={createTimeStampArray}
                            handleSelectImageThumbnail={handleSelectImageThumbnail}
                            duration={duration}
                        />
                    </div>
                    <div className="col-sm-4">
                        <MediaInformationComponent
                            media={media}
                            error={error}
                            updateMessage={updateMessage}
                            mediacoverurl={formFields.mediacoverurl}
                            stateFormFields={formFields}
                            changeField={handleEdit}
                            deleteCheckboxValue={deleteCheckboxValue}
                            handleDeleteCheckbox={handleDeleteCheckbox}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.MediaReducer.error,
        updateMessage: state.mediaSingleReducer.updateMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        },
        clearQuery: () => {
            dispatch(clearQuery())
        }
    }
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DeskTopMediaEdit)
);
