import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./desktopnavpopup.css"
import {login} from "../actions";

class LoginPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordError: '',
            type: "search"
        };
    }

    handleSubmit = e => {
        const {history, login} = this.props;
        e.preventDefault();

        const email = this.getEmail.value;
        const password = this.getPassword.value;

        // if (!email) {
        //     alert("Email address is required.");
        //     return;
        // } else if (!password) {
        //     this.setState({
        //         passwordError: 'Wrong Password. Try again or click Forgot password to reset'
        //     })
        // }
        login(email, password);
        history.push('/login')
    };

    placeholderInputTypeAction = (value) => {
        value === '' ?
            this.setState({
                type: 'search'
            })
            :
        this.setState({
            type: 'password'
            });
    };

    render() {
        const {back, loginErrorMessage} = this.props;
        const {placeholderInputTypeAction} = this;

        return (
            <div>
                <div onClick={() => back('Home')}>
                    <img className="back-arrow" src={`${process.env.PUBLIC_URL}/arrow_back.png`} alt=""/>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className='panel-message'>Please login</div>
                    <div className="panel-form-input-container">
                        <input
                            required
                            type="email"
                            className="input-style"
                            placeholder="Email"
                            ref={input => (this.getEmail = input)}
                        />
                        <div className="form-space"></div>
                        <input
                            required
                            className="input-style"
                            id="formBasicPassword"
                            placeholder="Password"
                            onChange={(e) => placeholderInputTypeAction(e.target.value)}
                            type={this.state.type}
                            ref={input => (this.getPassword = input)}
                        />
                    </div>
                    <div className="password-error-wrapper">
                        {loginErrorMessage}
                    </div>
                    <div className="button-container-center">
                        <button type="submit" className="navpop-up-blue-btn remove-styles-btn">
                            Login
                        </button>
                    </div>
                </form>
                <div className="forgot-password-wrapper">
                    <div onClick={() => back('Reset')}>Forgot Password?</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        email: state.loginReducer.authentication.email,
        loginToken: state.loginReducer.authentication.loginToken,
        loginErrorMessage: state.desktopNavReducer.loginErrorMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        login: (email, password) => {
            dispatch(login(email, password));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPanel))
