import React from 'react'
import './ModalComponent.css'
const WarningModal = (props) => (
    <div className="modal fade" id="warningModal" tabIndex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>
                        Private recordings are only visible to you.<br/>
                        If you want to share the recording,<br/>
                        please change to "Unlisted" or "Public".<br/>
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
);

export default WarningModal
