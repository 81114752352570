import {createStore, combineReducers, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

import postReducer from "./reducers/postReducer";
import loginReducer from "./reducers/loginReducer";
import navReducer from "./reducers/navReducer";
import MediaReducer from "./reducers/MediaReducer";
import mobileMenuReducer from "./reducers/mobileMenuReducer"
import mediaSingleReducer from "./MediaSingle/reducer"
import desktopNavReducer from "./DesktopNavPopUp/reducer"
import drawerDisplayReducer from "./Drawer/reducer"

/*
Functionality combined with MediaReducer, file can be deleted
import mediaReducer from "./reducers/mediaReducer";
 */

export default createStore(
    combineReducers({
            MediaReducer,
            navReducer,
            loginReducer,
            postReducer,
            mediaSingleReducer,
            mobileMenuReducer,
            desktopNavReducer,
            drawerDisplayReducer
    }),
    {},
    composeWithDevTools(applyMiddleware(thunk))
);
