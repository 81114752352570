import React from 'react';

import radioValues from './radioValues'

const VisibilityComponent = ({
                                 stateFormFields,
                                 changeField,
                                 name,
                             }) => (<div className="visibility-component-wrapper">
    <div className="visibility-content">
        <span>Visibility</span>
        {radioValues.map(({value, title, icon, description}, i) =>
            <div className="visibility-form-wrapper">
                <div className="form-check visibility-form-check-inline" key={i}>
                    <input type="radio" className=""
                           name="inlineRadioOptions"
                           value={value}
                           checked={stateFormFields['mediasharemethod'] === value}
                           onChange={value => changeField(name, value.target.value)}
                    />
                    {icon}
                    <label className="form-check-label"
                           htmlFor="materialInline1">{title.replace(/^\w/, c => c.toUpperCase())}</label>
                </div>
                <p>{description}</p>
            </div>

        )}
    </div>
</div>)


export default VisibilityComponent
