import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Chart from 'chart.js';
import "./connection.css"

class Connection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartCreated: false
        };
    }

    componentDidMount() {
        const dataTimeDelayCtx = this.dataTimeDelayCanvas.getContext("2d");
        const gpshorizontalaccuracyCtx = this.gpshorizontalaccuracyCanvas.getContext("2d");

        this.dataTimeDelayChart = new Chart(dataTimeDelayCtx, {
            type: "line",
            data: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: '',
                        fill: false,
                        backgroundColor: 'blue',
                        pointRadius: 4
                    },
                    {
                        data: [],
                        label: '',
                        fill: false,
                        spanGaps: true,
                        backgroundColor: 'red',
                        borderColor: "white",
                        pointRadius: 0
                    },
                ],
            },
            options: {
                responsive: true,
                animation: {
                    // duration: 100 * 1.5,
                    // easing: 'linear'
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "transparent"
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "transparent"
                        }
                    }],
                },
                tooltips: {enabled: false},
                hover: {mode: null},
                showTooltips: false,
                elements: {
                    line: {
                        tension: 0 // disables bezier curves
                    }
                },
                legend: {
                    display: false
                }
            }
        });

        this.gpshorizontalaccuracyChart = new Chart(gpshorizontalaccuracyCtx, {
            type: "line",
            data: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: '',
                        fill: false,
                        backgroundColor: 'blue',
                        pointRadius: 4
                    },
                    {
                        data: [],
                        label: '',
                        fill: false,
                        spanGaps: true,
                        backgroundColor: 'red',
                        borderColor: "white",
                        pointRadius: 0
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                animation: {},
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "transparent"
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "transparent"
                        }
                    }],
                },
                tooltips: {enabled: false},
                hover: {mode: null},
                showTooltips: false,
                elements: {
                    line: {
                        tension: 0
                    }
                },
                legend: {
                    display: false
                }
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setChartData(this.dataTimeDelayChart, 'dataTimeDelayArray');
        this.setChartData(this.gpshorizontalaccuracyChart, 'gpshorizontalaccuracyArray')
    }

    shouldComponentUpdate(nextProps) {
        const {equals} = this;
        return equals(nextProps);
    }

    equals = (next) => {
        // If incoming props are null or empty dont re-render
        if (next.sliderValue !== this.props.sliderValue) {
            return true
        }
        if (next.connectionData.satellites.length === 0) {
            return false
        } else {
            return true
        }
    };

    setChartData = (chart, chartArrayName) => {
        const {progress, duration} = this.props;
        const connectionData = this.props.connectionData;
        if (!connectionData) {
            return
        }
        ;
        let frames = 0;

        if (progress === duration && progress !== undefined && duration !== undefined) {
            //Resets the blue dot back to orginal position after video plays through
            chart.data.datasets[0].data = [connectionData[chartArrayName][0]]
        } else {
            if (connectionData[chartArrayName]) {
                frames = connectionData[chartArrayName].length;
            }
            let frameMath = (frames / duration);
            chart.data.datasets[1].data = connectionData[chartArrayName];
            if (connectionData[chartArrayName]) {
                chart.data.datasets[0].data = [connectionData[chartArrayName][Math.round(progress * frameMath)]];
            }

            chart.data.labels = this.getLabelCount(connectionData[chartArrayName]);
        }

        chart.update();
    };

    getLabelCount = (frames) => {
        let count = [];
        if (frames) {
            for (let i = 0; i < frames.length; i++) {
                count.push(i);
            }
        }
        return count
    };

    generateLatency = data => {
        const {duration, progress} = this.props;

        if (data) {
            let frames = data.length;
            let frameMath = (frames / duration);
            let obj = data[Math.round(progress * frameMath)];

            if (obj)
                return obj['y']
        }
    };

    generateCellTowerCenter = (cellArray) => {
        if (cellArray) {

            if (cellArray.length > 1) {

            } else {
                return {
                    lat: cellArray[0].lat,
                    lng: cellArray[0].lng
                }
            }
        }
    };

    calcSignal = (color, dmb) => {
        let num = parseInt(dmb);
        let numPos = Math.abs(num);
        let signalStr = Math.round((numPos / 110) * 100);

        if (color === 'orange') {
            return signalStr + '%'
        }
        if (color === 'white') {
            return 100 - signalStr + '%'
        }
    };

    render() {
        const {dataTimeDelayArray, connectionspeed, osname, gpsaccuracy, celltowers} = this.props.connectionData;
        const {sliderValue} = this.props;
        const {generateLatency} = this;
        let lightMode = 'black';
        let darkMode = 'white';

        return (
            <div
                className="connection-container"
                style={{background: !this.props.lightView ? `rgb(243,101,38,${sliderValue * .01})` : 'white'}}>
                <div className="component-header-wrapper">
                    <div
                        className="component-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        CONNECTIONS
                    </div>
                    <div className="connection-component-icon">
                        {/*<i*/}
                        {/*    className="fa fa-cloud-upload"*/}
                        {/*    aria-hidden="true"*/}
                        {/*    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                        {/*</i>*/}
                        <img src={`${process.env.PUBLIC_URL}/Icon-Connection.png`} alt=""/>
                    </div>
                </div>
                <div className="connection-speed-wrapper">
                    <div
                        className="connection-speed-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        GPS Accuracy
                    </div>
                    <div className="connection-speed-title"
                         style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        {gpsaccuracy ? "+/-" + gpsaccuracy + " feet" : 0.0 + " feet"}
                    </div>
                    <div className="connection-speed-graph">
                        <div className="chart-background-wrapper">
                            <div className="half-chart-background-gold"></div>
                            <div className="half-chart-background-green"></div>
                        </div>
                        <canvas id="gpshorizontalaccuracyCanvas" ref={gpshorizontalaccuracyCanvas => {
                            this.gpshorizontalaccuracyCanvas = gpshorizontalaccuracyCanvas;
                        }}/>
                    </div>
                </div>
                <div className="connection-speed-wrapper">
                    <div className="connection-speed-title"
                         style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        Data Time Delay
                    </div>
                    <div
                        className="connection-speed-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        {generateLatency(dataTimeDelayArray)} seconds
                    </div>
                    <div className="connection-speed-graph">
                        <div className="chart-background-wrapper">
                            <div className="half-chart-background-gold"></div>
                            <div className="half-chart-background-green"></div>
                        </div>
                        <canvas id="dataTimeDelay" ref={dataTimeDelayCanvas => {
                            this.dataTimeDelayCanvas = dataTimeDelayCanvas;
                        }}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        sliderValue: state.mediaSingleReducer.sliderValue,
        // latency: state.
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Connection))
