import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Clock from 'react-clock';
import "./time.css"

let moment = require('moment');

class Time extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {clockToDark} = this

        if (this.props.lightView) {
            //Targets the clock component and changes the colors to black
            let mark_body = document.getElementsByClassName('react-clock__mark__body');
            let hand__body = document.getElementsByClassName('react-clock__hand__body');
            let minute_hand = document.getElementsByClassName('react-clock__minute-hand');

            clockToDark(mark_body);
            clockToDark(hand__body);
            clockToDark(minute_hand);
        }
    }

    clockToDark = divEle => {
        for (var i = 0; i < divEle.length; i++) {
            divEle[i].className += ' dark'
        }
    }

    handlePhoneTime = (date) => {
        if (date) {
            let arr = date.split(/[- :]/);
            let newDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
            return newDate
        }
    };

    handleWorldTime = (date, time) => {
        let joinedDate = 0;

        if (date && time) {
            if (time.includes('GMT')) {
                let cleanAndroidTime = time.slice(0, -3);
                let insertWorldTime = date.split(" ");

                joinedDate = insertWorldTime[0] + ' ' + cleanAndroidTime;
            } else {
                let insertWorldTime = date.split(" ");
                joinedDate = insertWorldTime[0] + ' ' + time;
            }

            let arr = joinedDate.split(/[- :]/);
            return new Date(arr[1] - 1, arr[2], arr[0], arr[3], arr[4], arr[5]);
        }
    };

    isTimeAndroid = time => {
        if (time) {
            if (time.includes("GMT")) {
                return ''
            } else {
                return " GMT"
            }
        }
    };

    getDate = (date) => {
        if (date) {
            return moment(date).format('MM/DD/YYYY')
        }
    };

    render() {
        const {
            timeData: {
                phoneclocktime,
                worldclocktime,
                mediaframedevicedatetime,
            },
            connectionData,
            sliderValue
        } = this.props;
        let lightMode = 'black';
        let darkMode = 'white';

        return (
            <div className="time-container"
                 style={{background: !this.props.lightView ? `rgb(43,138,132,${sliderValue * .01})` : 'white'}}>
                <div className="component-header-wrapper">
                    <div
                        className="component-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        TIME
                    </div>
                    <div className="time-component-icon">
                        <img src={`${process.env.PUBLIC_URL}/Icon-Clock.png`} alt=""/>
                    </div>
                </div>
                <div className="time-clocks-wrapper">
                    <div className="time-clock-wrapper">
                        <div
                            className="time-clocks-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Phone Time
                        </div>
                        <div className="time-clock">
                            <Clock
                                value={this.handlePhoneTime(mediaframedevicedatetime)}
                            />
                        </div>
                        <div
                            className="time-clock-time"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneclocktime}
                        </div>
                        <div
                            className="time-clock-date"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {this.getDate(mediaframedevicedatetime)}</div>
                    </div>
                    <div className="time-clock-wrapper">
                        <div
                            className="time-clocks-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            World Clock Time
                        </div>
                        <div className="time-clock">
                            <Clock
                                value={this.handleWorldTime(mediaframedevicedatetime, worldclocktime)}
                            />
                        </div>
                        <div
                            className="time-clock-time"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {worldclocktime} {this.isTimeAndroid(worldclocktime)}
                        </div>
                        <div
                            className="time-clock-date"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {this.getDate(mediaframedevicedatetime)}
                        </div>
                    </div>
                </div>
                <div className="time-daylight-map-wrapper">
                    <div className="time-daylight-map">
                        <img alt="" src={`${process.env.PUBLIC_URL}/sunmap.jpg`}/>
                    </div>
                </div>
                <div className="time-daylight-readings-wrapper">
                    <div className="time-daylight-reading-wrapper">
                        <div className="time-daylight-icon">
                            <img src={`${process.env.PUBLIC_URL}/sunicon.png`} alt=""/>
                        </div>
                        <div
                            className="time-daylight-icon-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Sun Zenith:
                        </div>
                        <div className="time-daylight-reading"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {connectionData ? connectionData.sunMoonData.sunposition : ''}
                        </div>
                    </div>
                    <div className="time-daylight-reading-wrapper">
                        <div className="time-daylight-icon">
                            <img src={`${process.env.PUBLIC_URL}/moon-2.png`} alt=""/>
                        </div>
                        <div
                            className="time-daylight-icon-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Moon Zenith:
                        </div>
                        <div
                            className="time-daylight-reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {connectionData ? connectionData.sunMoonData.moonposition : ''}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        sliderValue: state.mediaSingleReducer.sliderValue
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Time))
