import xapi from "../xapi";
import axios from 'axios'
import store from '../store'
let moment = require('moment');
/*
RESPONSE HEADERS CONTENT-TYPE IS ALWAYS TEXT/HTML
TO CATCH SERVER ERRORS WE PARSE TEXT FIRST AND
VALIDATE CONTENTS BEFORE PARSING AS JSON
*/

export function login(email, password) {
  return function dispatch(dispatch) {
    let x = new xapi("authorize");
    x.add("type", "client");
    x.add("email", encodeURIComponent(email));
    x.add("password", encodeURIComponent(password));
    let p = x.fetch();
    p.then(resp => resp.text())
        .then(function (result) {
          if (result.includes("result") || result.includes("errors")) {
            const dataResult = JSON.parse(result);
            if (dataResult.result) {
              if (dataResult.result.success === false) {
                dispatch({
                  type: "LOGIN_ERROR",
                  payload: dataResult.result.error
                });
              }
              if (dataResult.result.success === true) {
                const {authtoken, clientemail, clientid, clientchannel} = dataResult.result
                if (dataResult.result.authtoken) {
                  localStorage.setItem("authtoken", authtoken);
                  localStorage.setItem("email", clientemail);
                  localStorage.setItem("clientid", clientid); //current client id
                  localStorage.setItem("clientchannel", clientchannel ? clientchannel : clientid); //current channel
                  localStorage.setItem("homechannel", clientchannel ? clientchannel : clientid); // reference to return
                  localStorage.setItem("homeid", clientid); //reference to return
                }

                const data = {
                  id: new Date(),
                  email: clientemail,
                  clientid: clientid,
                  password,
                  editing: false,
                  loginToken: authtoken,
                  clientchannel: clientchannel ? clientchannel : clientid

                };
                dispatch({type: "PROCESS_LOGIN", payload: data});
                dispatch({
                  type: "LOGIN_ERROR",
                  payload: ''
                });
                dispatch({type: "TOGGLE_USER_MENU"});
              }
            }
          }else{
            throw new Error(result);
          }
        })
        .catch((err) =>{
              dispatch({ type: "LOGIN_ERROR", payload: "A server Error has occurred. Please contact your Systems Administrator"});
              console.error("The login action has received the following error: " + err.message)
            }
        );
  };
}

export function processauthtoken(authtoken) {

  localStorage.getItem("email");
  const data = {
    loginToken: authtoken,
    email: localStorage.getItem("email"),
    clientid: localStorage.getItem("clientid"),
    clientchannel:localStorage.getItem("clientchannel")
  };
  return { type: "PROCESS_LOGIN", payload: data };
};

export function logout(embed) {

  localStorage.clear();

  const data = {
    loginToken: null,
    email: "",
    clientid: "",
    clientchannel:""
  };
  return function dispatch(dispatch){
    dispatch({ type: "PROCESS_LOGIN", payload: data })
    dispatch({ type: "ADD_MEDIA", payload: [] });
    if(!embed)
    dispatch({type: "TOGGLE_USER_MENU"});
  }
}

export const searchQuery = query => {
  return {
    type: "MEDIA_SEARCH",
    payload: query
  };
};

export const gridToggle = value => {
  return {
    type: "TOGGLE_DISPLAY",
    payload: value
  };
};

export const mediaFilter = filter => {
  return {
    type: "MEDIA_FILTER",
    payload: filter
  };
};

export const setMediaKey = key => {
  return {
    type: "SET_KEY",
    payload: key
  };
};

export const setMediaToken = token =>{
  return {
    type:"SET_TOKEN",
    payload:token
  }
}

export function getallmedia() {
  const stateProperties = {...store.getState().navReducer, ...store.getState().loginReducer}
  const {embed, userView} = stateProperties;
  let authtoken = localStorage.getItem("authtoken");
  const clientchannel = localStorage.getItem("clientchannel");
  const homechannel = localStorage.getItem("homechannel");
  // const type = !authtoken  ? "public" : "all";
  const type = userView === "public" ? "public" : "all"

  //Necessary to return correct error when channel doesn't exist
  if(clientchannel !== homechannel && clientchannel !=="my"){
    authtoken = null
  }
  return function dispatch(dispatch) {
    dispatch({ type: "FETCHING_MEDIA" });
    dispatch({type:'RESET_REQUEST_FULLFILLED'});
    dispatch({type:'CLEAR_ERROR'});
    var x = new xapi("list");
    x.add("type", "media");
    x.add("sharemethod", type);
    x.add("authtoken", authtoken);
    x.add("clientchannel", clientchannel);
    x.add("clientid",localStorage.getItem("clientid"));

    var p = x.fetch();
    p.then(response =>response.text()) // Transform the data into text
        .then(function(result) {
          //check for json obj vs server message, if json parse it else throw new error with message
          if(result.includes("result") || result.includes("errors")){
            const dataResult = JSON.parse(result);
            if (dataResult.result) {
              //No success returned by this API so not currently checking
              if(dataResult.result.message){
                dispatch({ type: "MEDIA_ERROR", payload: dataResult.result.message});
                dispatch({ type: "ADD_MEDIA", payload: [] });
              }
              if (dataResult.result.media) {

                if (dataResult.result.media && dataResult.result.media.length > 0) {
                  const data = dataResult.result.media;
                  const localChannel = localStorage.getItem("clientchannel");
                  const clientid = localStorage.getItem("clientid");

                  //This pertains to embed
                  //---------------------------------------------------------
                  if(localChannel === 'my'){
                    const {clientchannel,clientid} = data[0];
                    localStorage.setItem('clientchannel',clientchannel ? clientchannel : clientid );
                    localStorage.setItem('clientid',clientid);
                    localStorage.setItem('homechannel',clientchannel ? clientchannel : clientid);
                    localStorage.setItem("homeid",clientid);
                    dispatch({
                      type:"UPDATE_CHANNEL",
                      payload:{clientid,
                        clientchannel:clientchannel ? clientchannel : clientid !== "0" ? clientid : ""
                      }})
                  }
                  //----------------------------------------------------------
                  //clientid type coercion is intentional, do not triple =
                  if(clientid == 0){
                    const {clientchannel,clientid} = data[0];
                    localStorage.setItem('clientchannel',clientchannel ? clientchannel : clientid )
                    localStorage.setItem('clientid',clientid);
                    dispatch({
                      type:"UPDATE_CHANNEL",
                      payload:{clientid,
                        clientchannel:clientchannel ? clientchannel : clientid !== "0" ? clientid : ""
                      }})
                  }

                  let recentFirstArray = data.sort(function (a, b) {
                    let a1 = a.mediapublisheddate ? a.mediapublisheddate: a.mediacreateddate
                    let b1 = b.mediapublisheddate ? b.mediapublisheddate: b.mediacreateddate

                    return new moment(b1) - new moment(a1);
                  });
                  //Testing verified-----------------------------
                  // recentFirstArray[0].clientchannelverified="1";
                  // const testVerifyArray = recentFirstArray;
                  //
                  // dispatch({type: "ADD_MEDIA", payload: recentFirstArray});
                  //---------------------------------------------
                  dispatch({type: "ADD_MEDIA", payload: recentFirstArray});
                } else {
                  // console.log("ZERO media returned");
                }
              }
            } else {
              if(dataResult.error){
                dispatch({ type: "ADD_MEDIA", payload: [] });
                dispatch({ type: "MEDIA_ERROR", payload: dataResult.error });
              }
            }
          }else{
            throw new Error(result);
          }
        })
        .catch((err) =>{
              dispatch({ type: "MEDIA_ERROR", payload: "A server Error has occurred. Please contact your Systems Administrator"});
              console.error("The getallmedia action has received the following error: " + err.message)
            }
        );
  };
}

export function updateMedia(
    mediatoken,
    {mediadescription, mediatitle, mediasharemethod, setcoverphoto, mediacoverimage},
    type
) {

  mediadescription = mediadescription ? mediadescription : "No Description";
  mediatitle = mediatitle ? mediatitle : "No Title";


  return function dispatch(dispatch) {
    dispatch({type: 'UPDATE_MEDIA_START',payload:"Updating Media..."});
    dispatch({type:"CLEAR_QUERY"})
    var x = new xapi("updatemeta");
    x.add("type", "media");
    x.add("mediatype", type);
    x.add(`${type}token`, mediatoken);
    x.add("authtoken", localStorage.getItem("authtoken"));
    x.add("title", encodeURIComponent(mediatitle));
    x.add("description", encodeURIComponent(mediadescription));
    x.add("sharemethod", mediasharemethod);
    if (setcoverphoto) {
      x.add("setcoverphoto", true)
    }

    var p = x.fetch();
    p.then(resp => resp.text())
        .then(function (result) {
          //check for json obj vs server message, if json parse it else throw new error with message
          if (result.includes("result") || result.includes("errors")) {
            const dataResult = JSON.parse(result);
            if (dataResult.result) {
              if (
                  dataResult.result.success === true ||
                  dataResult.result.success === 1
              ) {
                const{videouploadcoverurl} = dataResult.result;
                if(videouploadcoverurl){
                  console.log(videouploadcoverurl);
                  dispatch(updateMediaCoverImage(videouploadcoverurl,mediacoverimage));
                  dispatch({type: 'UPDATE_MEDIA_FINISH', payload: {mediatoken, mediadescription, mediatitle}});
                }else{
                  dispatch({type: 'UPDATE_SUCCESS'});
                  dispatch({type: 'UPDATE_MEDIA_FINISH', payload: {mediatoken, mediadescription, mediatitle}});
                }
              }
            } else {
              console.error("The updateMedia action has received the following error: " +  dataResult.errors);
              dispatch({type: "MEDIA_ERROR", payload: "A problem was encountered, update unsuccessful."});
            }
          } else {
            throw new Error(result);
          }
        })
        .catch((err) => {
          dispatch({
            type: "MEDIA_ERROR",
            payload: "A server Error has occurred. Please contact your Systems Administrator"
          });
          console.error("The updateMedia action has received the following error: " + err.message)
        });
  }
}

export function updateclientname(newname = "") {
  //alert("inside updateclientname");

  // console.log("updateclientname: New Name: " + newname);

  //if (newname === "") {
  //  newname = "newname";
  //}

  return function dispatch(dispatch) {
    var x = new xapi("updatemeta");
    x.add("type", "client");
    x.add("authtoken", localStorage.getItem("authtoken"));
    x.add("name", newname);

    var p = x.fetch();
    p.then(resp => resp.json()) // Transform the data into json
      .then(function(dataResult) {
        if (dataResult.result) {
          //console.log("dataResult.result: " + JSON.stringify(dataResult));
          if (dataResult.result.success === false) {
            // alert(" " + dataResult.result.message);

            console.log("dataResult: " + dataResult);
          }
          if (
            dataResult.result.success === true ||
            dataResult.result.success === 1
          ) {
            console.log("updateclientname successful!");
            console.log("success dataResult: " + JSON.stringify(dataResult));

            // do something after successful update
          }
        } else {
          console.log("no result - response data: " + dataResult);
          console.log(dataResult);
        }
      })
      .catch(err =>
        console.log("updateclientname dataResult error message: " + err.message)
      );
  };
}

export function deleteMedia(payload) {
  return function dispatch(dispatch) {
    let x = new xapi("unpublish");
    x.add("token", payload.token);
    x.add("type", payload.mediaType);
    x.add("authtoken", payload.authtoken);
    let p = x.fetch();
    p.then(resp => resp.text())
        .then(function (result) {
          //check for json values else throw new error with server message
          if (result.includes("result") || result.includes("errors")) {
            const dataResult = JSON.parse(result);
            const {error} = dataResult;
            if (dataResult.result && dataResult.result.success) {
              dispatch({type: 'UPDATE_SUCCESS'})
            }
            if (error && !error.includes("not published")) {
              dispatch({type: "MEDIA_ERROR", payload: dataResult.error});
            }
            //dev error to console with file number, user error without
            if (error) {
              dispatch({
                type: "MEDIA_ERROR",
                payload: "File is not published and may not be deleted. Please contact your Systems Administrator"
              });
              console.error("The deleteMedia action has received the following error:" + error);
            }
          } else {
            //result is not json
            throw new Error(result)
          }
        })
        .catch((err) => {
          dispatch({
            type: "MEDIA_ERROR",
            payload: "A server Error has occurred. Please contact your Systems Administrator"
          });
          console.error("The deleteMedia action has received the following error: " + err.message)
        });
  };
}

export function handleToggleMenu() {
  return {
    type: "TOGGLE_MOBILE_MENU"
  };
}

export function mobileMenuLoginOn() {
  return {
    type: "MOBILE_MENU_ON"
  };
}

// export const setEmbedValue = value => {
//   return {
//     type: "SET_EMBED",
//     payload:value
//   };
// };

export const setEmbedValue = value => dispatch => {
  dispatch({
    type: "SET_EMBED",
    payload: value
  });
  dispatch({
    type: "USER_VIEW",
    payload: "private"
  })
};

export const toggleUploadModal = (uploadType) => {
  return {
    type: "TOGGLE_UPLOAD_MODAL",
    payload: uploadType
  };
}

export function searchMedia(str) {
  return {
    type: "SEARCH_MEDIA",
    payload: str
  };
}

export function toggleReputationModal() {
  return {
    type: "TOGGLE_REPUTATION_MODAL",
  };
}

export const sortMedia = mediaArray => {
  return {
    type: 'SORT_MEDIA',
    payload: mediaArray
  }
}

export const toggleEdit = value =>{
  return {
    type:'TOGGLE_EDIT',
    payload:value
  }
}

export const toggleSingView = boolean =>{
  return {
    type:"TOGGLE_SINGLE_VIEW",
    payload:boolean
  }
};

export const updateClientChannel = (channel,id) =>{
  localStorage.setItem("clientchannel", channel);
  localStorage.setItem("clientid",id);
  return {
    type: "UPDATE_CLIENTCHANNEL",
    payload:{
      channel,
      id
    }
  }
};

export const setNavShare = value => {
  return {
    type: "SET_WEB_SHARE",
    payload:value
  }
};

export const unsetMetData = () =>{
  return {
    type:"UNSET_METDATA"
  }
};

export const setShareUrl = (socialshareurl,mediasharetype) => dispatch => {
  dispatch({
    type: "SET_SOCIAL_SHARE_URL",
    payload:socialshareurl
  });
  return Promise.resolve({socialshareurl,mediasharetype});
};

export const setCopySuccess = successvalue =>{
  return {
    type:"SET_COPY_SUCCESS",
    payload:successvalue
  }
};

export const clearQuery = ()=>{
  return {
    type:"CLEAR_QUERY"
  }
}

const updateMediaCoverImage = (signedurl,mediacoverimage) =>
    dispatch=>{
      axios.put(signedurl,mediacoverimage)
          .then(resp =>{
            if(resp.status === 200){
              dispatch({type: 'UPDATE_SUCCESS'});
              // console.log(resp);
            }
          }).catch(error =>console.error("This is the update image error",error.message))
    };
