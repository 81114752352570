import React from 'react'
import { connect } from "react-redux";
import { Route} from "react-router-dom";
import {logout} from "../actions"

const LoginRoute = ({logout, ...props }) => {
    const clientchannel = localStorage.getItem("clientchannel")
    if(clientchannel){
        logout()
    }
    return <Route {...props}/>

}

export default connect(
    (state) =>({
    }),
    dispatch =>({
        logout:()=>{
            dispatch(logout())
        }
    })
    )(LoginRoute)
