export function adjustMap(data, map) {
    if (data) {
        const bounds = new window.google.maps.LatLngBounds();
        if (data.length > 1) {
            data.forEach(marker => {
                const {lat, lng} = marker;
                bounds.extend(new window.google.maps.LatLng(lat, lng));
            });
            //Fitbounds works seems to be async issue if it set bounds gets called before center value loads
            setTimeout(() => {
                map.fitBounds(bounds)
            }, 3000);
        }
    }
};