export const socialShareCopyFunction = (shareurl,setCopySuccess, inputRef) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(shareurl).then(() => {
            setCopySuccess("visible")
            // this.setState({copySuccess: "visible"})
        }).catch(err => {
            console.log(err)
        })
    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        const textarea = inputRef.current;
        textarea.select();
        try {
            document.execCommand("copy");  // Security exception may be thrown by some browsers.
            // this.setState({copySuccess: "visible"});
            setCopySuccess("visible")
            if (window.getSelection) {
                if (window.getSelection().empty) {  // Chrome
                    window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {  // Firefox
                    window.getSelection().removeAllRanges();
                }
            }
        } catch
            (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
    }
};
