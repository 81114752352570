/**
 * React Video Thumbnail Component
 * Original content @ https://github.com/brothatru/react-video-thumbnail
 * @author mike trieu
 */
import React from 'react';
import PropTypes from 'prop-types';
import "./thumbnail.css"

/**
 * Simple component that renders thumbnail url
 * @param {string} snapshot
 * @param thumbnailHandler
 */
const ThumbnailImage = ({snapshot, displayImage, thumbnailHandler, timespread, imageRef}) => {
    return (
        <div className="img-wrapper" ref={imageRef} onClick={() => thumbnailHandler(snapshot, imageRef )}>
            {/*<div style={{fontSize: "10px"}}>{timespread}</div>*/}
            <img src={displayImage} className="thumbnail-cover-image" alt="my video thumbnail"/>
        </div>
    );
};

export default class IOSThumbnail extends React.Component {

    constructor(props) {
        super(props);
        this.videoElRef = React.createRef();
        this.canvasRef = React.createRef();
        this.imageWrap = React.createRef();
        this.state = {
            dataLoaded: false,      // boolean
            metadataLoaded: false,  // boolean
            seeked: false,          // boolean
            snapshot: false,        // Blob thumbnail  || false
            displayImage: false,     // Image url created from blob
            suspended: false,       // boolean
            canPlayThrough: false,   // boolean
            timeRange: "",
            timeRangeStart: null,
            timeupdate:false,
            // props
            cors: props.cors,                           // boolean
            width: props.width,                         // number
            height: props.height,                       // number
            renderThumbnail: props.renderThumbnail,     // boolean
            snapshotAtTime: props.snapshotAtTime,       // number
            thumbnailHandler: props.thumbnailHandler,   // callback function
            videoUrl: props.videoUrl,                   // string
        }
    }

    render() {
        const {renderThumbnail, snapshot, displayImage, videoUrl, thumbnailHandler} = this.state;

        if (!snapshot) {
            return (
                <div className="img-wrapper">
                    <div className="thumbnail-loading-message">Loading...</div>
                    <canvas className="thumbnail-cover-image" ref={this.canvasRef}
                            style={{"visibility": "hidden"}} ></canvas>
                    <video muted={true}
                           style={{"visibility": "hidden"}}
                           className="thumbnail-cover-image"
                           ref={this.videoElRef}
                           src={videoUrl}
                        //Using .load in DidMount() loads data without need to play hidden video.
                        // autoPlay={true}
                           playsInline={true}
                           onCanPlayThrough={() => {
                               //Ready State HAVE_ENOUGH_DATA, entire media can be played without interruption
                               this.setState({canPlayThrough: true})
                           }}
                           onLoadedMetadata={() => {
                               this.setState({metadataLoaded: true})
                           }}
                           onLoadedData={() => {
                               //First frame has finished loading
                               this.setState({dataLoaded: true})
                           }}
                           onSeeked={() => {
                               //Seek operation has completed
                               this.setState({seeked: true})
                           }}
                           onSuspend={()=>{
                               // alert("suspend firing")
                               this.setState({suspended: true})
                           }}
                           onTimeUpdate={()=>{
                               this.setState({timeupdate:true})
                           }}
                    >
                    </video>
                </div>
            )
        } else {
            if (renderThumbnail) {
                return <ThumbnailImage imageRef={this.imageWrap} snapshot={snapshot} displayImage={displayImage}
                                       thumbnailHandler={thumbnailHandler} timespread={this.state.timeRange}/>;
            } else {
                return null;
            }
        }
    }

    /**
     * Update any props that may have changed
     */
    componentWillReceiveProps(nextProps) {
        let stateChanged = false;
        const data = {};
        for (let prop in nextProps) {
            if (nextProps[prop] !== this.props[prop]) {
                data[prop] = nextProps[prop];
                if (!stateChanged) {
                    stateChanged = true;
                }
            }
        }
        if (stateChanged) {
            this.setState(data);
        }
    }

    componentDidMount() {
        if (!this.state.cors) this.videoElRef.current.setAttribute('crossOrigin', 'Anonymous');
        this.videoElRef.current.load();
    }

    //Seekable range testing------------------------------------------------------------------------
    // componentDidUpdate(prevProps, prevState) {
    //     if (!this.state.snapshot) {
    //         const {metadataLoaded, dataLoaded, canPlayThrough, seeked, snapshot, snapshotAtTime, timeupdate,suspended} = this.state;
    //         // check if all 3 required events fired
    //
    //         if (metadataLoaded && dataLoaded && !snapshot, canPlayThrough) {
    //
    //             var timeRanges = {};
    //             //Go through the object and output an array
    //             if(!timeRanges.start && !timeRanges.end){
    //                 for (let count = 0; count < this.videoElRef.current.seekable.length; count++) {
    //                     timeRanges.start = this.videoElRef.current.seekable.start(count);
    //                     timeRanges.end = this.videoElRef.current.seekable.end(count)
    //                 }
    //             }
    //             if (timeRanges.start !== this.state.timeRangeStart) {
    //                 this.setState({timeRange: "Seekable Range start: " + timeRanges.start + " End: " + timeRanges.end +
    //                 ". SnapshotAtTime: " + snapshotAtTime});
    //                 this.setState({timeRangeStart: timeRanges.start});
    //             }
    //
    //             //Seek to snapshot time and pause video
    //             // alert("Seekable Range start: " + timeRanges.start + " End: " + timeRanges.end)
    //             if(timeRanges.start < snapshotAtTime && timeRanges.end > snapshotAtTime && !seeked){
    //                 this.videoElRef.current.currentTime = snapshotAtTime;
    //             }
    //
    //             //pause only needed if using autoplay to load data
    //             this.videoElRef.current.pause();
    //             // this.videoElRef.current.load();
    //             // alert("dataLoaded: " + dataLoaded)
    //
    //             if (!snapshot && seeked,suspended) {
    //                 //attempt to generate thumbnail
    //                 if(this.videoElRef.current.currentTime === snapshotAtTime)
    //                 this.getSnapShot();
    //             }
    //         }
    //     }
    // }
    //------------------------------------------------------------------------

    /**
     * (fires every time setState() gets called)
     */
    //Original DidUpdate functions
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.snapshot) {
            const {metadataLoaded, dataLoaded, canPlayThrough, seeked, snapshot, snapshotAtTime} = this.state;
            // check if all 3 required events fired
            if (metadataLoaded && dataLoaded && !snapshot, canPlayThrough) {
                //Seek to snapshot time and pause video
                    this.videoElRef.current.currentTime = snapshotAtTime;
                //pause only needed if using autoplay to load data
                // this.videoElRef.current.pause();

                if (!snapshot && seeked) {
                    //attempt to generate thumbnail
                    this.getSnapShot();
                }
            }
        }
    }


    componentWillUnmount() {
        URL.revokeObjectURL(this.state.displayImage)
    }

    /**
     * Create a canvas and video element to "draw" the
     * image, then convert it to a data url
     */
    getSnapShot = () => {
        const {width, height} = this.props;
        //acquire current reference to canvas and video elements
        const video = this.videoElRef.current;
        const canvas = this.canvasRef.current;
        try {
            canvas.height = height;
            canvas.width = width;
            // // resize thumbnail or no ?
            if (!width || !height) {
                canvas.getContext('2d').drawImage(video, 0, 0);
            } else {
                canvas.getContext('2d').drawImage(video, 0, 0, width, height);
            }
            //create blob and image url, revoke image url after set state
            canvas.toBlob(blob => this.setState({
                snapshot: blob,
                displayImage: URL.createObjectURL(blob)
            }), 'image/jpeg', 0.95);

            // Remove video & canvas elements (no longer needed)
            video.src = "";  // setting to empty string stops video from loading
            video.remove();
            canvas.remove();

        } catch (e) {
            console.error("getSnapshot has resulted in the following error: ", e);
        }
    }
}

/**
 * Property Types
 */
IOSThumbnail.propTypes = {
    cors: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    renderThumbnail: PropTypes.bool,
    snapshotAtTime: PropTypes.number,
    thumbnailHandler: PropTypes.func,
    videoUrl: PropTypes.string,
};

/**
 * Default Properties
 */
IOSThumbnail.defaultProps = {
    cors: false,
    renderThumbnail: true,
    snapshotAtTime: 2,
};

