import React, {Component} from "react";
import {connect} from "react-redux";
import {login} from "./actions";
import {toggleUserMenu} from './DesktopNavPopUp/actions'


class LoginForm extends Component {
//THIS COMPONENT COULD BE USED TO SHOW A WELCOME OR THANKS FOR USING VIDEOLOCK MESSAGE
    componentDidMount() {
        this.props.toggleUserMenu();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Component updates on login due to state change, checks for loginToken
        //and redirects to Grid View
        const {loginToken,clientchannel,history} = this.props
        if (loginToken)
            history.push(`/${clientchannel}`)
    }

    // handleSubmit = e => {
    //     const {login} = this.props;
    //     e.preventDefault();
    //
    //     const email = this.getEmail.value;
    //     const password = this.getPassword.value;
    //
    //     console.log("email: " + email);
    //     console.log("password: " + password);
    //
    //     if (!email) {
    //         alert("Email address is required.");
    //         return;
    //     } else if (!password) {
    //         alert("Password is required.");
    //         return;
    //     }
    //
    //     login(email, password);
    // };

    render() {
        return (
            <div>
                {/*<form onSubmit={this.handleSubmit}>*/}
                {/*  <div className="form-group">*/}
                {/*    <h2>Login</h2>*/}
                {/*    /!* <h2>{this.props.email}</h2> *!/*/}
                {/*    <label htmlFor="formBasicEmail">Email address</label>*/}
                {/*    <input*/}
                {/*        required*/}
                {/*        type="email"*/}
                {/*        className="input-style"*/}
                {/*        id="formBasicEmail"*/}
                {/*        aria-describedby="emailHelp"*/}
                {/*        placeholder="Enter email"*/}
                {/*        ref={input => (this.getEmail = input)}*/}
                {/*    />*/}
                {/*    <small id="emailHelp" className="form-text text-muted">*/}
                {/*      We'll never share your email with anyone else.*/}
                {/*    </small>*/}
                {/*  </div>*/}
                {/*  <div className="form-group">*/}
                {/*    <label htmlFor="formBasicPassword">Password</label>*/}
                {/*    <input*/}
                {/*        required*/}
                {/*        type="password"*/}
                {/*        className="input-style"*/}
                {/*        id="formBasicPassword"*/}
                {/*        placeholder="Password"*/}
                {/*        ref={input => (this.getPassword = input)}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <button type="submit" className="btn btn-primary">*/}
                {/*    Submit*/}
                {/*  </button>*/}
                {/*</form>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        email: state.loginReducer.authentication.email,
        loginToken: state.loginReducer.authentication.loginToken,
        userMenuToggled: state.desktopNavReducer.userMenuToggled,
        clientchannel: state.loginReducer.authentication.clientchannel,
        clientid: state.loginReducer.authentication.clientid
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (email, password) => {
            dispatch(login(email, password));
        },
        toggleUserMenu: () => {
            dispatch(toggleUserMenu())
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
