import React from 'react'
import './spinner.css'

const LoadSpinner = () => {
    return (
        <div className='Spinner_Wrapper' style={{ textAlign: 'center' }}>
            <div>
                <h1 className='loading_header'>Loading...</h1>
            </div>
            <div className="lds-dual-ring"></div>
        </div>
    )
}

export default LoadSpinner
