import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";

//Bootstrap-------------------------------------------
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js'; //deprecated but requierd by bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min';
//Bootstrap--------------------------------------------
import "./index.css";

import App from "./App";
import store from './store'
import LoginForm from "./LoginForm";
import ProtectedRoute from './ProtectedRoute'
import InitialRoute from './InitialRoute'
import LoginRoute from "./CustomRoutes/LoginRoute"
import Grid from './Grid/Grid'
import MediaSingle from './MediaSingle/MediaSingle'
import ReturnRestrictedContentMessage from "./RestrictedContentMessage/RestrictedContentMessage";
import DeepLinkRoute from "./CustomRoutes/DeepLinkRoute";
import "typeface-comfortaa";



//New Routes
//
const id = localStorage.getItem("clientchannel")
const router =
      <Switch>
          <LoginRoute exact path='/login' component={LoginForm}/>
          <DeepLinkRoute path ={"/s/"} component={ReturnRestrictedContentMessage} />
          <ProtectedRoute exact path="/my/" component={Grid}/>
          <InitialRoute exact path='/:channel'
                        component={Grid}/> {/*combine with Protected Route. No need for different components*/}
          <InitialRoute exact path={`/:media/:mediakey`} component={MediaSingle}/>
          <InitialRoute exact path={`/:channel/:media/:mediakey`} component={MediaSingle}/>
          <Route render={() => <Redirect to= {`/${id}`}/>}/>
      </Switch>

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App children={router}/>
        </BrowserRouter>

    </Provider>,
    document.getElementById("root")
);
