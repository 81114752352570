import React from 'react';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail'
import IOSThumbnail from "../VideoThumbnail/IOSThumbnail";

const ThumbnailGenerator = ({media,handleSelectThumbnail,timeStampArray}) => {
    
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        return (
            <div className="edit-thumbnail-wrapper">
                {timeStampArray.map((timeStamp,i) =>(
                    <div key={i} className="thumb-wrapper">
                        <IOSThumbnail
                            videoUrl={media.mediaurl}
                            thumbnailHandler={handleSelectThumbnail}
                            snapshotAtTime={timeStamp}
                            width={media.mediacoverurlwidth}
                            height={media.mediacoverurlheight}
                        />
                        {/*<div className="img-wrapper" >*/}
                        {/*    /!*<img src={snapshot} className="thumbnail-cover-image" alt="my video thumbnail" />*!/*/}
                        {/*    <img src={`${process.env.PUBLIC_URL}/videoCoverTemp.png`} className="thumbnail-cover-image" alt="my video thumbnail" />*/}
                        {/*</div>*/}
                    </div>
                ))}
            </div>

        )
    } else {
        return (
            <div className="edit-thumbnail-wrapper">
                {timeStampArray.map((timeStamp,i) =>(
                    <div key={i} className="thumb-wrapper">
                        <VideoThumbnail
                            videoUrl={media.mediaurl}
                            thumbnailHandler={handleSelectThumbnail}
                            snapshotAtTime={timeStamp}
                            width={media.mediacoverurlwidth}
                            height={media.mediacoverurlheight}
                        />
                    </div>
                ))}
            </div>
        )
    }
};

export default ThumbnailGenerator