import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import "../../node_modules/video-react/dist/video-react.css"; // import css
import {Player, ControlBar, Shortcut} from "video-react";
import Drawer from "../Drawer/Drawer";
import HalfViewContainer from "../HalfViewContainer/HalfViewContainer"
import {mediaFilter, toggleSingView} from "../actions";

import {
    getSingleMedia,
    setSliderValue,
    handleDrawerToggle,
    getStoreMetadata,
    setDrawerOpacity,
    scaleHalfDrawer,
    toggleHalfView,
    toggleFullScreenDesktopView,
    viewMedia,
    resetMediaDefaults,
    unsetMedia,
    toggleDisplayTools
} from "./actions";
import {formatDuration} from "../utilityFunctions/formatDuration"
import LoadSpinner from "../LoadSpinner/LoadSpinner";
import Waveform from "./Waveform";
import "./mediasingle.css";
import Playbar from "../Playbar/Playbar";
import ReputationScore from "../ReputationScore/ReputationScore";
import EditMedia from "../EditMedia/EditMedia";


class MediaSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFrame: 0,
            listenerSet: false,
            isSubscribed: false,
            updateDisplay: 0,
            width: 0,
            // drawerOpen: false,
            sliderValue: 100,
            videoPlaying: false,
            player: {
                currentTime: 0
            },
            fullScreenDrawerClass: '',
            touchYValue: ''
        };
        this.getCurrentFrame = this.getCurrentFrame.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        const {
            params: {media, mediakey, channel},
            toggleSingView,
        } = this.props;

        toggleSingView(true);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        if (window.innerWidth <= 414) {
            window.scroll({
                top: 0,
                left: 0,
            });
            // document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        }

        this.checkSetChannel(channel);
        this.getMedia(media, mediakey);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            params: {media, mediakey},
            setDrawerOpacity,
            viewMedia,
            history,
            clientchannel
        } = this.props;
        const {listenerSet, isSubscribed} = this.state;
        let tokenType = '';

        if (clientchannel !== prevProps.clientchannel) {
            if (!clientchannel) {
                history.replace(`/${media}/${mediakey}`)
                return
            }
            history.replace(`/${clientchannel}/${media}/${mediakey}`)
        }
        ;

        if (!isSubscribed && this.props.media !== prevProps.media) {
            if (media === 'v') {
                tokenType = 'videotoken'
            }
            if (media === 'a') {
                tokenType = 'audiotoken'

            }
            if (media === 'i') {
                tokenType = 'imagetoken'
            }

            viewMedia({
                token: this.props.media[tokenType],
                type: this.props.media.mediatype
            });
        }

        if (this.state.width < 834 && !isSubscribed) {
            setDrawerOpacity(75);
        }

        if (media === "v" && this.player && !isSubscribed) {
            //subscribes to changes only if the player exists
            this.setState({isSubscribed: true});
            this.player.subscribeToStateChange(this.handleStateChange.bind(this));
            //setting an attribute to prevent full screen video in safari on mobile
            // document.getElementsByTagName("video")[0].setAttribute('playsInline', "playsInline");
            // $("video")[0].attr('webkit-playsinline');
            // document.getElementsByTagName("video")[0].setAttribute('webkit-playsinline', "webkit-playsinline");
            document.getElementsByClassName('video-react-video')[0].setAttribute('webkit-playsinline', "");
            document.getElementsByClassName('video-react-video')[0].setAttribute('playsInline', "");

            // this.player.play();
            // setInterval(this.player.pause(), 15)
        }
        if (media === "a" && this.player && !listenerSet) {
            //Only sets a event listener after render and does so once
            this.player.onloadedmetadata = () => {
                this.setState({
                    duration: this.player.duration
                })
            };

            this.player.addEventListener("timeupdate", e => {
                this.setState({
                    player: this.player,
                    currentsequence: e.target.currentTime,
                    listenerSet: true
                    // duration: e.target.duration
                });
            });

            this.player.play();
            setInterval(this.player.pause(), 15)
        }
    }

    componentWillUnmount() {
        const {resetMediaDefaults, toggleSingView, unsetMedia} = this.props

        window.removeEventListener('resize', this.updateWindowDimensions);
        document.getElementsByTagName("html")[0].style.overflowY = null;
        toggleSingView(false);
        resetMediaDefaults();
        unsetMedia();
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth});
    }

    checkSetChannel = (channel) => {
        if (!channel) return;
        const test = localStorage.getItem("clientid");
        if (test === "null" || test === "undefined" || !test) {
            localStorage.setItem("clientid", channel)
        }
    };

    getMedia(media, mediakey) {
        const {getSingleMedia, mediatoken} = this.props;
        switch (media) {
            case "v":
                getSingleMedia({
                    type: "video",
                    key: mediakey,
                    keytype: "videokey",
                    mediatoken,
                });
                this.sideCareSync(mediakey, 'video');
                break;
            case "a":
                getSingleMedia({
                    type: "audio",
                    key: mediakey,
                    keytype: "audiokey",
                    mediatoken,
                });
                this.sideCareSync(mediakey, 'audio');
                break;
            case "i":
                getSingleMedia({
                    type: "image",
                    key: mediakey,
                    keytype: "imagekey",
                    mediatoken,
                });
                this.sideCareSync(mediakey, "image");
                break;
            default:
                this.props.history.push("/login");
        }
    };

    sideCareSync = (key, type) => {
        //Logic for metadata sync and update will eventually be in this function
        const {getStoreMetadata, mediatoken} = this.props;
        if (mediatoken) {
            getStoreMetadata({key, mediatoken, type})
        }
    };

    handleStateChange(state) {
        this.setState({
            player: state,
            currentsequence: state.currentTime
        });
        this.setFrame();
    }

    setFrame = () => {
        //Buggy, commented out for now. not needed for current testing
        //B
        // const {player} = this.player.getState();
        //
        // if (player.hasStarted) {
        //     this.setState({
        //         // currentFrame: Math.floor(this.state.currentTime * 16.224)
        //         currentFrame: Math.floor(this.state.currentTime * 29.330)
        //     })
        // } else {
        //     clearInterval();
        // }
    };

    getCurrentFrame() {
        // setInterval(() => this.setFrame(), (1000 / 29.330));
    }

    getTime = time => {
        if (!isNaN(time)) {
            return (
                Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
            );
        }
    };

    handleDisplayUpdate = (e) => {
        this.setState({updateDisplay: this.state.updateDisplay + 1})
    };

    openDrawer = () => {
        const {handleDrawerToggle} = this.props;
        handleDrawerToggle();
    };

    handleSliderChange = (e) => {
        const {setSliderValue} = this.props;
        setSliderValue(e.target.value);
    };

    getMediaData = (media, dataType) => {
        for (let k in media) {
            if (k.toLowerCase().indexOf(dataType.toLowerCase()) !== -1) {
                if (dataType === 'description' && media[k] === null) {
                    return 'No Description'
                }
                if (dataType === 'title' && media[k] === null) {
                    return 'No Title'
                } else {
                    return media[k];
                }
            }
        }
        return null;
    };

    handleMediaScrub = (seconds) => {
        const {
            params: {media}
        } = this.props;

        if (media === 'v') {
            this.player.seek(seconds.value * (this.state.player.duration * .01));
        } else {
            let scrub = seconds.value / this.state.duration;
            this.player.currentTime = scrub
        }
    };

    playVideo = () => {
        this.player.play();

        this.setState({
            videoPlaying: !this.state.videoPlaying
        });
    };

    getCurrentMediaView = () => {
        const {mediaHalfView, fullscreenDesktopOn} = this.props;
        const fullDesktopMediaClasses = 'col-12 fullscreen-desktop-media';
        const mobileMediaHalfClasses = "col-12 mobile-media-half";
        const mobileMediaClasses = "col-12 mobile-media";
        const desktopMediaClasses = "col-9 nopadding";

        if (this.state.width < 834 || fullscreenDesktopOn) {
            if (fullscreenDesktopOn) {
                //Sets the media to full screen view
                return fullDesktopMediaClasses
            } else {
                if (mediaHalfView) {
                    //Sets the media to have half screen nav open
                    return mobileMediaHalfClasses
                } else {
                    return mobileMediaClasses
                }
            }
        } else {
            return desktopMediaClasses
        }
    };

    getCurrentDrawerView = () => {
        const {fullscreenDesktopOn} = this.props;
        const desktopDrawerClasses = "col-3 drawer-control nopadding";
        const mobileDrawerClasses = "col-12 mobile-drawer";
        const fullDesktopDrawerClasses = 'col-3 fullscreen-desktop-drawer';

        if (this.state.width < 834 || fullscreenDesktopOn) {
            if (fullscreenDesktopOn) {
                return fullDesktopDrawerClasses
            } else {
                return mobileDrawerClasses
            }
        } else {
            return desktopDrawerClasses
        }
    };

    showSlider = () => {
        const {drawerOpen, fullscreenDesktopOn} = this.props;

        if (drawerOpen || fullscreenDesktopOn) {
            return 'sliderIsOn'
        } else {
            return 'sliderIsOff'
        }
    };

    createFullScreenDrawerView = () => {
        const {toggleFullScreenDesktopView, fullscreenDesktopOn} = this.props;

        if (!fullscreenDesktopOn) {
            // toggle full screen
            toggleFullScreenDesktopView();
            // set css to hide drawer
            this.setState({
                fullScreenDrawerClass: 'drawerHidden'
            })
        } else {
            //exit fullscreen
            toggleFullScreenDesktopView();
            //show drawer for desktop view
            this.setState({
                fullScreenDrawerClass: 'drawerShown'
            })
        }
    };

    fullDeskChevToggle = () => {
        const {fullScreenDrawerClass} = this.state;

        if (fullScreenDrawerClass === 'drawerHidden') {
            this.setState({
                fullScreenDrawerClass: 'drawerShown'
            })
        } else {
            this.setState({
                fullScreenDrawerClass: 'drawerHidden'
            })
        }
    };

    fullScreenDrawerChevronControls = () => {
        const {fullscreenDesktopOn, displayTools} = this.props;
        const {fullScreenDrawerClass} = this.state;
        const {fullDeskChevToggle} = this;

        if (fullscreenDesktopOn && displayTools) {
            if (fullScreenDrawerClass === 'drawerHidden') {
                return (
                    <i className="fa fa-chevron-right full-desk-chev"
                       aria-hidden="true"
                       onClick={() => fullDeskChevToggle()}
                    >
                    </i>
                )
            } else {
                return (
                    <i
                        className="fa fa-chevron-left full-desk-chev-left"
                        aria-hidden="true"
                        onClick={() => fullDeskChevToggle()}
                    >
                    </i>
                )
            }
        }
    };

    returnRestrictedContentMessage = () => {
        const{privateContent}=this.props;
        if(!privateContent){
            return (
                <div className="icon-wrapper" style={{paddingTop: "3em"}}>
                    <div>The VideoLock app is required to view this content. <br/>Download for IOS or Android</div>
                    <i className="fa fa-android" aria-hidden="true"></i>
                    <i className="fa fa-apple" aria-hidden="true"></i>
                </div>
            )
        }else{
            return (
                <div className="icon-wrapper" style={{paddingTop: "3em"}}>
                    <div>This video is set to Private. <br/>You do not have permission to view this content</div>
                    <i className="fa fa-user-secret"></i>
                </div>
            )
        }
    };

    generateOverlay = () => {
        const {reason, color} = this.props;

        if (color === 'yellow' && this.state.player.hasStarted) {
            return (
                <div
                    className="media-single-verification-bar-yellow"
                    style={{top: this.state.width > 834 ? '7px' : '0'}}
                >
                    <div className="caution-reason-wrapper">
                        <div className="caution-readout">
                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            <div>{reason}</div>
                        </div>
                        <div>CAUTION</div>
                    </div>
                </div>
            )
        }
        if (color === 'red') {
            return (
                <div
                    className="media-single-verification-bar"
                >
                    <div className="reason-wrapper">
                        {reason}
                    </div>
                </div>
            )
        }
        if (color === 'green' && this.state.player.hasStarted) {
            return (
                <div
                    className="media-single-verification-bar-green"
                    style={{top: this.state.width > 834 ? '7px' : '0'}}
                >
                    <div className="reason-wrapper">
                        VERIFIED
                    </div>
                </div>
            )
        }
    };

    pauseVideo = () => {
        this.player.pause();
    };

    calcDescriptionHeight = () => {
        const {fullscreenDesktopOn} = this.props;
        let innerHeight = window.innerHeight;

        if (!fullscreenDesktopOn) {
            return innerHeight - (130 + 535)
        }
    };

    render() {
        const {
            media, sliderValue, isOpen, drawerOpen, mediaHalfView, toggleHalfView, edit,frameError,
            fullscreenDesktopOn, apprequired,privateContent, error, reason, color, toggleDisplayTools, displayTools
        } = this.props;
        const loading = this.props.fetching;
        const {
            openDrawer,
            handleSliderChange,
            playVideo,
            getMediaData,
            handleMediaScrub,
            getCurrentMediaView,
            getCurrentDrawerView,
            showSlider,
            fullScreenDrawerChevronControls,
            createFullScreenDrawerView,
            generateOverlay,
            calcDescriptionHeight
        } = this;
        const {videoPlaying, fullScreenDrawerClass} = this.state;
        const currentTime = this.getTime(this.state.currentsequence);

        if (apprequired || privateContent) {
            return this.returnRestrictedContentMessage()
        }

        if (edit) {
            return <EditMedia
                width={this.state.width}
                media={media}
            />
        }

        if (error) {
            return <h1 className="error-message" align="center">{error}</h1>
        }

        return (
            <div>
                {loading && <LoadSpinner/>}
                {!loading && (
                    <div className="row">
                        {this.state.width > 834 || drawerOpen ?
                            <div className={getCurrentDrawerView() + " " + fullScreenDrawerClass}>
                                <div className={showSlider()}>
                                    <input
                                        type="range"
                                        min="1"
                                        max="100"
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        className="slider"
                                        id="myRange"/>
                                </div>
                                <Drawer
                                    currentFrame={this.state.currentFrame}
                                    currentsequence={this.state.currentsequence}
                                    duration={this.state.player ? this.state.player.duration : ""}
                                    buttonUpdateDisplay={this.state.updateDisplay}
                                />
                            </div>
                            :
                            <div className="hidden">
                                <div className="slidecontainer">
                                    <input
                                        type="range"
                                        min="1"
                                        max="100"
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        className="slider"
                                        id="myRange"/>
                                </div>
                                <Drawer
                                    currentFrame={this.state.currentFrame}
                                    currentsequence={this.state.currentsequence}
                                    duration={this.state.player ? this.state.player.duration : ""}
                                    buttonUpdateDisplay={this.state.updateDisplay}
                                />
                            </div>
                        }
                        <div
                            className={getCurrentMediaView()}>
                            {media.mediatype === "video" && (
                                <div className="full-desk-media-wrap">
                                    <Player
                                        fluid={false}
                                        poster={media.videocoverurl ? media.videocoverurl : ""}
                                        halfview-percentage-wrapper
                                        width={"100%"}
                                        height={mediaHalfView ? this.props.drawerHeightPercentage : '100%'}
                                        src={media.videourl}
                                        preload={'auto'}
                                        playsInline={true}
                                        ref={player => {
                                            this.player = player;
                                        }}
                                    >
                                        {!mediaHalfView &&
                                        <Shortcut clickable={false}/>
                                        }
                                        <ControlBar disableDefaultControls={true} className="my-class"/>
                                        {/*<BigPlayButton position="center"/>*/}
                                    </Player>
                                    {this.state.player.paused && this.state.width > 834 &&
                                    <i className="fa fa-play-circle"
                                       id={fullscreenDesktopOn ? 'fixed-center-play' : 'half-view-button'}
                                       aria-hidden="true"
                                       style={{'zIndex': '99'}}
                                       onClick={() => playVideo()}
                                    >
                                    </i>
                                    }
                                </div>
                            )}
                            {media.mediatype === "audio" && (
                                <div className="waveform-wrapper">
                                    <div className="wave-background">
                                        <Waveform id="audioPlayer"
                                                  src={media.audiourl}/>
                                        <div className="progress-bar-audio-desktop"
                                             style={{width: this.state.player ? this.state.player.currentTime * 100 / this.state.player.duration + '%' : ''}}>
                                        </div>
                                    </div>
                                    <audio
                                        ref={ref => (this.player = ref)}
                                        id="audioPlayer"
                                        controls
                                        src={media.audiourl}
                                    />
                                </div>
                            )}
                            {media.mediatype === "image" && (
                                <div
                                    className={this.state.width < 834 ? "media-single-image-wrapper" : !fullscreenDesktopOn ? "desktop-media-single-image-wrapper" : "desktop-media-single-image-wrapper-full"}
                                    style={{height: mediaHalfView ? this.props.drawerHeightPercentage : '100%'}}
                                >
                                    {/*fullscreenDesktopOn*/}
                                    <div className="transparent-click-zone" onClick={() => toggleDisplayTools()}>
                                    </div>
                                    <img alt="" src={media.imageurl}/>
                                </div>
                            )}
                            {
                                !drawerOpen && displayTools ?
                                    <div>
                                        {generateOverlay()}
                                    </div>
                                    : ''
                            }
                            {
                                this.state.width > 834 && media.mediatype !== "image" && media.mediatype !== "audio" && !fullscreenDesktopOn &&
                                <div>
                                    <div className="media-single-details-wrapper">
                                        <Playbar
                                            media={media}
                                            isOpen={isOpen}
                                            handleMediaScrub={handleMediaScrub}
                                            playVideo={playVideo}
                                            videoPlaying={videoPlaying}
                                            currentTime={currentTime}
                                            videoPlayer={this.state.player}
                                            audioPlayer={this.player}
                                            mediaHalfView={mediaHalfView}
                                            colorreason={reason}
                                            color={color}
                                            cssWrapperStyle={'desktop-media-container'}
                                        />
                                    </div>
                                    <div>
                                        <ReputationScore summaryBullets={media.summarybullet}
                                                         viewCss={'rep-score-desktop-single'}/>
                                    </div>
                                </div>
                            }
                            {this.state.width > 834 && !fullscreenDesktopOn &&
                            <div
                                style={{
                                    height: calcDescriptionHeight(),
                                    overflowY: 'scroll'
                                }}>
                                <div className="media-single-video-details">
                                    <div className="media-title"><h1>{getMediaData(media, 'title')}</h1>
                                    </div>
                                    <div className="media-description">{getMediaData(media, 'description')}</div>
                                </div>
                                {
                                    frameError &&
                                    <div style={{color:"red"}}>{`[${frameError}]`}</div>
                                }
                            </div>
                            }
                            {/*<div*/}
                            {/*    className="fullscreen-toggle"*/}
                            {/*    onClick={() => toggleHalfView()}>*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/toggle.png`} alt=""/>*/}
                            {/*</div>*/}
                            {/*<i onClick={() => toggleHalfView()}*/}
                            {/*   className="fullscreen-toggle fa fa-square-o"*/}
                            {/*   aria-hidden="true">*/}
                            {/*</i>*/}
                        </div>
                    </div>
                )}
                {/*Still rendering the halfview hidden so data is always set. Needs a refactor*/}
                {this.state.width < 834 && mediaHalfView ?
                    <HalfViewContainer
                        currentFrame={this.state.currentFrame}
                        currentsequence={this.state.currentsequence}
                        duration={this.state.player ? this.state.player.duration : ""}
                        buttonUpdateDisplay={this.state.updateDisplay}
                        mediaHalfView={mediaHalfView}
                        style={{'top': this.props.drawerHeightPercentage + 'px'}}
                    />
                    :
                    this.state.width < 834 ? <HalfViewContainer
                        style={{'display': 'none'}}
                        currentFrame={this.state.currentFrame}
                        currentsequence={this.state.currentsequence}
                        duration={this.state.player ? this.state.player.duration : ""}
                        buttonUpdateDisplay={this.state.updateDisplay}
                        player={this.player}
                    /> : ''
                }
                {this.state.width < 834 && mediaHalfView && media.mediatype !== "image" ?
                    <Playbar
                        media={media}
                        isOpen={isOpen}
                        handleMediaScrub={handleMediaScrub}
                        playVideo={playVideo}
                        videoPlaying={videoPlaying}
                        currentTime={currentTime}
                        videoPlayer={this.state.player}
                        audioPlayer={this.player}
                        mediaHalfView={mediaHalfView}
                        cssWrapperStyle={'half-media-playbar'}
                        colorreason={reason}
                        color={color}
                        height={this.props.drawerHeightPercentage - 10 + 'px'}
                    />
                    : ''
                }
                {
                    //Collapsed Play Button
                }
                {(this.state.player.paused && this.state.width < 834) ?
                    <div>
                        <div className="collapsed-play-button"
                             onClick={() => playVideo()}
                        >
                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                        </div>
                    </div> : ''
                }
                {(!mediaHalfView || fullscreenDesktopOn) &&
                <div className="transparent-click-zone" onClick={() => toggleDisplayTools()}>
                </div>
                }
                {!mediaHalfView &&
                <div>
                    {displayTools &&
                    <div onClick={() => openDrawer()}
                         className={drawerOpen ? "mobile-chevron-left" : "mobile-chevron"}>
                        {
                            drawerOpen ?
                                <i className="fa fa-chevron-left" aria-hidden="true">
                                </i>
                                :
                                <i className="fa fa-chevron-right" aria-hidden="true">
                                </i>
                        }
                    </div>
                    }
                    {
                        // Collapsed View Scrub Play Bar
                    }
                    {(!drawerOpen && displayTools) &&
                    <div>
                        <div
                            className="collapsed-fullscreen-toggle"
                            onClick={() => toggleHalfView()}>
                            <img src={`${process.env.PUBLIC_URL}/opentoggle.png`} alt=""/>
                        </div>
                        {media.mediatype !== 'image' &&
                        <div className="mobile-playbar">
                            <div className="mobile-playbar-wrapper">
                                {!isOpen &&
                                <div className="play-bar-data-wrapper">
                                    <div>{this.state.player ? formatDuration(this.state.player.currentTime) : '  '}</div>
                                    <div
                                        className="playbutton"
                                    >
                                        {media.mediatype === "video" || media.audiotype === "audio" ?
                                            <div>
                                                {!this.state.player.paused ?
                                                    <i className="fa fa-pause"
                                                       aria-hidden="true"
                                                       onClick={() => this.pauseVideo()}
                                                    >
                                                    </i>
                                                    :
                                                    <i className="fa fa-play"
                                                       aria-hidden="true"
                                                       onClick={() => playVideo()}
                                                    >
                                                    </i>
                                                }
                                            </div> : ''
                                        }
                                    </div>
                                    {media.mediatype === "video" ?
                                        <div>{this.state.player ? formatDuration(this.state.player.duration) : ''}</div> :
                                        <div>{this.player ? formatDuration(this.player.duration) : ''}</div>
                                    }
                                </div>
                                }
                            </div>
                            <Playbar
                                media={media}
                                isOpen={isOpen}
                                handleMediaScrub={handleMediaScrub}
                                playVideo={playVideo}
                                videoPlaying={videoPlaying}
                                currentTime={currentTime}
                                videoPlayer={this.state.player}
                                audioPlayer={this.player}
                                mediaHalfView={mediaHalfView}
                                cssWrapperStyle={'mobile-scrub-playcontrols'}
                                colorreason={reason}
                                color={color}
                            />
                        </div>
                        }
                    </div>
                    }
                </div>
                }
                {this.state.width > 834 &&
                <div>
                    {
                        fullscreenDesktopOn && media.mediatype !== 'image' ?
                            <div>
                                <div className="playbar-wrapper-full">
                                    {displayTools &&
                                    <div className="playbar-full-container">
                                        <Playbar
                                            media={media}
                                            isOpen={isOpen}
                                            handleMediaScrub={handleMediaScrub}
                                            playVideo={playVideo}
                                            videoPlaying={videoPlaying}
                                            currentTime={currentTime}
                                            videoPlayer={this.state.player}
                                            audioPlayer={this.player}
                                            mediaHalfView={mediaHalfView}
                                            colorreason={reason}
                                            color={color}
                                            cssWrapperStyle={'fullscreen-desktop-media-container'}
                                        />
                                        {
                                            //Fullscreen Desktop Play Controls
                                        }
                                        <div className="play-bar-data-wrapper">
                                            <div>{this.state.player ? formatDuration(this.state.player.currentTime) : '  '}</div>
                                            <div
                                                className="playbutton"
                                            >
                                                {media.mediatype === "video" || media.audiotype === "audio" ?
                                                    <div>
                                                        {!this.state.player.paused ?
                                                            <i className="fa fa-pause"
                                                               aria-hidden="true"
                                                               onClick={() => this.pauseVideo()}
                                                            >
                                                            </i>
                                                            :
                                                            <i className="fa fa-play"
                                                               aria-hidden="true"
                                                               onClick={() => playVideo()}
                                                            >
                                                            </i>
                                                        }
                                                    </div> : ''
                                                }
                                            </div>
                                            {media.mediatype === "video" ?
                                                <div>{this.state.player ? formatDuration(this.state.player.duration) : ''}</div> :
                                                <div>{this.player ? formatDuration(this.player.duration) : ''}</div>
                                            }
                                        </div>
                                    </div>
                                    }
                                </div>
                                {displayTools &&
                                <div className="desktop-fullscreen-toggle-open"
                                     onClick={() => createFullScreenDrawerView()}
                                >
                                    <img src={`${process.env.PUBLIC_URL}/opentoggle.png`} alt=""/>
                                </div>
                                }
                            </div>
                            :
                            <div className="desktop-fullscreen-toggle"
                                 onClick={() => createFullScreenDrawerView()}
                            >
                                <img src={`${process.env.PUBLIC_URL}/toggle.png`} alt=""/>
                            </div>
                    }
                    {fullScreenDrawerChevronControls()}
                </div>
                }
                {
                    // Mobile Drawer Play Bar
                }
                {drawerOpen && media.mediatype !== "image" &&
                <div>
                    <div className="mobile-playbar">
                        <div className="mobile-playbar-wrapper">
                            {!isOpen &&
                            <div className="play-bar-data-wrapper">
                                <div>{this.state.player ? formatDuration(this.state.player.currentTime) : '  '}</div>
                                <div
                                    className="playbutton"
                                >
                                    {media.mediatype === "video" || media.audiotype === "audio" ?
                                        <div>
                                            {!this.state.player.paused ?
                                                <i className="fa fa-pause"
                                                   aria-hidden="true"
                                                   onClick={() => this.pauseVideo()}
                                                >
                                                </i>
                                                :
                                                <i className="fa fa-play"
                                                   aria-hidden="true"
                                                   onClick={() => playVideo()}
                                                >
                                                </i>
                                            }
                                        </div> : ''
                                    }
                                </div>
                                {media.mediatype === "video" ?
                                    <div>{this.state.player ? formatDuration(this.state.player.duration) : ''}</div> :
                                    <div>{this.player ? formatDuration(this.player.duration) : ''}</div>
                                }
                            </div>
                            }
                        </div>
                        <Playbar
                            media={media}
                            isOpen={isOpen}
                            handleMediaScrub={handleMediaScrub}
                            playVideo={playVideo}
                            videoPlaying={videoPlaying}
                            currentTime={currentTime}
                            videoPlayer={this.state.player}
                            audioPlayer={this.player}
                            mediaHalfView={mediaHalfView}
                            cssWrapperStyle={'mobile-scrub-playcontrols'}
                            colorreason={reason}
                            color={color}
                        />
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        error: state.mediaSingleReducer.error,
        allMedia: state.MediaReducer.allMedia,
        audios: state.MediaReducer.audio,
        drawerOpen: state.mediaSingleReducer.drawerOpen,
        fetching: state.mediaSingleReducer.fetchingMedia,
        images: state.MediaReducer.images,
        isOpen: state.mobileMenuReducer.isOpen,
        params: params,
        player: state.player,
        reason: state.mediaSingleReducer.reason,
        color: state.mediaSingleReducer.color,
        fullscreenDesktopOn: state.mediaSingleReducer.fullscreenDesktopOn,
        drawerHeightPercentage: state.mediaSingleReducer.drawerHeightPercentage,
        videoHeightPercentage: state.mediaSingleReducer.videoHeightPercentage,
        displayTools: state.mediaSingleReducer.displayTools,
        media: state.mediaSingleReducer.currentMedia,
        mediaHalfView: state.mediaSingleReducer.mediaHalfView,
        mediatoken: state.mediaSingleReducer.mediaToken,
        sliderValue: state.mediaSingleReducer.sliderValue,
        subbarurl: state.mediaSingleReducer.currentMedia.subbarurl,
        videos: state.MediaReducer.videos,
        edit: state.navReducer.edit,
        clientchannel: state.loginReducer.authentication.clientchannel,
        apprequired: state.mediaSingleReducer.apprequired,
        privateContent:state.mediaSingleReducer.privateContent,
        frameError:state.mediaSingleReducer.frameError,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSingleMedia: (type, mediakey, mediasharetype) => {
            dispatch(getSingleMedia(type, mediakey, mediasharetype));
        },
        mediaFilter: mediaObj => {
            dispatch(mediaFilter(mediaObj));
        },
        toggleHalfView: () => {
            dispatch(toggleHalfView());
        },
        toggleDisplayTools: () => {
            dispatch(toggleDisplayTools());
        },
        setSliderValue: value => {
            dispatch(setSliderValue(value))
        },
        handleDrawerToggle: () => {
            dispatch(handleDrawerToggle())
        },
        getStoreMetadata: payload => {
            dispatch(getStoreMetadata(payload))
        },
        resetMediaDefaults: () => {
            dispatch(resetMediaDefaults())
        },
        setDrawerOpacity: (percentage) => {
            dispatch(setDrawerOpacity(percentage))
        },
        scaleHalfDrawer: (payload) => {
            dispatch(scaleHalfDrawer(payload))
        },
        toggleFullScreenDesktopView: () => {
            dispatch(toggleFullScreenDesktopView())
        },
        viewMedia: (payload) => {
            dispatch(viewMedia(payload))
        },
        toggleSingView: (boolean) => {
            dispatch(toggleSingView(boolean))
        },
        unsetMedia: () => {
            dispatch(unsetMedia())
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MediaSingle)
);
