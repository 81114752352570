const initialState = {
    isOpen: false,
};

const mobileMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE_MOBILE_MENU":
            return {
                ...state,
                isOpen: !state.isOpen
            };
        case "MOBILE_MENU_ON":
            return {
                ...state,
                isOpen: false
            };
        default:
            return state;
    }
};

export default mobileMenuReducer;
