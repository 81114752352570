import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./desktopnavpopup.css"
import {accountCreateStepOne, verifyCreate} from './actions'

class CreateAccountPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmNewPassword: '',
            channel: '',
            name: '',
            verifycode: '',
            passwordtype: "search",
            confirmtype: "search",
        };
    }

    handleInputChange = (e) => {
        const {placeholderInputTypeAction} = this;

        this.setState({
            [e.target.name]: e.target.value
        });

        placeholderInputTypeAction(e.target.name, e);
    };

    handleAccountCreateStepOne = (e) => {
        const {accountCreateStepOne} = this.props;

        e.preventDefault();
        accountCreateStepOne({
            email: this.state.email,
            password: this.state.password,
            confirmpassword: this.state.confirmNewPassword,
            channel: this.state.channel,
        })
    };

    handleVerifyCreate = (e) => {
        const {verifyCreate, clientId} = this.props;
        const {verifycode} = this.state;

        e.preventDefault();
        verifyCreate(
            {
                code: verifycode,
                clientid: clientId
            }
        );
    };

    passwordType = type => {
        this.setState({
            [`${type}`]: 'password'
        })
    };

    placeholderInputTypeAction = (field, e) => {
        let value = e.target.value;
        let fieldName = field + 'Type';

        value === '' ?
            this.setState({
                [fieldName]: 'search'
            })
            :
            this.setState({
                [fieldName]: 'password'
            });
    };

    render() {
        const {
            back,
            formErrors,
            currentStep
        } = this.props;
        const {handleAccountCreateStepOne, handleVerifyCreate, passwordType} = this;

        return (
            <div>
                {
                    currentStep === 1 &&
                    <div>
                        <div className="back-icon-wrapper">
                            <img
                                className="back-arrow"
                                src={`${process.env.PUBLIC_URL}/arrow_back.png`}
                                alt=""
                                onClick={() => back('Home')}
                            />
                        </div>
                        <div className="">
                            <div className="panel-form-input-container ">
                                {/*<input*/}
                                {/*    className="input-modal-type-search"*/}
                                {/*    type="search"*/}
                                {/*    name="name"*/}
                                {/*    placeholder="Name"*/}
                                {/*    value={this.state.name}*/}
                                {/*    onChange={this.handleInputChange}*/}
                                {/*/>*/}
                                {/*<div className="password-error-wrapper">*/}
                                {/*    {formErrors.name}*/}
                                {/*</div>*/}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                />
                                <div className="password-error-wrapper">
                                    {formErrors.email}
                                </div>
                                <div className="channel-input-wrapper">
                                    <div>VideoLock.com/&nbsp;</div>
                                    <input
                                        className="input-modal-type-search force-align-left"
                                        type="search"
                                        name="channel"
                                        placeholder="Channel Name"
                                        value={this.state.channelname}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="password-error-wrapper">
                                    {formErrors.channel}
                                </div>
                                <input
                                    className="input-modal-type-search"
                                    type={this.state.passwordType}
                                    name="password"
                                    placeholder="Password"
                                    onFocus={() => passwordType("passwordtype")}
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                />
                                <div className="password-error-wrapper">
                                    {formErrors.password}
                                </div>
                                <input
                                    type={this.state.confirmNewPasswordType}
                                    className="input-modal-type-search"
                                    name="confirmNewPassword"
                                    placeholder="Confirm Password"
                                    onFocus={() => passwordType("confirmtype")}
                                    value={this.state.confirmNewPassword}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="password-error-wrapper">
                                {formErrors.confirm}
                            </div>
                            <div className="button-container-center">
                                <button
                                    className="navpop-up-blue-btn remove-styles-btn"
                                    type="submit"
                                    onClick={(e) => handleAccountCreateStepOne(e)}
                                >
                                    Next
                                </button>
                            </div>
                            <div className="flex-center-wrapper">
                                <div className="confirm-message-box">
                                    A confirmation code will be emailed in minutes ...
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    currentStep === 2 &&
                    <div className="step-2-wrapper">
                        <div className="back-icon-wrapper">
                            <img
                                className="back-arrow"
                                src={`${process.env.PUBLIC_URL}/arrow_back.png`}
                                alt=""
                                onClick={() => back('Home')}
                            />
                        </div>
                        <div className='panel-message'>Please enter your code</div>
                        <form>
                            <div className="panel-form-input-container">
                                <input
                                    type="search"
                                    name="verifycode"
                                    placeholder="Confirmation Code"
                                    value={this.state.verifycode}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="password-error-wrapper">
                                {/*{createMessage}*/}
                            </div>
                            <div className="button-container-center">
                                <button
                                    className="navpop-up-blue-btn remove-styles-btn"
                                    type="submit"
                                    onClick={(e) => handleVerifyCreate(e)}
                                >
                                    Verify
                                </button>
                            </div>
                            <div className="align-center">Already Confirmed? <span
                                onClick={() => back('Sign In')}
                                className="login-questions-span">Sign In</span></div>
                        </form>
                    </div>
                }
                {currentStep === 3 &&
                <div>
                    <div className="download-description">
                        You have successfully registered your channel on VideoLock.com.
                    </div>
                    <div className="download-description">
                        Your channel helps you manage all the recordings you create in the VideoLock app and uploaded
                        to the VideoLock.com website.
                    </div>
                    <div className="download-description">
                        If you do not have the VideoLock app on your phone, please go to the download
                        page so you can start creating your own certified
                        VideoLock recordings.
                    </div>
                    <div className="button-container-center">
                        <div className="navpop-up-blue-btn remove-styles">
                            <a href="https://www.videolock.com/download">
                                Download
                            </a>
                        </div>
                    </div>
                    <div className="button-container-center">
                        <div className="navpop-up-blue-btn remove-styles-btn">
                            <a onClick={() => back('Sign In')}>
                                Login
                            </a>
                        </div>
                    </div>
                </div>
                }


                {/*<div className="icon-wrapper">*/}
                {/*    <div>Download the VideoLock app for IOS or Android</div>*/}
                {/*    <i className="fa fa-android" aria-hidden="true"></i>*/}
                {/*    <i className="fa fa-apple" aria-hidden="true"></i>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        formErrors: state.desktopNavReducer.formErrors,
        currentStep: state.desktopNavReducer.currentStep,
        clientId: state.desktopNavReducer.verifyClientId,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        accountCreateStepOne: (payload) => {
            dispatch(accountCreateStepOne(payload))
        },
        verifyCreate: (payload) => {
            dispatch(verifyCreate(payload))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccountPanel))
