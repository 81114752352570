import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './phone.css'

class Phone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xAxis: 0,
            yAxis: 0,
            zAxis: 0,
            background: ''
        };
    }

    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        const {equals} = this;
        return equals(nextProps);
    }

    equals = (next) => {
        // If incoming props are null dont re-render
        // Carrier should never change from first frame so a good marker of empty frames
        if (next.sliderValue !== this.props.sliderValue) {
            return true
        }
        if (next.phoneData.carrier == null) {
            return false
        } else {
            return true
        }
    };

    arcGraph = (percentage) => {
        if (percentage) {
            return {
                transform: "rotate(" + (45 + (parseInt(percentage) * 1.8)) + "deg)",
            };
        } else {
            return {
                transform: "rotate(45deg)"
            }
        }
    };

    removeDecimal = (strNum) => {
        if (strNum) {
            let num = parseInt(strNum);
            return Math.round(num) + '%'
        }
    };

    render() {
        let phones = this.props.phoneData.phoneDirectionData;
        let lightMode = 'black';
        let darkMode = 'white';
        const {phoneData, sliderValue} = this.props;
        const {removeDecimal} = this;

        // rotate3d(x, y, z, a)
        const styles = {
            transform: `rotate3d(${phones[0]}, ${phones[1]}, ${phones[2]}, ${phones[3] * 100}deg)`,
            // transition: `transform 500ms ease`
        };

        return (
            <div className="phone-container"
                 style={{background: !this.props.lightView ? `rgb(113,156,51,${sliderValue * .01})` : 'white'}}>
                <div className="component-header-wrapper">
                    <div
                        className="component-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        PHONE
                    </div>
                    <div className="phone-component-icon">
                        <img src={`${process.env.PUBLIC_URL}/Icon-Phone.png`} alt=""/>
                    </div>
                </div>
                <div className="phone-visual-top-wrapper">
                    <div className="phone-position-wrapper">
                        <div style={styles}
                             className="phone-box-test">
                            <img alt="" src={`${process.env.PUBLIC_URL}/deep_truth_orientation_animation_icon.png`}/>
                        </div>
                        <div className="xyz-background-image">
                            <div className="xyz-image-wrapper">
                                <img alt="" src={`${process.env.PUBLIC_URL}/cordinatesxyz.png`}/>
                            </div>
                        </div>
                    </div>
                    <div className="reading-wrapper">
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            X-Axis
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {String(phones[0]) + '°'}
                        </div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Y-Axis
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {String(phones[1]) + '°'}
                        </div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Z-Axis
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {String(phones[2]) + '°'}
                        </div>
                    </div>
                </div>
                <div className="phones-graph-wrapper">
                    <div className="phone-graph-wrapper">
                        <div className="phone-graph-title"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Memory
                        </div>
                        <div className="phone-graph">
                            <div className="progress-status">
                                <div className="barOverflow">
                                    <div
                                        className="bar"
                                        style={this.arcGraph(phoneData.memoryusage)}>
                                    </div>
                                    <div className="arc-data-display"
                                         style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                        {phoneData.memoryusage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="phone-graph-wrapper">
                        <div
                            className="phone-graph-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            CPU
                        </div>
                        <div className="phone-graph">
                            <div className="progress-status">
                                <div className="barOverflow">
                                    <div style={this.arcGraph(phoneData.cpuusageidle)} className="bar">
                                    </div>
                                    <div
                                        className="arc-data-display"
                                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                        {removeDecimal(phoneData.cpuusageidle)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="phone-graph-wrapper">
                        <div
                            className="phone-graph-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Battery 
                        </div>
                        <div className="progress-status">
                            <div className="barOverflow">
                                <div
                                    className="bar"
                                    style={this.arcGraph(phoneData.battery)}>
                                </div>
                                <div
                                    className="arc-data-display"
                                    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                    {phoneData.battery}
                                </div>
                            </div>
                        </div>
                        <div
                            className="phone-graph-percentage"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phones[4]}
                        </div>
                    </div>
                </div>
                <div className="phone-main-data-wrapper">
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Phone
                        </div>
                        <div className="reading"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.model}
                        </div>
                    </div>
                    <div>
                        <div className="reading-title"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Version
                        </div>
                        <div className="reading"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.osversion}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Screen
                        </div>
                        <div className="reading"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.screenwidth} x {phoneData.screenheight}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            WiFi
                        </div>
                        <div
                            className="reading abbrev"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.wifiname}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Network
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.carrier}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Connection
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.dataconnection}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Brightness
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.brightness}
                        </div>
                    </div>
                    <div>
                        <div className="reading-title"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Storage Free
                        </div>
                        <div className="reading"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.freespace}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Barometer
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.barometer}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Orientation
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.deviceorientation}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Camera
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.picturequality ? phoneData.picturequality : 'NA'}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Picture
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.picturequality ? phoneData.picturequality : 'NA'}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Language
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.devicelanguage}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Country
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.country}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Currency
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.devicecurrency}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Airplane
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.airplanemode ? phoneData.airplanemode : 'OFF'}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            GPS
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.gpsonoff}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Jailbroken
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.jailbroken}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Phone Time
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.devicetime}
                        </div>
                    </div>
                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Time Zone
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.timezone}
                        </div>
                    </div>

                    <div>
                        <div
                            className="reading-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Phone Uptime
                        </div>
                        <div
                            className="reading"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {phoneData.systemuptimeseconds}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        sliderValue: state.mediaSingleReducer.sliderValue
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Phone))
