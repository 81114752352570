import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from 'moment';

import {deleteMedia, toggleEdit, updateMedia} from "../actions/index";
import {clearMediaObject} from "../MediaSingle/actions";

import LoadSpinner from "../LoadSpinner/LoadSpinner";
import DeskTopEdit from "./DeskTopEdit"
import MobileEditComponent from "./MobileEditComponent";

import './EditMedia.css'
import ModalComponent from "../ModalComponent/ModalComponent";

/*
Edit Parent component possessing state and necessary methods
Will possess logic to render desktop or mobile component
 */
class EditMedia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleModal: false,
            editing:false,
            duration:0,
            deleteCheckboxValue:false,
            currentThumbnail:null,
            formFields: {
                mediatitle: props.media.mediatitle,
                mediadescription: props.media.mediadescription,
                mediasharemethod: props.media.mediasharemethod,
                mediacoverurl:this.handleSetMediaCoverUrl(),
                mediacoverimage:null,
                setcoverphoto:false
            },
            originalFormFields: {
                mediatitle: props.media.mediatitle,
                mediadescription: props.media.mediadescription,
                mediasharemethod: props.media.mediasharemethod,
                mediacoverurl:this.handleSetMediaCoverUrl(),
                mediacoverimage:null,
                setcoverphoto:false
            }
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.media !== prevProps.media  && !this.state.editing) {
            this.setState({
                formFields: {
                    mediatitle: this.props.media.mediatitle,
                    mediadescription: this.props.media.mediadescription,
                    mediasharemethod: this.props.media.mediasharemethod,
                    mediacoverurl:this.handleSetMediaCoverUrl(),
                    mediacoverimage:null,
                    setcoverphoto:false
                },
                originalFormFields: {
                    mediatitle: this.props.media.mediatitle,
                    mediadescription: this.props.media.mediadescription,
                    mediasharemethod: this.props.media.mediasharemethod,
                    mediacoverurl:this.handleSetMediaCoverUrl(),
                    mediacoverimage:null,
                    setcoverphoto:false
                },
                duration:moment.duration(this.props.media.mediaduration).asSeconds()
            })
        }
    };

    componentWillUnmount() {
        const {mediacoverurl,setcoverphoto}=this.state
        const {toggleEdit,clearMediaObject, edit} = this.props;

        toggleEdit(false);
        // clearMediaObject();
        if(setcoverphoto)
            URL.revokeObjectURL(mediacoverurl);
    };

    handleSetMediaCoverUrl = () => {
        const {media:{mediaurl,mediacoverurl,mediatype}} = this.props;
        let url="";
        if(mediatype === 'image'){
            url = mediaurl;
        }else {
            url = mediacoverurl;
        }
        return url ? url : `${process.env.PUBLIC_URL}/videoCoverTemp.png`
    };

    handleUpdate = () => {
        const {toggleEdit,error} = this.props;
        const {updateMedia, media: {mediatoken, mediatype}} = this.props;
        const {formFields,editing} = this.state;
        if(error || !editing)return;
        updateMedia(mediatoken, formFields, mediatype);
        this.setState({editing:false,formFields: {}});
        // toggleEdit(false)
    };

    handleEdit = (fieldName, value) => {
        let newFieldValues = {...this.state.formFields};
        newFieldValues[fieldName] = value;
        this.setState({editing:true,formFields: newFieldValues})
    };

    handleUndoChanges = ()=>{
        const {currentThumbnail} = this.state
        if(currentThumbnail){
            currentThumbnail.current.style.opacity = .4
        }
        this.setState({currentThumbnail:null,editing:false,formFields: this.state.originalFormFields})
    }

    handleMediaDelete = () => {
        const {media, authtoken, deleteMedia} = this.props;

        deleteMedia({
            authtoken: authtoken,
            token: media.mediatoken,
            mediaType: media.mediatype
        });
        this.setState({deleteCheckboxValue:false})
    };

    handleSelectImageThumbnail = (thumbnail,ref) =>{
        //Blob object is returned from VideoThumbnail component
        this.handleThumbnailStyle(ref);
        this.setState({editing:true,formFields:{
                ...this.state.formFields,mediacoverurl:URL.createObjectURL(thumbnail),
                setcoverphoto:true,
                mediacoverimage:thumbnail}
        });
    };

    handleThumbnailStyle = thumbnailRef =>{
        thumbnailRef.current.style.opacity = 1
        this.setState((prevState) =>{
            if(!prevState.currentThumbnail){
                thumbnailRef.current.style.opacity = 1
                return {
                    currentThumbnail:thumbnailRef
                }
            }
            prevState.currentThumbnail.current.style.opacity = .4
            thumbnailRef.current.style.opacity = 1
            return {
                currentThumbnail: thumbnailRef
            }
        })
    }

    createTimeStampArray = (duration) =>{
        const {width} =this.props
        if(width > 834){
            return  [1,1.5, Math.round(duration /2), Math.round(duration - .5)]
        }
        return  [1, Math.round(duration /2), Math.round(duration - .5)]
    };

    handleDeleteCheckbox = (e)=>{
        const name = e.target.name
        const value =e.target.checked
        if(name === "deletecheckbox"){
            this.setState({deleteCheckboxValue:value})
        }else{
            this.setState({deleteCheckboxValue:false})
        }
    }

    render() {
        const {media, clientchannel, history, requestFullfilled, error, loading, width} = this.props;
        const {handleMediaDelete,handleSelectImageThumbnail,createTimeStampArray,handleDeleteCheckbox,handleCancelDelete} = this;

        if (requestFullfilled) {
            history.push(`/${clientchannel}`);
            return null;
        }
        if (loading && !error) {
            return <LoadSpinner/>
        } else {
            return (
                <div>
                    {width > 834 ? <DeskTopEdit
                            media={media}
                            editState={this.state}
                            handleEdit={this.handleEdit}
                            clientchannel={clientchannel}
                            createTimeStampArray={createTimeStampArray}
                            handleSelectImageThumbnail={handleSelectImageThumbnail}
                            handleDeleteCheckbox={handleDeleteCheckbox}
                            handleUpdate={this.handleUpdate}
                            handleUndoChanges={this.handleUndoChanges}
                        />
                        :
                        <MobileEditComponent
                            media={media}
                            editState={this.state}
                            handleEdit={this.handleEdit}
                            clientchannel={clientchannel}
                            createTimeStampArray={createTimeStampArray}
                            handleSelectImageThumbnail={handleSelectImageThumbnail}
                            handleDeleteCheckbox={handleDeleteCheckbox}
                            handleUpdate={this.handleUpdate}
                            handleUndoChanges={this.handleUndoChanges}
                        />
                    }
                    <ModalComponent
                        clickedFunction={handleMediaDelete}
                        handleDeleteCheckbox={handleDeleteCheckbox}
                    />
                </div>
            )
        }
    }
}

const convertMedia = media => {
    if (!media) return;
    const mediaObj = {};
    for (const key in media) {
        mediaObj[key.replace(/image|video|audio/g, 'media')] = media[key]
    }
    return mediaObj
};

const mapStateToProps = (state) => {
    return {
        clientid: state.loginReducer.authentication.clientid,
        media: convertMedia(state.mediaSingleReducer.currentMedia),
        requestFullfilled: state.mediaSingleReducer.requestFullfilled,
        authtoken: state.loginReducer.authentication.loginToken,
        clientchannel: state.loginReducer.authentication.clientchannel,
        error: state.MediaReducer.error,
        loading: state.mediaSingleReducer.fetchingMedia,
        updateMessage:state.mediaSingleReducer.updateMessage,
        edit: state.navReducer.edit,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        },
        updateMedia: (mediatoken, formFields, mediatype) => {
            dispatch(updateMedia(mediatoken, formFields, mediatype))
        },
        deleteMedia: (payload) => {
            dispatch(deleteMedia(payload))
        },
        clearMediaObject:()=>{
            dispatch(clearMediaObject())
        }
    }
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditMedia)
);
