import React from 'react'
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const DeepLinkRoute = ({...props}) => {
    localStorage.setItem("clientchannel","");
    return <Route {...props}/>
};

export default DeepLinkRoute
