import React from 'react'

let generateRepPanel = (mobile, props) => {
    if (mobile) {
        return (
            <div className="modal-container">
                <div className="top-title-header">
                    <div className="reputation-title">VideoLock Reputation Score</div>
                    <div
                        className="reputation-close"
                        onClick={() => props.toggleReputationModal()}
                    >
                        X
                    </div>
                </div>
                <div>
                    <p className="reputation-data-wrapper">All VideoLock media is certified during recording, in
                        real-time, with digital DNA
                        fingerprints at 30-times-per-second and mapped with 80+ layers of metadata.</p>
                    <p className="reputation-data-wrapper">The VideoLock Reputation Score helps you evaluate on
                        a
                        second-by-second basis with the
                        following results:</p>
                </div>
                <div className="reputation-grid">
                    <div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#4bd964'}}
                            >
                                VALID
                            </div>
                            <ul>
                                <li>The image has not been modified - not even a single pixel -and</li>
                                <li>The sound has not been altered, and</li>
                                <li>The metadata has not been altered, and</li>
                                <li>All 80+, layers of metadata were precise and available during recording</li>
                            </ul>
                        </div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#fccf10'}}
                            >
                                CAUTION
                            </div>
                            <ul className="nested-ul">
                                <li>The image has not been modified, and</li>
                                <li>The sound has not been altered, and</li>
                                <li>The metadata has not been altered, but</li>
                                <li>One of the 80+ layers of metadata was not available/precise:</li>
                                <ul>
                                    <li>GPS was not available</li>
                                    <li>GPS accuracy was wider than 50 feet</li>
                                    <li>No internet connection during the recording</li>
                                    <li>Slow or delayed connection</li>
                                </ul>
                            </ul>
                        </div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#ff4040'}}
                            >
                                INVALID
                            </div>
                            <ul className="nested-ul">
                                <li>A portion of an image has been modified or</li>
                                <li>A portion of the audio has been modified or</li>
                                <li>Any one the 80+ layers of metadata has been charged</li>
                                <ul>
                                    <li>Location changed</li>
                                    <li>Time changed</li>
                                    <li>Cell tower proximity changed</li>
                                    <li>Speed, direction, altitude changed</li>
                                    <li>Satellite location changed</li>
                                </ul>
                                <li> If the recording was not originally created by VideoLock or the recording has been
                                    compressed by an email or text messaging system, the entire recording will be
                                    considered invalid.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#bfbfbf'}}
                            >
                                TRIMMED
                            </div>
                            <ul>
                                <li>Part of the original recording is not included in the version you are
                                    watching
                                </li>
                            </ul>
                        </div>
                        {/*<div className="reputation-data-wrapper-note">*/}
                        {/*    * If the recording was not originally created by VideoLock or the recording has*/}
                        {/*    been*/}
                        {/*    compressed by an email or text messaging system, the entire recording will be*/}
                        {/*    considered*/}
                        {/*    invalid.*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>)
    } else {
        return (
            <div className="modal-container">
                <div className="top-title-header">
                    <div className="reputation-title">VideoLock Reputation Score</div>
                    <div
                        className="reputation-close"
                        onClick={() => props.toggleReputationModal()}
                    >
                        X
                    </div>
                </div>
                <div>
                    <p className="reputation-data-wrapper">All VideoLock media is certified during recording, in
                        real-time, with digital DNA
                        fingerprints at 30-times-per-second and mapped with 80+ layers of metadata.</p>
                    <p className="reputation-data-wrapper">The VideoLock Reputation Score helps you evaluate on
                        a
                        second-by-second basis with the
                        following results:</p>
                </div>
                <div className="reputation-grid">
                    <div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#4bd964'}}
                            >
                                VALID
                            </div>
                            <ul>
                                <li>The image has not been modified - not even a single pixel -and</li>
                                <li>The sound has not been altered, and</li>
                                <li>The metadata has not been altered, and</li>
                                <li>All 80+, layers of metadata were precise and available during recording</li>
                            </ul>
                        </div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#fccf10'}}
                            >
                                CAUTION
                            </div>
                            <ul className="nested-ul">
                                <li>The image has not been modified, and</li>
                                <li>The sound has not been altered, and</li>
                                <li>The metadata has not been altered, but</li>
                                <li>One of the 80+ layers of metadata was not available/precise:</li>
                                <ul>
                                    <li>GPS was not available</li>
                                    <li>GPS accuracy was wider than 50 feet</li>
                                    <li>No internet connection during the recording</li>
                                    <li>Slow or delayed connection</li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#ff4040'}}
                            >
                                INVALID
                            </div>
                            <ul className="nested-ul">
                                <li>A portion of an image has been modified or</li>
                                <li>A portion of the audio has been modified or</li>
                                <li>Any one the 80+ layers of metadata has been charged</li>
                                <ul>
                                    <li>Location changed</li>
                                    <li>Time changed</li>
                                    <li>Cell tower proximity changed</li>
                                    <li>Speed, direction, altitude changed</li>
                                    <li>Satellite location changed</li>
                                </ul>
                                <li>If the recording was not originally created by VideoLock or the recording has
                                    been
                                    compressed by an email or text messaging system, the entire recording will be
                                    considered
                                    invalid.
                                </li>
                            </ul>
                        </div>
                        <div className="reputation-card-wrapper">
                            <div
                                className="top-header"
                                style={{background: '#bfbfbf'}}
                            >
                                TRIMMED
                            </div>
                            <ul>
                                <li>Part of the original recording is not included in the version you are
                                    watching
                                </li>
                            </ul>
                        </div>
                        {/*<div className="reputation-data-wrapper-note">*/}
                        {/*    * If the recording was not originally created by VideoLock or the recording has*/}
                        {/*    been*/}
                        {/*    compressed by an email or text messaging system, the entire recording will be*/}
                        {/*    considered*/}
                        {/*    invalid.*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>)

    }
};

const ReputationSummary = (props) => (
    <div>
        {generateRepPanel(props.mobile, props)}
    </div>
);

export default ReputationSummary