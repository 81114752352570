import React from 'react'
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import {mediaFilter,logout} from './actions'

const handleChannel =(channel,media,logout) =>{
    const clientchannel = localStorage.getItem("clientchannel")
    if((!channel && !media) || (channel === "null")){
        return null
    }

    if(channel !== clientchannel){
      /*
      This applies only to embed.
      The logout action has to be triggered here or
      the page will default to login without a refresh due to auth removal
      */
      if(channel === 'logout'){
          //when embedded we want to avoid toggling the usermenu on
          //when logging out. We WANT the blank screen
          const embed = true;
          logout(embed);
      }
        localStorage.setItem("clientid", 0);
    }
    if(!channel && media){
      localStorage.setItem("clientchannel","")
        return true
    };

    localStorage.setItem("clientchannel", channel);
    return true

}
//ROUTE----------------------------------------------------------------------
const InitialRoute = ({logout,setClientId,mediaFilter,computedMatch:{params},...props}) => {
   const validPath = handleChannel(params.channel,params.media,logout);
    if(validPath){
        return <Route {...props}/>
    }
    return <Redirect to='/login'/>
}

export default connect(
    state =>({

    }),
    dispatch =>({
        mediaFilter: (mediaObject) =>{
            dispatch(mediaFilter(mediaObject))
        },
        logout:(embed)=>{
            dispatch(logout(embed))
        }
    })
)(InitialRoute)
