import React from "react";

const radioValues = [
    {
        value: "public",
        title: "Public",
        icon:
            <div className="vis-icon-wrapper">
                <img className="vis-icon-image" src={`${process.env.PUBLIC_URL}/visibilityicons/publicicon.png`}
                     alt=""/>
            </div>,
        description: "Anyone can search for and view"
    },
    {
        value: "hidden",
        title: "Unlisted",
        icon:
            <div className="vis-icon-wrapper">
                <img className="vis-icon-image" src={`${process.env.PUBLIC_URL}/visibilityicons/unlistedicon.png`}
                     alt=""/>
            </div>
        ,
        description: "Anyone with the link can view"
    },
    {
        value: "private",
        title: "Private",
        icon:
            <div className="vis-icon-wrapper">
                <img className="vis-icon-image" src={`${process.env.PUBLIC_URL}/visibilityicons/privateicon.png`}
                     alt=""/>
            </div>
        ,
        description: "Only you can view"
    }
]

export default radioValues;
