import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import objEmpty from '../utilityFunctions/isEmptyObj'
import {setDisplay} from './actions'
import {metaDataIsSet, setEncryption, setOverlayData} from '../MediaSingle/actions'
import {
    getPhoneData,
    getPhoneDirectionData,
    getLocationData,
    getTimeData,
    getEncryptionData,
    getConnectionData,
    getSummaryData,
    getSoundData
} from './drawerUtilityFunctions/displayHelperFunctions'
import {
    generateDataTimeDelay,
    generateCoordinates,
    gatherEncryptionData,
    additionalFrameData,
    generateFrameValidData,
    generateFrameGradeReasons
} from './drawerUtilityFunctions/metaDataSetterFunctions'
import './drawer.css'

import Location from "../DrawerComponents/Location/Location";
import Time from "../DrawerComponents/Time/Time";
import Phone from "../DrawerComponents/Phone/Phone";
import Connection from "../DrawerComponents/Connection/Connection";
import Encryption from "../DrawerComponents/Encryption/Encryption";
import Summary from "../DrawerComponents/Summary/Summary";
import Networks from "../DrawerComponents/Networks/Networks";

// import Sound from "../DrawerComponents/Sound/Sound";

class Drawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            frameNum: 0,
            invokeDisplay: false,
            currentFrame: {},
            currentMetaData: {},
            // lastMetaFrameNum:0
        };
    }

    componentDidMount() {
        // const {mediaType} = this.props;
        this.metaDataSetter();
    }

    componentDidUpdate(prevProps, prevState) {
        // A combination of DidMount and DidUpdate are needed to populate drawer state on entry
        const {invokeDisplay} = this.state
        //compares current props with previous, if there is an update rerender
        if (this.props.mediaFrames !== prevProps.mediaFrames) {
            this.metaDataSetter();
            this.setState({invokeDisplay: true})
        }
        if (invokeDisplay !== prevState.invokeDisplay) {
            this.handleDrawerDisplay()
        }
        //calls handleDrawerDisplay if currentsequence changes
        if (this.props.currentsequence !== prevProps.currentsequence) {
            this.handleDrawerDisplay()
        }
        if (this.getTime(this.props.currentsequence) !== this.getTime(prevProps.currentsequence)) {
            //metaDataSetter does not need to fire on milliseconds(currentsequence)
            this.metaDataSetter();
        }
        if (this.props.drawerOpen !== prevProps.drawerOpen ||
            this.props.mediaIsSet !== prevProps.mediaIsSet
        ) {

            this.handleDrawerDisplay()
        }
    }

    componentWillUnmount() {
        this.setState({currentMetaData: {}, invokeDisplay: false})
    }

    setFrame = () => {
        //Not currently used
        this.setState({frameNum: this.state.frameNum + 1})
    };

    calculateDuration() {
        //duration passed in via props from player state in MediaSingle
        //This will need to be refined and optimized
        const {duration, totalFrames} = this.props;
        return duration / parseInt(totalFrames) + .005
    }

    getTime = (time) => {
        if (!isNaN(time) && time > 0) {
            return (
                (Math.floor(time / this.calculateDuration()))
            );
        }
        return 0
    };

    metaDataSetter = () => {
        //All calculation, organization and data parsing will be done within MetaDataSetter and
        //it's associated helper functions.
        const time = this.getTime(this.props.currentsequence);
        const {mediaFrames, mediaIsSet, params: {media}} = this.props;
        if (objEmpty(mediaFrames)) return;

        const frameNumbers = Object.keys(mediaFrames);
        let currentFrame = frameNumbers[time] !== undefined ? frameNumbers[time] : frameNumbers[frameNumbers.length - 1];
        //check against out of bounds should calculations be off (currently will be)
        const additionalData = additionalFrameData(mediaFrames[currentFrame]);
        const connectionSpeedArray = generateCoordinates(frameNumbers, mediaFrames, 'connectionspeed');
        const dataTimeDelayArray = generateDataTimeDelay(frameNumbers, mediaFrames, 'latency');
        const distancetravelledArray = generateCoordinates(frameNumbers, mediaFrames, 'distancetravelled');
        const decibelArray = generateCoordinates(frameNumbers, mediaFrames, 'decibel');
        const encryptionData = gatherEncryptionData(mediaFrames[currentFrame], media);
        const gpsaltitudeArray = generateCoordinates(frameNumbers, mediaFrames, 'gpsaltitude');
        const gpshorizontalaccuracyArray = generateCoordinates(frameNumbers, mediaFrames, 'gpsaccuracy');
        const locationSpeedArray = generateCoordinates(frameNumbers, mediaFrames, 'speed');
        const frameGradeData = generateFrameGradeReasons(frameNumbers, mediaFrames);
        const summaryFrameData = generateFrameValidData(frameNumbers, mediaFrames);

        const rawMetaData = {
            ...additionalData,
            ...mediaFrames[currentFrame].meta,

            connectionSpeedArray,
            dataTimeDelayArray,
            decibelArray,
            distancetravelledArray,
            encryptionData,
            frameGradeData,
            gpsaltitudeArray,
            gpshorizontalaccuracyArray,
            locationSpeedArray,
            summaryFrameData,
        };
        const metaData = this.organizeMetaData(rawMetaData);

        //Currently Setting encrypt data for half view in reducer
        this.props.setEncryption(metaData.encryptionData);

        //Currently Setting data for media overlay
        this.props.setOverlayData({
                reason: additionalData.colorreason,
                color: additionalData.color
            }
        );
        this.setState({currentMetaData: metaData}, () => {
            if (!mediaIsSet) {
                this.props.metaDataIsSet()
            }
        });
    };

    organizeMetaData = (rawMetaData) => {
        let organizedMetaData = {
            connectionData: getConnectionData(rawMetaData),
            locationData: getLocationData(rawMetaData),
            encryptionData: getEncryptionData(rawMetaData),
            phoneData: getPhoneData(rawMetaData),
            summaryData: getSummaryData(rawMetaData),
            soundData: getSoundData(rawMetaData),
            timeData: getTimeData(rawMetaData),
        };
        organizedMetaData.phoneData.phoneDirectionData = getPhoneDirectionData(rawMetaData);
        return organizedMetaData;
    };

    handleDrawerDisplay = () => {
        //if meta has not been set exit function
        const {currentMetaData} = this.state;
        const {setDisplay} = this.props;
        if (!this.props.mediaIsSet || objEmpty(currentMetaData)) {
            return
        }
        setDisplay(currentMetaData);
    };

    getDrawerBackground = () => {
        const {drawerOpen, fullscreenDesktopOn} = this.props;

        if (fullscreenDesktopOn || drawerOpen) {
            return ''
        } else {
            return 'black'
        }
    };

    calcDrawerHeight = () => {
        const {fullscreenDesktopOn} = this.props;
        //Fullscreen desktop view
        if (!fullscreenDesktopOn) {
            return window.innerHeight - 75
        } else {
            return window.innerHeight - 180
        }
    };

    render() {
        const {displayData, mediaFrames} = this.props;
        // const {mediaType} = this.props;
        const {getDrawerBackground} = this;

        return (
            <div className="drawer-container"
                 style={{maxHeight: this.calcDrawerHeight()}}
            >
                <div
                    className="drawer-wrapper"
                    style={{background: getDrawerBackground()}}
                >
                    {/*<div>{this.props.currentsequence}</div>*/}
                    <Summary
                        summaryData={displayData.summaryData}
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        frames={Object.keys(mediaFrames)[Object.keys(mediaFrames).length - 1]}
                    />
                    <Location
                        summaryData={displayData.summaryData}
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        locationData={displayData.locationData}/>
                    <Time
                        timeData={displayData.timeData}
                        connectionData={displayData.connectionData}
                    />
                    <Phone phoneData={displayData.phoneData}/>
                    {/*{mediaType !== 'image' &&*/}
                    {/*<Sound*/}
                    {/*    progress={this.props.currentsequence}*/}
                    {/*    duration={this.props.duration}*/}
                    {/*    soundData={displayData.soundData}*/}
                    {/*/>}*/}
                    <Connection
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        connectionData={displayData.connectionData}/>
                    <Networks
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        connectionData={displayData.connectionData}
                        encryptionData={displayData.encryptionData}
                    />
                    <Encryption encryptionData={displayData.encryptionData}/>
                    {/*<ul className="list-group">*/}
                    {/*    {*/}
                    {/*        currentMetaData.map(data =>*/}
                    {/*            <li className="list-group-item metaListItem">{data.key}: {data.value}</li>)*/}
                    {/*    }*/}
                    {/*</ul>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        params: params,
        player: state.player,
        fullscreenDesktopOn: state.mediaSingleReducer.fullscreenDesktopOn,
        drawerOpen: state.mediaSingleReducer.drawerOpen,
        mediaIsSet: state.mediaSingleReducer.mediaIsSet,
        mediaFrames: state.mediaSingleReducer.mediaFrames,
        mediaType: state.mediaSingleReducer.currentMedia.mediatype,
        totalFrames: state.mediaSingleReducer.totalFrames,
        displayData: state.drawerDisplayReducer.displayData

    }
};

const mapDispatchToProps = dispatch => {
    return {
        metaDataIsSet: () => {
            dispatch(metaDataIsSet())
        },
        setEncryption: (data) => {
            dispatch(setEncryption(data))
        },
        setOverlayData: (data) => {
            dispatch(setOverlayData(data))
        },
        setDisplay: currentMetaData => {
            dispatch(setDisplay(currentMetaData))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Drawer));
