import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './playbar.css'
import {} from "../actions";
import {setPlaybarPercentage} from '../MediaSingle/actions'
import {formatDuration} from '../utilityFunctions/formatDuration'

class Playbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPercentage: '',
            initialSet: false,
            flexState: '',
            frameStartJSX: {},
            dualTrimmPadding: 0
        };
    }

    componentDidMount() {
        const {generateTrimmCont} = this;
        const {setPlaybarPercentage} = this.props;
        generateTrimmCont();

        if (this.playbarDurationBox) {
            let percentage = (this.playbarDurationBox.clientWidth / this.playbarSubBar.clientWidth) * 100;
            setPlaybarPercentage(percentage);

            this.setState({
                displayPercentage: percentage / 2,
                currentDurationWidth: this.playbarDurationBox.clientWidth
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {setPlaybarPercentage} = this.props;
        const {generateTrimmCont} = this;
        let duration = this.props.videoPlayer.duration;
        let percentage = '';
        const {initialSet} = this.state;

        if (prevProps !== this.props) {
            generateTrimmCont();
        }

        if (!initialSet && this.props.color === 'yellow') {
            //this checks if the first frame is yellow then we need calculate that differently then if the first frame is green
            if (this.playbarDurationReason) {
                if (this.playbarDurationReason.clientWidth > 60) {
                    percentage = (this.playbarDurationReason.clientWidth / this.playbarSubBar.clientWidth) * 100;
                    setPlaybarPercentage(percentage);
                    this.setState(
                        {
                            displayPercentage: percentage / 2,
                            currentDurationWidth: this.playbarDurationBox.clientWidth,
                            initialSet: true
                        }
                    )
                }
            }
            return
        }

        if (this.playbarDurationBox && !this.state.initialSet && this.props.color) {

            if (this.playbarDurationBox.clientWidth > 0) {
                percentage = (this.playbarDurationBox.clientWidth / this.playbarSubBar.clientWidth) * 100;
                setPlaybarPercentage(percentage);
                this.setState({
                    displayPercentage: percentage / 2,
                    currentDurationWidth: this.playbarDurationBox.clientWidth,
                    initialSet: true
                })
            }
        }

        if (this.props.color !== prevProps.color && duration > 0) {
            //This is fired when frame change from original point and the media advances
            if (prevProps.color === 'green' && this.props.color === 'yellow') {
                percentage = (this.playbarDurationBox.clientWidth / this.playbarSubBar.clientWidth) * 100 / 2;
                this.setState({
                    displayPercentage: percentage,
                    entering: !this.state.entering,
                    currentDurationWidth: this.playbarDurationBox.clientWidth,
                })
            } else if (prevProps.color === 'yellow' && this.props.color === 'green') {
                percentage = (this.playbarDurationBox.clientWidth / this.playbarSubBar.clientWidth) * 100 / 2 - .75;
                this.setState({
                    displayPercentage: percentage,
                    entering: !this.state.entering,
                    currentDurationWidth: this.playbarDurationBox.clientWidth,
                })
            }
        }
    }

    componentWillUnmount() {
        if (window.location.href.indexOf("/v/") === -1) {
            this.setState({
                flexState: '',
                frameStartJSX: {},
                dualTrimmPadding: 0
            })
        }
    }

    generateTrimmCont = () => {
        const {
            frameStart,
            frameEnd,
            totalFrames
        } = this.props;

        //Setting at 97 and 3 because it seems to match subbarurl better

        // could create a calc function for getting correct start end etc
        //Could make this a global and not have to call it on each playbar that needs it set those values that
        //could be used for the summary bar

        let frameStartJSX = {};
        let calcFrameStart = '';
        let calcFrameEnd = '';
        frameStartJSX['display'] = 'flex';

        if (frameStart && frameEnd) {
            calcFrameStart = ((totalFrames - frameStart) / totalFrames) * 100;
            calcFrameEnd = ((totalFrames - frameEnd) / totalFrames) * 100;
            frameStartJSX['width'] = (calcFrameStart - calcFrameEnd) + '%';
            // console.log(calcFrameStart)
            // console.log(calcFrameEnd);
            // console.log('above values')
            // console.log('frameOBj',frameStartJSX)

            //TODO some reason the state not setting or some other issue but its happening here on mobile popover drawer

            this.setState({
                flexState: 'left',
                frameStartJSX: frameStartJSX,
                dualTrimmPadding: 96 - parseInt(calcFrameStart) + '%'
            });
            // console.log('set obj', this.state.frameStartJSX);
            return
        }
        if (frameStart) {
            frameStartJSX['width'] = 100 - ((totalFrames - frameStart) / totalFrames) * 100 + '%';
            this.setState({
                flexState: 'right',
                frameStartJSX: frameStartJSX
            });
            return
        }
        if (frameEnd) {
            frameStartJSX['width'] = 100 - ((totalFrames - frameEnd) / totalFrames) * 100 + '%';

            // console.log(frameStartJSX);
            this.setState({
                flexState: 'left',
                frameStartJSX: frameStartJSX
            });
        } else {
            this.setState({
                frameStartJSX: {
                    width: '100%'
                }
            })
        }
    };

    magicSmoothSpellPlayer = () => {
        const {videoPlayer} = this.props;

        let stepValue = ((1 / videoPlayer.duration) * 100);

        if (videoPlayer.currentTime === videoPlayer.duration || videoPlayer.currentTime === 0) {
            //End of video reset to non smoothed version
            return (videoPlayer.currentTime * 100 / videoPlayer.duration) + '%'
        }

        if (!videoPlayer.paused) {
            //Video is playing adds a second of calc time to match transition
            return (videoPlayer.currentTime * 100 / videoPlayer.duration) + stepValue + '%'
        } else {
            //Video is paused
            return (videoPlayer.currentTime * 100 / videoPlayer.duration) + '%'
        }
    };

    magicSmoothPlayerBox = () => {
        const {videoPlayer} = this.props;
        const {frameStartJSX} = this.state;

        let stepValue = ((1 / videoPlayer.duration) * parseInt(frameStartJSX.width));

        if (videoPlayer.currentTime === videoPlayer.duration || videoPlayer.currentTime === 0) {
            //End of video reset to non smoothed version
            return (videoPlayer.currentTime * parseInt(frameStartJSX.width) / videoPlayer.duration) + this.state.displayPercentage + '%'
        }

        if (!videoPlayer.paused) {
            //Video is playing adds a second of calc time to match transition
            return ((videoPlayer.currentTime * parseInt(frameStartJSX.width) / videoPlayer.duration) + this.state.displayPercentage) + (stepValue) + '%'
        } else {
            //Video is paused
            return (videoPlayer.currentTime * parseInt(frameStartJSX.width) / videoPlayer.duration) + this.state.displayPercentage + '%'
        }
    };


    render() {
        const {
            height,
            cssWrapperStyle,
            videoPlayer,
            media,
            subbarurl,
            handleMediaScrub,
        } = this.props;

        const {
            flexState,
            frameStartJSX,
            dualTrimmPadding
        } = this.state;

        const {magicSmoothSpellPlayer, magicSmoothPlayerBox} = this;

        return (
            <div>
                <div
                    className={cssWrapperStyle}
                    style={{'top': height}}
                >
                    <div className="mobile-playbar-wrapper">
                    </div>
                    <div className="half-view-frames-bar">
                    </div>
                    <div className="playbar-mobile-subbar-image"
                         ref={(playbarSubBar) => {
                             this.playbarSubBar = playbarSubBar
                         }}
                         style={{
                             display: 'flex',
                             justifyContent: flexState,
                             width: '100%'
                         }}
                    >
                        <img src={subbarurl} alt=""/>
                        <div style={{
                            marginLeft: dualTrimmPadding
                        }}>
                            <div className="playbar-fake-bar"
                                 style={{
                                     width: parseInt(frameStartJSX.width) + '%',
                                 }}
                            >
                            </div>
                            <div className="playbar-progress"
                                 style={{
                                     width: parseInt(frameStartJSX.width) + '%',
                                 }}
                            >
                                <div
                                    className="playbar-actual-progress"
                                    style={{
                                        width: magicSmoothSpellPlayer(),
                                        transition: !videoPlayer.paused && videoPlayer.currentTime !== 0 ? 'width linear 1s' : ''
                                    }}
                                >
                                </div>
                            </div>
                        </div>
                        {!isNaN(videoPlayer.duration) &&
                        <div
                            style={{
                                width: parseInt(frameStartJSX.width) + '%',
                            }}
                        >
                            <div className="playbar-wrapper-group"
                                 style={{
                                     // width: parseInt(frameStartJSX.width) + '%',
                                     width: magicSmoothPlayerBox(),
                                     // width: (videoPlayer.currentTime * parseInt(frameStartJSX.width) / videoPlayer.duration) + this.state.displayPercentage + '%',
                                     top: this.props.color === 'yellow' ? '-63px' : '-43px',
                                     transition: !videoPlayer.paused && videoPlayer.currentTime !== 0 ? 'width linear 1s' : ''
                                     // width: (videoPlayer.currentTime * parseInt(frameStartJSX.width) / videoPlayer.duration) + this.state.displayPercentage + 2 + '%',
                                 }}
                            >
                                <div className="playbar-box-wrapper"
                                >
                                    <div className="playbar-duration-reason"
                                         style={{background: this.props.color === 'yellow' ? '#f4db3f' : ''}}
                                         ref={(playbarDurationReason) => {
                                             this.playbarDurationReason = playbarDurationReason
                                         }}
                                    >
                                        {this.props.colorreason ? this.props.colorreason : ''}
                                    </div>
                                    <div className="playbar-duration-box"
                                         ref={(playbarDurationBox) => {
                                             this.playbarDurationBox = playbarDurationBox
                                         }}
                                    >
                                        {formatDuration(videoPlayer.currentTime)}
                                    </div>
                                    <div className="arrow-wrapper">
                                        <div className="bottom-arrow-centered">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {media.mediatype === "video" ?
                        <div className="playbar-slider-scrub"
                             style={{
                                 display: 'flex',
                                 justifyContent: flexState,
                                 marginLeft: dualTrimmPadding
                             }}
                        >
                            <div
                                style={frameStartJSX}
                            >
                                <input
                                    type="range"
                                    min="0"
                                    ref={(rangeBar) => {
                                        this.rangeBar = rangeBar
                                    }}
                                    max="100"
                                    value={videoPlayer.currentTime && videoPlayer.duration ? videoPlayer.currentTime * 100 / videoPlayer.duration : 0}
                                    onChange={(e) => handleMediaScrub(e.target)}
                                    className="playbar-scrubber"
                                    id="myRange"/>
                            </div>
                        </div>
                        :
                        <div className="playbar-slider-scrub">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={videoPlayer.currentTime && videoPlayer.duration ? videoPlayer.currentTime * 100 / videoPlayer.duration : 0}
                                onChange={(e) => handleMediaScrub(e.target)}
                                className="playbar-scrubber"
                                id="myRange"/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subbarurl: state.mediaSingleReducer.currentMedia.subbarurl,
        frameStart: state.mediaSingleReducer.currentMedia.framestart,
        frameEnd: state.mediaSingleReducer.currentMedia.frameend,
        totalFrames: state.mediaSingleReducer.currentMedia.videosequencecount
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPlaybarPercentage: (percentage) => {
            dispatch(setPlaybarPercentage(percentage))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Playbar));
