import React from 'react'
import ThumbnailGenerator from "../ThumbnailGenerator/ThumbnailGenerator";

const EditThumbnailComponent = ({media,duration,createTimeStampArray,handleSelectImageThumbnail}) =>{
    return (
        <div className="thumbnail-component-wrapper">
            <div className="thumbnails">
                <div>Thumbnail</div>
                <ThumbnailGenerator
                    media = {media}
                    duration={duration}
                    timeStampArray = {createTimeStampArray(duration)}
                    handleSelectThumbnail={handleSelectImageThumbnail}
                />
            </div>
        </div>

    )
}
export default EditThumbnailComponent
