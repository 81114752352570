import {getallmedia} from "../actions";

// export const privateUserView = view => {
//     return {
//         type: "USER_VIEW",
//         payload: view
//     };
// };

export const privateUserView = view => dispatch => {
    dispatch({
        type: "USER_VIEW",
        payload: view
    });
    dispatch(getallmedia())
};
