import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./desktopnavpopup.css"
import {verifyForgotPassword} from "./actions";

class VerifyPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {restAuth, back} =this.props;
        if(restAuth){
            back('ConfirmPassReset');
        }
    }
    
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleVerify = (e) => {
        const {back, clientId, verifyForgotPassword} = this.props;
        const {code} = this.state;

        e.preventDefault();

        if (clientId) {
        verifyForgotPassword({
            code: code,
            clientid: clientId
        });
        }
    };

    render() {
        const {back, verifyError} = this.props;
        const {handleVerify} = this;

        return (
            <div>
                <div onClick={() => back('Home')}>
                    <img className="back-arrow" src={`${process.env.PUBLIC_URL}/arrow_back.png`} alt=""/>
                </div>
                <div className='panel-message'>Please enter your reset code.</div>
                <form>
                    <div className="panel-form-input-container">
                        <input
                            type="search"
                            name="code"
                            placeholder="Confirmation Code"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="password-error-wrapper" style={{"marginLeft":"1rem"}}>
                        {verifyError}
                    </div>
                    <div className="button-container-center">
                        <button
                            className="navpop-up-blue-btn remove-styles-btn"
                            type="submit"
                            onClick={(e) => handleVerify(e)}
                        >
                            Verify
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        email: state.loginReducer.authentication.email,
        loginToken: state.loginReducer.authentication.loginToken,
        clientId: state.desktopNavReducer.verifyClientId,
        restAuth:state.desktopNavReducer.restAuth,
        verifyError:state.desktopNavReducer.verifyError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        verifyForgotPassword: payload => {
            dispatch(verifyForgotPassword(payload))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyPanel))