import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './grid.css'
import {mediaFilter, getallmedia} from "../actions";
import {toggleMobileSocialShare} from "../EditShareModal/toggleMobileSocialShare";
import {clearMediaObject} from "../MediaSingle/actions";
import ListMediaComponent from "../MediaComponent/ListMediaComponent";
import MediaComponent from '../MediaComponent/MediaComponent'
import LoadSpinner from '../LoadSpinner/LoadSpinner'
import SortModal from "../SortModal/SortModal";
import PrivateMediaComponent from "../MediaComponent/PrivateMediaComponent";
import {privateUserView} from '../UserMenuComponent/actions';
import MobilePrivateMediaComponent from "../MediaComponent/MobilePrivateMediaComponent"

let topSortOptions = [
    {title: ''},
    {title: 'Title'},
    {title: 'Reputation'},
    {title: 'Visibility'},
    {title: 'Recorded'},
    {title: 'Uploaded'},
    {title: 'Published'},
    {title: 'Views'},
    {title: ''},
]

class Grid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMediaTitle: 'All Media',
            shareurl:""
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        const {getallmedia,clearMediaObject, query, params: {channel}} = this.props
        this.checkSetChannel(channel)
        this.updateWindowDimensions();
        clearMediaObject();
        window.addEventListener('resize', this.updateWindowDimensions);
        if(channel !== 'logout' && !query)
        getallmedia();
    };

    componentDidUpdate(prevProps){
        const{getallmedia,loginToken,clientchannel,history,loading} =this.props;
        if(loginToken !== prevProps.loginToken){
            history.push(`/${clientchannel}`);
            if(clientchannel !== "logout"&& !loading){
                getallmedia();
            }
        }
        if(prevProps.clientchannel !== this.props.clientchannel && !loading){
            history.replace(`${clientchannel}`);
            if(clientchannel !== "logout"){
                getallmedia();
            }
        }
    }

    checkSetChannel = (channel) => {
        if (!channel) return
        const test = localStorage.getItem("clientchannel")
        if (test === "null" || test === "undefined" || !test) {
            localStorage.setItem("clientchannel", channel)
        }
    };

    updateWindowDimensions() {
        this.setState({width: window.innerWidth});
    }

    mediaSwitch = () => {
        //return array based on url search params
        const {currentMedia, loading} = this.props
        //clear screen until new media arrives
        if (loading) return [];

        return currentMedia.map(media => this.convertMedia(media));
    };

    // generateMediaTitle = () => {
    //     const {mediaType} = this.props;
    //
    //     switch (mediaType) {
    //         case 'v':
    //             return " Videos";
    //         case 'i':
    //             return " Images";
    //         case 'a':
    //             return " Audio";
    //         default:
    //             return []
    //     }
    // };

    convertMedia = media => {
        const mediaObj = {};
        for (const key in media) {
            mediaObj[key.replace(/image|video|audio/g, 'media')] = media[key]
        }
        return mediaObj
    };

    setLayout = (layout) => {
        if (layout === 'grid') {
            return "col-lg-3 col-md-4 p-2 grid-item"
        } else {
            return layout + "-grid"
        }
    };

    setWrapper = (layout) => {
        if (layout === 'grid') {
            return "row grid-media-row"
        } else {
            return "grid-media-row-list"
        }
    };

    setGridAlignment = (layout) => {
        const {userView} = this.props;

        if (userView !== 'private') {
            return "grid-media-nonlist-wrapper"
        } else {
            return ""
        }
    };

    generateGridCard = (media, key) => {
        const {embedded, userView} = this.props;
        let mobile = window.innerWidth < 900;

        if (embedded || mobile) {
            if (userView === 'private') {
                return (
                    <MobilePrivateMediaComponent
                        key={key}
                        media={media}/>
                )
            } else {
                return (
                    <ListMediaComponent
                        key={key}
                        media={media}/>
                )
            }
        } else {
            if (userView === 'private') {
                return (
                    <PrivateMediaComponent
                        key={key}
                        media={media}/>
                )
            }
            return (
                <MediaComponent
                    media={media}
                    key={key}
                />
            )
        }
    }

    mediaTypeDisplay = () => {
        const {mediaType} = this.props;
        switch (mediaType) {
            case 'v':
                return 'VIDEOS';
            case 'i':
                return 'IMAGES';
            case 'a':
                return 'AUDIO';
            default:
                return ''
        }
    };

    loadMoreBoolean = () => {
        const {videos, images, audios, mediaType} = this.props
        if (videos.length > 0 && mediaType === 'v') {
            return true
        }
        if (images.length > 0 && mediaType === 'i') {
            return true
        }
        if (audios.length > 0 && mediaType === 'a') {
            return true
        }
        return false
    };

/// on mount access to the video api from a switch on the passed toggle from app.js
// Public or Private depending on which make the call
    render() {
        const {layout, isOpen, loading, error, embedded,userView} = this.props;
        const {width, copySuccess,shareurl} = this.state;
        const {setGridAlignment, setLayout, setWrapper,generateGridCard} = this;
// will need a function to set type of media title
        return (
            !isOpen &&
            <div className="container-grid-wrapper">
                <div className="sort-profile-verified-container"
                     style={{paddingRight: width > 900 ? '3.4rem' : ''}}
                >
                    <SortModal/>
                </div>
                {userView === 'private' && width > 900 &&
                <div className="private-grid-container top-sort-container">
                    {topSortOptions.map((option, i) =>
                        <div
                            key={i}
                            className={'option-' + i}
                        >
                            {option.title}
                        </div>
                    )}
                </div>
                }
                {loading && <LoadSpinner/>}

                {error && <h1 style={{paddingLeft:'0'}} align="center">{error}</h1>}
                <div className={setGridAlignment(layout)}>
                    <div className={setWrapper(layout)}>
                        {this.mediaSwitch().map((media, i) =>
                            <div className={setLayout(layout)}
                                 key={i}
                            >
                                {generateGridCard(media, i)}
                            </div>
                        )}
                    </div>
                </div>

                {/*{*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            display: 'flex',*/}
                {/*            justifyContent: width < 900 ? '' : 'flex-end',*/}
                {/*            width: '100%'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                width: width < 900 ? '100%' : '50%'*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <PaginationControls/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*{{this.loadMoreBoolean() > 0 && (*/}
                {/*    <div className="load-more-wrapper">*/}
                {/*        <div className="load-more-text">LOAD MORE {this.mediaTypeDisplay()}}</div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        currentMedia: state.MediaReducer.currentMedia,
        layout: state.navReducer.layout,
        userView: state.navReducer.userView,
        mediaType: params.media,
        params: params,
        loading: state.MediaReducer.loading,
        isOpen: state.mobileMenuReducer.isOpen,
        allMedia: state.MediaReducer.allMedia,
        error: state.MediaReducer.error,
        embedded:state.loginReducer.embed,
        loginToken: state.loginReducer.authentication.loginToken,
        clientchannel: state.loginReducer.authentication.clientchannel,
        query: state.MediaReducer.query
    }
};

const mapDispatchToProps = dispatch => {
    return {
        mediaFilter: (filter) => {
            dispatch(mediaFilter(filter))
        },
        getallmedia: type => {
            dispatch(getallmedia(type))
        },
        clearMediaObject:()=>{
            dispatch(clearMediaObject())
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Grid))


// Line 30:   'type' is assigned a value but never used                   no-unused-vars
// Line 58:   'mediaShareType' is assigned a value but never used         no-unused-vars
// Line 59:   'capitalizeFirstLetter' is assigned a value but never used  no-unused-vars
// Line 149:  'videos' is assigned a value but never used                 no-unused-vars
// Line 149:  'images' is assigned a value but never used                 no-unused-vars
// Line 150:  'generateMediaTitle' is assigned a value but never used     no-unused-vars
// Line 150:  'setTitleProper' is assigned a value but never used         no-unused-vars
// Line 151:  'currentMediaTitle' is assigned a value but never used
