import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {logout, sortMedia} from '../actions'
import "./UserMenuComponent.css"
import LoginPanel from "../DesktopNavPopUp/LoginPanel";
import PasswordPanel from "../DesktopNavPopUp/PasswordPanel";
import CreateAccountPanel from "../DesktopNavPopUp/CreateAccountPanel";
import VerifyPanel from "../DesktopNavPopUp/VerifyPanel";
import ConfirmPassReset from "../DesktopNavPopUp/ConfirmPassReset";
import ProfileCircle from "../ChannelBanner/ProfileCircle";
import ChangePasswordPanel from "../DesktopNavPopUp/ChangePasswordPanel";
import ProfileOptionsPanel from "../DesktopNavPopUp/ProfileOptionsPanel";
import {privateUserView} from "./actions";

class UserMenuComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedOnHome: true,
            homeOn: true,
            loginOn: false,
            createAccountOn: false,
            changePasswordOn:false,
            resetPasswordOn: false,
            confirmationCodeOn: false,
            verifyOn: false,
            confirmPassResetOn: false,
            settingsOn:false
        }
    }


    triggerPanel = (choice) => {
        const {logout, history, privateUserView, embed} = this.props;
        const url = window.location.origin

        switch (choice) {
            case 'Your Channel (public)':
                privateUserView('public');
                return
            case 'Your Channel (private)':
                privateUserView('private');
                return
            case 'Sign in' :
                this.setState({
                    loginOn: true,
                    homeOn: false,
                    createAccountOn: false,
                    resetPasswordOn: false,
                    confirmationCodeOn: false,
                    verifyOn: false
                });
                break;
            case 'Home' :
                this.setState({
                    loginOn: false,
                    homeOn: true,
                    createAccountOn: false,
                    resetPasswordOn: false,
                    confirmationCodeOn: false,
                    confirmPassResetOn: false,
                    changePasswordOn: false,
                    verifyOn: false,
                    profileOptionsOn: false
                });
                break;
            case 'Forgot password' :
                this.setState({
                    loginOn: false,
                    homeOn: false,
                    createAccountOn: false,
                    resetPasswordOn: true,
                    confirmationCodeOn: false
                });
                break;
            case 'Edit Profile' :
                this.setState({
                    homeOn: false,
                    changePasswordOn: false,
                    profileOptionsOn: true
                })
                break;
            case 'Change Password' :
                this.setState({
                    profileOptionsOn: false,
                    settingsOn:false,
                    changePasswordOn: true,
                    homeOn: false,
                })
                break;
            case 'Register account' :
                this.setState({
                    createAccountOn: true,
                    loginOn: false,
                    homeOn: false,
                })
                break;
            case 'Verify' :
                this.setState({
                    createAccountOn: false,
                    loginOn: false,
                    homeOn: false,
                    resetPasswordOn: false,
                    verifyOn: true,
                })
                break;
            case 'ConfirmPassReset':
                this.setState({
                    createAccountOn: false,
                    loginOn: false,
                    homeOn: false,
                    resetPasswordOn: false,
                    verifyOn: false,
                    confirmPassResetOn: true
                })
                break;
            case 'Settings':
                this.setState({
                    createAccountOn: false,
                    loginOn: false,
                    homeOn: false,
                    resetPasswordOn: false,
                    verifyOn: false,
                    confirmPassResetOn: false,
                    settingsOn:true
                })
                break;
            case 'Sign out':
                this.setState({
                    createAccountOn: false,
                    loginOn: false,
                    homeOn: false,
                    resetPasswordOn: false,
                    verifyOn: false,
                    confirmPassResetOn: false
                })
                if(embed){
                    window.location.href =`${url}/logout`
                }
                logout();
                privateUserView('public');//reset view
                history.push('/login');
                break;
            default:
                break;
        }
    };

    render() {
        const {
            confirmPassResetOn,
            createAccountOn,
            changePasswordOn,
            homeOn,
            loginOn,
            resetPasswordOn,
            settingsOn,
            verifyOn,
        } = this.state;

        const {
            menuOptions,
            mobileMenuToggle,
            clientchannel,
            notLoggedIn,
            userView,
            email
        } = this.props;

        const {triggerPanel} = this;

        let isMobile = window.innerWidth < 900

        return (
            <div className="user-modal-container">
                {homeOn ?
                    <div className="user-modal-body-container"
                         style={{
                             width: isMobile ? "100%" : "300px",
                             maxWidth: isMobile ? "100%" : "300px"
                         }}
                    >
                        <div>
                            {isMobile &&
                            <div className="close-header">
                                <div className="close-svg"
                                     onClick={() => mobileMenuToggle()}
                                >
                                    <svg viewBox="0 0 24 24"
                                         preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                        </path>
                                    </svg>
                                </div>
                                {notLoggedIn &&
                                <div>
                                    Account
                                </div>
                                }
                            </div>
                            }
                        </div>
                        {!notLoggedIn &&
                            <div className="profile-box">
                                <ProfileCircle
                                    uploader={false}
                                    dimensions={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '14px'
                                    }
                                    }
                                />
                                <div>
                                    <div className="channelbanner-display-text"
                                         style={{
                                             fontSize: '14px',
                                             fontWeight: '600'
                                         }}
                                    >
                                        {clientchannel}
                                    </div>
                                    <div className="banner-email">
                                        {email}
                                    </div>
                                </div>

                            </div>
                        }
                        <ul>
                            {
                                menuOptions.map((option, i) =>
                                    <li key={i}
                                        style={{
                                            background: option.displayTitle.includes(userView)  ? 'lightgrey' : 'white',
                                            borderBottom: option.break ? 'solid .5px lightgrey' : 'none'
                                        }}
                                        onClick={(e) => triggerPanel(e.target.textContent)}
                                    >
                                        <div
                                            className="user-modal-list-icon-wrapper"
                                        >
                                            <img
                                                className="sortmodal-icon"
                                                src={`${process.env.PUBLIC_URL}` + option.icon} alt=""/>
                                        </div>
                                        <div>
                                            {option.displayTitle}
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    :
                    <div className="user-modal-body-container"
                         style={{
                             width: isMobile ? "100%" : "300px",
                             maxWidth: isMobile ? "100%" : "300px",
                             padding: '10px'
                         }}
                    >
                        {
                            loginOn ? <LoginPanel back={triggerPanel}/> : ''
                        }
                        {
                            resetPasswordOn ? <PasswordPanel back={triggerPanel}/> : ''
                        }
                        {
                            createAccountOn &&
                            <CreateAccountPanel back={triggerPanel}/>
                        }
                        {
                            verifyOn &&
                            <VerifyPanel back={triggerPanel}/>
                        }
                        {
                            confirmPassResetOn &&
                            <ConfirmPassReset back={triggerPanel}/>
                        }
                        {
                            settingsOn &&
                            <ProfileOptionsPanel back={triggerPanel}/>
                        }
                        {
                            changePasswordOn &&
                            <ChangePasswordPanel  back={triggerPanel}/>
                        }
                    </div>
                }
            </div>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            filter: state.navReducer.filter,
            clientid: state.loginReducer.authentication.clientid,
            clientchannel: state.loginReducer.authentication.clientchannel,
            loginToken: state.loginReducer.authentication.loginToken,
            allMedia: state.MediaReducer.allMedia,
            isSingleView: state.MediaReducer.isSingleView,
            email: state.loginReducer.authentication.email,
            userView: state.navReducer.userView,
            embed: state.loginReducer.embed
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {
            logout: () => {
                dispatch(logout());
            },
            privateUserView: view => {
                dispatch(privateUserView(view))
            }
        }
    };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserMenuComponent));
