export const toggleMobileSocialShare = (shareurl,sharemethodisprivate) => {
    if (sharemethodisprivate) return;

    const isWebView = navigator.userAgent.includes('wv')

    if (navigator.share) {//only available on https
        navigator.share({
            url: shareurl
        })
            .then(() => {
                console.log("Thanks for sharing")
            })
            .catch(error => console.error(error.message))
    }
    if (isWebView) {
        window.location.href = `intent:#Intent;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=${encodeURIComponent(shareurl)}`;
    }
};
