const initialState = {
    loginErrorMessage: '',
    userMenuToggled: false,
    passwordUpdateMessage: '',
    messageColor: '',
    verifyClientId: '',
    restAuth: '',
    userEmail: '',
    createMessage: '',
    currentStep: 1,
    verifyError:null,
    formErrors: {
        name: '',
        email: '',
        channelName: '',
        password: '',
    }
};

const desktopNavReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_ERROR": {
            return {
                ...state,
                loginErrorMessage: action.payload
            };
        }
        case "TOGGLE_USER_MENU": {
            return {
                ...state,
                userMenuToggled: !state.userMenuToggled,
                verifyError: null,
                restAuth: ''
            };
        }
        default:
            break;
        case  'PASSWORD_UPDATE_MESSAGE': {
            return {
                ...state,
                passwordUpdateMessage: action.payload.passwordUpdateMessage,
                messageColor: action.payload.messageColor
            }
        }
        case 'SET_CLIENT_ID': {
            return {
                ...state,
                verifyClientId: action.payload
            }
        }
        case 'SET_RESET_AUTH' : {
            return {
                ...state,
                restAuth: action.payload,
                verifyError:""
            }
        }
        case 'CLEAR_RESET_AUTH' : {
            return {
                ...state,
                restAuth: ""
            }
        }
        case 'SET_USER_EMAIL' : {
            return {
                ...state,
                userEmail: action.payload
            }
        }
        case 'CREATE_MESSAGE' : {
            let errorsObj = {};
            let errors = action.payload;

            for (let message of errors) {
                let lwrMessage = message.toLowerCase();

                if (lwrMessage.includes('name')) {
                    errorsObj['name'] = message
                }
                if (lwrMessage.includes('email')) {
                    errorsObj['email'] = message
                }
                if (lwrMessage.includes('password')) {
                    errorsObj['password'] = message
                }
                if (lwrMessage.includes('channel')) {
                    errorsObj['channel'] = message
                }
                if (lwrMessage.includes('confirm')) {
                    errorsObj['confirm'] = message
                }
            }

            return {
                ...state,
                formErrors: errorsObj
            }
        }
        case 'CLEAR_PASSWORD_MESSAGE' : {
            return {
                ...state,
                passwordUpdateMessage: ''
            }
        }
        case "CLEAR_USER_MESSAGE" : {
            return {
                ...state,
                passwordUpdateMessage:"",
                loginErrorMessage:""
            }
        }
        case 'SET_CURRENT_STEP' : {
            return {
                ...state,
                currentStep:action.payload
            }
        }
        case "VERIFICATION_ERROR":{
            return {
                ...state,
                verifyError: action.payload
            }
        }
    }
    return state;
};
export default desktopNavReducer;
