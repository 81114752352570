//Functions utilized by handleDrawerDisplay function to structure data
export const getPhoneData = currentMetaData => {
    //properties to search for on the currentMetaData object
    let summaryData = ['picturequality', 'model', "osversion", "screenheight", "screenwidth", "wifiname", "cpuusageidle", "battery", "memoryusage",
        "screenheight", "screenwidth", "carrier", "dataconnection", "brightness", "freespace", "barometer", "deviceorientation",
        "devicelanguage", "country", "devicecurrency", "gpsonoff", "jailbroken", "systemuptime", "devicetime", "timezone", "airplanemode", "availablewifinetwork", "systemuptimeseconds"
    ];
    let phoneData = {};
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            //if properties exist assign key and value to phoneData
            phoneData[element] = currentMetaData[element]
        }
    }
    //return completed phoneData object
    return phoneData;
};

export const getLocationData = currentMetaData => {
    let headingData = ['heading', 'gpsaltitude', "gpslatitude", "gpslongitude", "address", 'compass',
        "headingdirection", "speed", 'locationSpeedArray', 'gpsaltitudeArray', 'distancetravelled',
        'distancetravelledArray', 'gpshorizontalaccuracyArray', "gpsaccuracy", 'gpshorizontalaccuracy',
        'gpsaccuracy', 'gpslatitudedeg', 'gpslatitudemin', 'gpslatitudesec', 'gpslatitudedir', 'gpslongitudedeg',
        'gpslongitudemin', 'gpslongitudesec', 'gpslongitudedir'
    ];
    let locationData = {};
    for (let i = 0; i < headingData.length; i++) {
        const element = headingData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            locationData[element] = currentMetaData[element]
        }
    }
    return locationData
};


export const getPhoneDirectionData = currentMetaData => {
    let summaryData = ['acceleration.x', 'acceleration.y', 'acceleration.z', 'angle'];
    let phoneDirectionArray = []
    if (currentMetaData) {
        for (let i = 0; i < summaryData.length; i++) {
            const element = summaryData[i];
            if (currentMetaData.hasOwnProperty(element)) {
                //remove non ANSI characters and white space frm string
                phoneDirectionArray.push(currentMetaData[element].replace(/[^\x00-\x7F]\s/g, ""))
            }
        }
        return phoneDirectionArray
    }
};

export const getTimeData = currentMetaData => {
    let summaryData = ["phoneclocktime", "worldclocktime", "sequencestarttime",
        "mediaframedevicedatetime"];
    let timeData = {}
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            timeData[element] = currentMetaData[element]
        }
    }
    return timeData
}

export const getEncryptionData = (currentMetaData) => {
    let summaryData = ["devicetime"];
    let encryptionData = {}
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            encryptionData[element] = currentMetaData[element]
        }
    }
    //combine current encryption data and has values into one object
    return {...encryptionData, ...currentMetaData.encryptionData}
}

export const getConnectionData = currentMetaData => {
    let summaryData = ['connectionSpeedArray', "connectionspeed",
        "latency", "dataTimeDelayArray", "satellitedate", "satellites","worldclockdate","worldclocktime", "gpshorizontalaccuracyArray", "gpshorizontalaccuracy", "gpsaccuracy", "osname", "celltowers", "sunMoonData", "traceroute"];
    let connectionData = {};
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            //android satellite array is JSON object
            if ((element === "satellites" || element === "celltowers") && typeof (currentMetaData[element]) === 'string') {
                try{
                    connectionData[element] = JSON.parse(currentMetaData[element]);
                }catch (e) {
                    // console.error(`The value for ${element} in getConnectionData is not valid JSON`)
                }
            } else {
                connectionData[element] = currentMetaData[element]
            }
        }
    }
    return connectionData
}

export const getSummaryData = currentMetaData => {
    let summaryData = ['summaryFrameData', 'frameGradeData'];
    let componentData = {}
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            componentData[element] = currentMetaData[element]
        }
    }
    return componentData
}

export const getSoundData = currentMetaData => {
    let summaryData = ['decibel', 'decibelArray'];
    let componentData = {};
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (currentMetaData.hasOwnProperty(element)) {
            componentData[element] = currentMetaData[element]
        }
    }
    return componentData
};
