const initialState = {
    displayData: {
        soundData: {
            decibelArray: []
        },
        phoneData: {
            phoneDirectionData: []
        },
        locationData: {
            distancetravelledArray: [],
            gpsaltitudeArray: [],
            gpshorizontalaccuracyArray: [],
            locationSpeedArray: []
        },
        timeData: {},
        encryptionData: {},
        summaryData: {},
        connectionData: {
            satellites: [],
            connectionSpeedArray: [],
            dataTimeDelayArray: [],
            sunMoonData: {},
            gpshorizontalaccuracyArray: []
        }
    },
};

const drawerDisplayReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DISPLAY':
            return{
                ...state,
                displayData:action.payload
            }
        default:
            break;
    }
    return state;
};
export default drawerDisplayReducer;
