import React from 'react'
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import {setEmbedValue} from './actions'

//HELPER FUNCTIONS
//--------------------------------------------------
const checkChannel=(auth)=>{
    //if auth and no channel set to 'my'
    const channel =localStorage.getItem("clientchannel")
    if(auth && channel !== "my"){
        // localStorage.setItem("clientchannel","my");
        localStorage.setItem("clientid","my");
    }
};

const  findGetParameter = (search,setEmbedValue)=> {
    //if no search then return
    if(!search)return
    //create object with query params and values
    let queryDict = {}
    search.substr(1).split("&").forEach(item => {queryDict[item.split("=")[0]] = item.split("=")[1]})

    localStorage.setItem("authtoken",queryDict.authcode)
    localStorage.setItem("clientchannel",queryDict.channelname);
    localStorage.setItem("homechannel",queryDict.channelname);
    setEmbedValue(queryDict.embedded)
};
//--------------------------------------------------

//ROUTE
const ProtectedRoute = ({ setEmbedValue,location:{search}, ...props }) => {
    //Check for search parameters in url
    findGetParameter(search,setEmbedValue)
    const  auth = localStorage.getItem("authtoken")
    checkChannel(auth);
    //if auth return requested route else /login
    // return auth ? <Route {...props}/>
    //  : <Redirect to="/login"/>
    if(auth){
        return <Route {...props}/>
    }else{
        localStorage.clear();
        return <Redirect to="/login"/>
    }
}

export default connect(
    (state) =>({
    }),
    dispatch =>({
        setEmbedValue:(value)=>{
            dispatch(setEmbedValue(value))
        }
    })
    )(ProtectedRoute)
