import React from 'react'
import {connect} from 'react-redux'
import './mobilenavbar.css'
import {withRouter, Link} from 'react-router-dom'
import {searchMedia, mediaFilter, sortMedia, toggleEdit, unsetMetData} from '../actions'
import {toggleUserMenu} from "../DesktopNavPopUp/actions";
import WarningModal from '../ModalComponent/WarningModal'
import SocialShareComponent from '../SocialShareComponent/SocialSchareComponent'
import socialNetworksArray from '../SocialShareComponent/SocialNetworks'
import VerifiedModal from "../VerifiedModal";
import MobileYoutubeSearch from "../MobileYoutubeSearch/MobileYoutubeSearch";


let moment = require('moment');

class MobileNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleModal: false,
            menuOpen: false,
            loginToggle: false,
            isFilterToggled: false,
            socialShareOn: false,
            currentToggle: 'dateToggle',
            direction: false,
            searchOpen: false
            // userAgent:null //check for Android webview
        };
    }

    componentDidUpdate(prevProps) {
        const {clientchannel, history} = this.props

        if (this.props.loginToken !== prevProps.loginToken && clientchannel) {
            history.push(`/${clientchannel}`)
        }

        window.addEventListener("resize", this.setOrientation);
    }

    setOrientation = () => {
        this.setState({
            orientation: 'moved'
        })
    };

    handleQuery = (e) => {
        e.preventDefault();
        const {searchMedia} = this.props
        searchMedia(e.target.value)
    };

    handleChannelDisplay = () => {
        //Returns a boolean to display the clientchannel
        //portion of the header url
        const {clientchannel} = this.props

        if (clientchannel === 'logout'
            || clientchannel === 'my'
            || !clientchannel) return false;

        return true;
    };

    toggleMenu = () => {
        const {handleToggleMenu} = this.props;
        this.setState({
            isFilterToggled: false
        });
        handleToggleMenu();
    };

    dateSort = (key, toggleType) => {
        //NULL VALUES AND MISSING PROPERTY ARE SORTED TO BOTTOM
        let direction = this.state['direction'];
        const {allMedia, sortMedia} = this.props;
        let sortedArray = allMedia.sort((a, b) => {
            if (key) {
                if (a[key] === null) {
                    return 1;
                }
                // nulls sort after anything else
                else if (b[key] === null) {
                    return -1;
                }
                // otherwise, if we're ascending, lowest sorts first
                else if (!direction) {
                    // return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1;
                    return new moment(a[key]) - new moment(b[key]);
                }
                // if descending, highest sorts first
                else {
                    // return a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1;
                    return new moment(b[key]) - new moment(a[key]);
                }
            }
            return ''
        });
        this.setState({
            currentToggle: toggleType,
            direction: !this.state.direction
        });
        sortMedia(sortedArray);
    };

    titleSort = (key, toggleType) => {
        //NULL VALUES AND MISSING PROPERTY ARE SORTED TO BOTTOM
        let direction = this.state['direction'];
        const {allMedia, sortMedia} = this.props;
        let sortedArray = allMedia.sort((a, b) => {
            if (key) {
                if (a[key] === null) {
                    return 1;
                }
                // nulls sort after anything else
                else if (b[key] === null) {
                    return -1;
                } else if (a[key].toUpperCase() === b[key].toUpperCase()) {
                    return 0;
                }
                // otherwise, if we're ascending, lowest sorts first
                else if (direction) {
                    return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1;
                }
                // if descending, highest sorts first
                else {
                    return a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1;
                }
            }
            return ''
        });
        this.setState({
            currentToggle: toggleType,
            direction: !this.state.direction
        });
        sortMedia(sortedArray);
    };

    sortViews = (key, toggleType) => {
        //NULL VALUES AND MISSING PROPERTY ARE SORTED TO BOTTOM
        let direction = this.state['direction'];
        const {allMedia, sortMedia} = this.props;
        let sortedArray = allMedia.sort((a, b) => {
            if (a[key] === b[key]) {
                return 0;
            }
            // nulls sort after anything else
            else if (a[key] === null || !a.hasOwnProperty(key)) {
                return 1;
            } else if (b[key] === null || !b.hasOwnProperty(key)) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (!direction) {
                return a[key] - b[key]
            }
            // if descending, highest sorts first
            else {
                return b[key] - a[key]
            }
        });

        this.setState({
            currentToggle: toggleType,
            direction: !this.state.direction
        });
        sortMedia(sortedArray);
    };

    shortenLength = (desc) => {
        if (desc) {
            if (desc.length > 12 && window.innerWidth < 416) {
                let shortenedDesc = desc.substring(0, 12);
                return shortenedDesc + '...';
            } else {
                return desc
            }
        }
    };

    toggleMobileSocialShare = () => {
        const {webshare, shareurl, sharemethod} = this.props;
        if (sharemethod === 'private') return;
        const isWebView = navigator.userAgent.includes('wv')
        //Intent Testing for embed android---------------------------------------
        if (navigator.share) {//only available on https
            console.log(webshare)
            navigator.share({
                url: shareurl
            })
                .then(() => {
                    console.log("Thanks for sharing")
                })
                .catch(error => console.error(error.message))
        } else if (isWebView) {
            window.location.href = `intent:#Intent;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=${encodeURIComponent(shareurl)}`;
        } else {
            this.setState({
                socialShareOn: !this.state.socialShareOn
            })
        }
        //Intent Testing for embed android---------------------------------------

        //Previous code-------------------------------------------
        // if(navigator.share) {//only available on https
        //     console.log(webshare)
        //     navigator.share({
        //         url: shareurl
        //     })
        //         .then(() => {
        //             console.log("Thanks for sharing")
        //         })
        //         .catch(error => console.error(error.message))
        // }else{
        //     this.setState({
        //         socialShareOn: !this.state.socialShareOn
        //     })
        // }
    };

    toggleVerificationModal = () => {
        console.log('working');
        this.setState({
            verificationMenuToggled: !this.state.verificationMenuToggled
        })
    };

    handleEditNavigation = () => {
        const {toggleEdit, unsetMetData} = this.props;
        toggleEdit();
        unsetMetData();
    };

    handleModalToggle = () => {
        const {sharemethod} = this.props;
        return sharemethod === 'private' ? "modal" : "";
    };

    toggleSearch = () => {
        // toggle search
        this.setState({
                searchOpen: !this.state.searchOpen
            }
        )
    };

    render() {
        // Checks activity of menu then sets is-active css class for animation
        const {socialShareOn, verificationMenuToggled, toggleModal, searchOpen} = this.state;
        const isActive = this.props.isOpen ? 'is-active' : '';
        const mobileNavBodyClasses = `mobile-navbar-menu-wrapper ${isActive}`;
        const backGround = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/icon-search.png)`
        };

        const {toggleUserMenu, isSingleView, clientchannel, mediaIsSet, embed, clientchannelverified, webshare} = this.props;
        const {toggleMobileSocialShare, toggleVerificationModal, handleModalToggle, handleEditNavigation, toggleSearch} = this;
        const {shareurl} = this.props;
        return (
            <div>
                {!searchOpen ?
                <div className="mobile-navbar-wrapper">
                    <div className="mobile-navbar-main-logowrapper">
                        <img className="mobile-logo" src={`${process.env.PUBLIC_URL}/Logo-VideoLock.png`} alt=""/>
                        <div className="mobile-navbar-underline-wrapper">
                            <a href='https://videolock.com'>
                                <div className="mobile-nav-bar-logo-text">VideoLock.com</div>
                            </a>
                            <div className="underline-bar">
                            </div>
                        </div>
                        {
                            this.handleChannelDisplay() &&
                            <React.Fragment>
                                <div className="mobile-slash-placeholder">/</div>
                                <div className="mobile-nav-bar-underline-wrapper">
                                    <Link to={`/${clientchannel ? clientchannel : "login"}`}>
                                        <div className="mobile-user-name">
                                            {this.shortenLength(clientchannel)}
                                        </div>
                                    </Link>
                                    <div className="underline-bar">
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="mobile-slash-placeholder">/</div>
                        {//KEY DOES NOT FIT IN HEADER CURRENTLY
                            // mediakey &&
                            // <React.Fragment>
                            //     <div className="mobile-nav-bar-underline-wrapper">
                            //         <div className="mobile-user-name">{mediatype}</div>
                            //         <div className="underline-bar">
                            //         </div>
                            //     </div>
                            //     <div className="mobile-slash-placeholder">/</div>
                            //
                            //     <div className="mobile-nav-bar-underline-wrapper">
                            //         <div className="mobile-user-name">{mediakey}</div>
                            //         <div className="underline-bar">
                            //         </div>
                            //     </div>
                            // </React.Fragment>
                        }
                        {mediaIsSet &&
                        <div className="mobile-social-media-top-wrapper" data-toggle={handleModalToggle()}
                             data-target="#warningModal">
                            <div onClick={() => toggleMobileSocialShare()} className="mobile-socialmedia-share">
                                <img src={`${process.env.PUBLIC_URL}/Icon-Share.png`} alt=""/>
                            </div>
                            {socialShareOn &&
                            <div className="mobile-social-stable">
                                <div className="mobile-social-grid social-modal-wrapper">
                                    {(!embed && !webshare) &&
                                    <div className="mobile-social-media-icon-wrapper">
                                        <a href={`mailto:?body=${shareurl}`} target="_blank" rel="noopener noreferrer">
                                            <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>
                                        </a>
                                    </div>
                                    }
                                    {socialNetworksArray.filter(network => {
                                        //Until native share can be invoked from
                                        //embed android we want to hide WhatsApp
                                        //Doesn't effect iOS as this menu isn't shown
                                        if (!embed) {
                                            return network
                                        } else if (embed && !network.icon.includes("WhatsApp")) {
                                            return network
                                        }
                                    }).map(({socialNetwork, icon}, index) =>
                                        <SocialShareComponent
                                            socialNetwork={socialNetwork}
                                            shareurl={shareurl}
                                            icon={icon}
                                            key={index}
                                            cssStyle={"mobile-social-media-icon-wrapper"}
                                            embed={embed}
                                        />
                                    )}
                                    {/*<div className="mobile-social-media-icon-wrapper">
                                        <a href={`https://www.facebook.com/sharer.php?u=${shareurl}`} rel="noopener noreferrer" target="_blank">
                                            <img src={`${process.env.PUBLIC_URL}/Icon-Facebook.png`} alt=""/>
                                        </a>
                                    </div>
                                    <div className="mobile-social-media-icon-wrapper">
                                        <img src={`${process.env.PUBLIC_URL}/Icon-Instragram.png`} alt=""/>
                                    </div>
                                    <div className="mobile-social-media-icon-wrapper">
                                        <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?url=${shareurl}`}>
                                            <img src={`${process.env.PUBLIC_URL}/Icon-Twitter.png`} alt=""/>
                                        </a>
                                    </div>
                                    <div className="mobile-social-media-icon-wrapper">
                                        <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?url=${shareurl}`}>
                                            <img src={`${process.env.PUBLIC_URL}/Icon-WhatsApp.png`} alt=""/>
                                        </a>
                                    </div>*/}

                                </div>
                            </div>
                            }
                            {/*<div className="mobile-social-media-icon-wrapper">*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/Icon-Facebook.png`} alt=""/>*/}
                            {/*</div>*/}
                            {/*<div className="social-media-icon-wrapper">*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/Icon-Instragram.png`} alt=""/>*/}
                            {/*</div>*/}
                            {/*<div className="social-media-icon-wrapper">*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/Icon-Twitter.png`} alt=""/>*/}
                            {/*</div>*/}
                            {/*<div className="social-media-icon-wrapper">*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>*/}
                            {/*</div>*/}
                        </div>
                        }
                        {(clientchannelverified && !mediaIsSet) &&
                        <div className="verified-mobile-wrapper"
                             onClick={() => toggleVerificationModal()}
                        >
                            {
                                verificationMenuToggled
                                    ?
                                    <VerifiedModal
                                        version={"mobile"}
                                    /> : null
                            }
                            <img src={`${process.env.PUBLIC_URL}/VerifiedIcon.png`} alt=""/>
                        </div>}
                    </div>

                    {/*<button*/}
                    {/*    className="login-icon-button"*/}
                    {/*    onClick={toggleUserMenu}*/}
                    {/*    type="button">*/}
                    {/*    <img src={`${process.env.PUBLIC_URL}/white_login_icon.png`} alt=""/>*/}
                    {/*</button>*/}
                    <div className='button-mobile-wrapper'>
                        <button
                            className="login-icon-button"
                            onClick={() => toggleSearch()}
                            type="button">
                            <img
                                className="search-update-icon"
                                src={`${process.env.PUBLIC_URL}/ui-update-search.png`}/>
                        </button>
                        <button
                            className="login-icon-button"
                            onClick={toggleUserMenu}
                            type="button">
                            <img src={`${process.env.PUBLIC_URL}/blue_login_icon.png`} alt=""/>
                        </button>
                    </div>
                </div>
                :
                <div>
                    <MobileYoutubeSearch
                        toggleBack={toggleSearch}
                    />
                </div>
                }

                <div>
                </div>
                <div>
                    <div className={mobileNavBodyClasses}>
                    </div>
                </div>
                <div className={mobileNavBodyClasses}>
                    <div className="mobile-navbar-login-wrapper">
                        {/*<form onSubmit={this.handleMobileLogin}>*/}
                        {/*    {!loginToken &&*/}
                        {/*    <div className="mobile-navbar-input-wrapper">*/}
                        {/*        <input className="" placeholder="USERNAME" type="text"/>*/}
                        {/*        <input className="" placeholder="PASSWORD" type="password"/>*/}
                        {/*    </div>*/}
                        {/*    }*/}
                        {/*    {loginToken ?*/}
                        {/*        <button*/}
                        {/*            className="mobile-navbar-login-button"*/}
                        {/*            onClick={() => logout()}>*/}
                        {/*            LOGOUT*/}
                        {/*        </button>*/}
                        {/*        :*/}
                        {/*        <button*/}
                        {/*            className="mobile-navbar-login-button"*/}
                        {/*            type="submit">*/}
                        {/*            LOGIN*/}
                        {/*        </button>*/}
                        {/*    }*/}
                        {/*</form>*/}
                        {/*<Link onClick={this.handleChannel} to="/my">*/}
                        {/*    <div className="mymedia-link">*/}
                        {/*        My Media*/}
                        {/*    </div>*/}
                        {/*</Link>*/}
                    </div>
                </div>
                <WarningModal
                    toggle={toggleModal}
                    clickedFunction={handleEditNavigation}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginToken: state.loginReducer.authentication.loginToken,
        isOpen: state.mobileMenuReducer.isOpen,
        clientid: state.loginReducer.authentication.clientid,
        allMedia: state.MediaReducer.allMedia,
        clientchannel: state.loginReducer.authentication.clientchannel,
        mediaIsSet: state.mediaSingleReducer.mediaIsSet,
        isSingleView: state.MediaReducer.isSingleView,
        shareurl: state.mediaSingleReducer.shareurl,
        mediakey: state.mediaSingleReducer.mediaKey,
        mediatype: state.mediaSingleReducer.mediaType,
        embed: state.loginReducer.embed,
        clientchannelverified: state.MediaReducer.clientchannelverified,
        webshare: state.mediaSingleReducer.webshare,
        sharemethod: state.mediaSingleReducer.sharemethod

    }
};

const mapDispatchToProps = dispatch => {
    return {
        mediaFilter: (filter) => {
            dispatch(mediaFilter(filter))
        },
        searchMedia: (str) => {
            dispatch(searchMedia(str))
        },
        sortMedia: (sortedArray) => {
            dispatch(sortMedia(sortedArray))
        },
        toggleUserMenu: () => {
            dispatch(toggleUserMenu())
        },
        toggleEdit: () => {
            dispatch(toggleEdit())
        },
        unsetMetData: () => {
            dispatch(unsetMetData())
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileNavbar));
