import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import "./ChannelBanner.css"
import ProfileCircle from "./ProfileCircle";
import ProfileMediaUploader from "../ProfileMediaUploader/ProfileMediaUploader";
import VerifiedModal from "../VerifiedModal";
import {toggleUploadModal} from "../actions";


class ChannelBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationMenuToggled: false
        }
    }

    setBackground = () => {
        let publicUrl = process.env.PUBLIC_URL;

        //TODO REMOVE PLACEHOLDER

        return publicUrl + '/placeholder-banner.png'
    }

    toggleVerificationModal = () => {
        this.setState({
            verificationMenuToggled: !this.state.verificationMenuToggled
        })
    };

    render() {
        const {
            clientchannel,
            loginToken,
            mediaIsSet,
            clientchannelverified,
            toggleUploadModal,
            uploadToggle,
            bannerType
        } = this.props;
        const {verificationMenuToggled} = this.state;
        const {toggleVerificationModal} = this;
        const homechannel = localStorage.getItem("homechannel")

        return (
            <div className="channelbanner-container">
                {window.innerWidth < 900 ?
                    <div
                        className="mobile-channelbanner-background-image"
                        style={{
                            backgroundImage: `url(${this.setBackground()})`
                        }}
                    >
                    </div>
                    :
                    <div
                        className="channelbanner-background-image"
                        style={{
                            backgroundImage: `url(${this.setBackground()})`
                        }}
                    >
                    </div>
                }
                <div className="channelbanner-placement-container"
                     style={{
                         left: window.innerWidth > 900 ? '2%' : '16px',
                         bottom: window.innerWidth > 900 ? '-60px' : '-42px'
                     }}
                >
                    <ProfileCircle
                        uploader={loginToken && clientchannel === homechannel}
                        dimensions={{
                            //add existing logic here
                            width: window.innerWidth > 900 ? '80px' : '50px',
                            height: window.innerWidth > 900 ? '80px' : '50px',
                            marginRight: window.innerWidth > 900 ? '24px' : '12px'
                        }
                        }
                    />
                    <div className="channelbanner-display-text"
                         style={{
                             fontSize: window.innerWidth > 900 ? '24px' : '18px'
                         }}
                    >
                        {clientchannel}
                    </div>

                    {(clientchannelverified && !mediaIsSet) &&
                    <div className="verified-media-icon-wrapper"
                         style={{
                             marginBottom: '15px',
                             paddingLeft: '15px'
                         }}
                    >
                        {verificationMenuToggled
                            ?
                            <VerifiedModal/> : null
                        }
                        <div className="verified-icon-wrapper">
                            <img
                                src={`${process.env.PUBLIC_URL}/VerifiedIcon.png`}
                                alt=""
                                onMouseOver={() => toggleVerificationModal()}
                                onMouseLeave={() => toggleVerificationModal()}
                            />
                        </div>
                    </div>
                    }
                </div>
                {loginToken && clientchannel === homechannel &&
                <div className="channelbanner-placement-container-fileuploader"
                     style={{
                         right: window.innerWidth > 900 ? '3.25%' : '16px',
                         bottom: '10px'
                     }}
                >
                    <ProfileMediaUploader
                        uploadType={bannerType}
                        uploadToggle={uploadToggle}
                        toggleModal={toggleUploadModal}
                    />
                </div>
                }
            </div>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            clientchannel: state.loginReducer.authentication.clientchannel,
            loginToken: state.loginReducer.authentication.loginToken,
            uploadToggle: state.navReducer.uploadToggle,
            bannerType: state.navReducer.bannerType,
            clientchannelverified: state.MediaReducer.clientchannelverified,
            mediaIsSet: state.mediaSingleReducer.mediaIsSet,
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {
            toggleUploadModal: (payload) => {
                dispatch(toggleUploadModal(payload))
            }
        }
    };


export default withRouter(connect

(mapStateToProps, mapDispatchToProps)(
    ChannelBanner
));
