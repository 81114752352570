import xapi from "../xapi";
import db from '../videolockframedb.js'
/*
RESPONSE HEADERS CONTENT-TYPE IS ALWAYS TEXT/HTML
TO CATCH SERVER ERRORS WE PARSE TEXT FIRST AND
VALIDATE CONTENTS BEFORE PARSING AS JSON
*/

export function getSingleMedia({type, key, keytype, mediatoken}) {
    return function dispatch(dispatch) {
        dispatch({type: "FETCHING_SINGLE_MEDIA"});
        dispatch({type: "SET_MEDIA_KEY", payload: {key, type: type[0]}});
        const clientid = localStorage.getItem("clientid");
        let x = new xapi("list");
        x.add("type", type);
        x.add("sharemethod", "all");
        x.add("clientid", clientid);
        x.add("authtoken", localStorage.getItem("authtoken"));
        x.add(keytype, key);

        let newmediapayload = {key: "keyvalue", type: "typevalue"};

        if (key) {
            newmediapayload.key = key;
            newmediapayload.type = type;
        }
        let p = x.fetch();
        p.then(resp => resp.text())
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    if (dataResult.result) {
                        if (dataResult.result.error) {
                            throw new Error(dataResult.result.error)
                        }
                        if (dataResult.result.private) {
                            dispatch({type: 'SET_PRIVATE_FLAG'});
                        }
                        if (
                            dataResult.result.success === true ||
                            dataResult.result.success === 1
                        ) {
                            if (dataResult.result[`${type}s`]) {
                                const media = dataResult.result[`${type}s`][0];
                                dispatch({
                                    type: "SET_MEDIA_OBJECT",
                                    payload: {
                                        media: media,
                                        duration: media[`${type}duration`],
                                        apprequired: media.apprequired,
                                        shareurl: media[`${type}socialshareurl`],
                                        sharemethod: media[`${type}sharemethod`]
                                    }
                                });

                                const channel = localStorage.getItem('clientchannel');
                                if (!channel || channel !== media.clientchannel) {
                                    const {clientid, clientchannel} = media;
                                    localStorage.setItem("clientchannel", clientchannel ? clientchannel : clientid !== "0" ? clientid : "");
                                    localStorage.setItem("clientid", clientid ? clientid : 0);

                                    dispatch({
                                        type: "UPDATE_CHANNEL",
                                        payload: {
                                            clientid,
                                            clientchannel: clientchannel ? clientchannel : clientid !== "0" ? clientid : ""
                                        }
                                    })
                                }
                                if (dataResult.result[`${type}s`][0][`${type}token`] && !mediatoken) {
                                    newmediapayload.mediatoken = dataResult.result[`${type}s`][0][`${type}token`];

                                    dispatch(getStoreMetadata(newmediapayload));
                                }
                            }
                            if (dataResult.result.message) {
                                dispatch({type: "FETCH_MEDIA_ERROR", payload: dataResult.result.message})
                            }
                        }
                    }
                }else{
                    throw new Error(result)
                }
            })
            .catch(err => {
                    console.error("The getSingleMedia action has received the following error: " + err.message);
                    dispatch({type: "FETCH_MEDIA_ERROR", payload: "An error occurred, please contact your Systems Administrator"})
                }
            );
    };
}

export function getStoreMetadata(payload) {

    // getStoreVideoMetadata gets all the frames and metadata for a particular mediatoken
    // and stores it in IndexDB
    // payload has two variables
    // payload.key for videokey
    // payload.mediatoken for videotoken
    // payload.type for media type

    return function dispatch(dispatch) {
        dispatch({type: `FETCHING_${payload.type.toUpperCase()}_FRAMES`})
        if (payload.key) {
            //check if already set in IndexDB
            db.table("frames").get(payload.key).then((result) => {
                if (result) {
                    /////////////////////////////////////////////////////////
                    //metadata already EXISTS in IndexDB, no need to retrieve it from API
                    const {framedata} = result
                    // parse out the location data  lat/lon points that are needed for location mapping
                    const locationData = parseOutLocationData(framedata);
                    //DISPATCH MEDIA FRAMES TO REDUCER
                    //framedata.frames contains all the objects of the all the frames
                    //framedata.currentframes is number of frames that have been retrieved during this call
                    //framedata.remainingframes is number of frames that still can be retrieved from API
                    //framedata.totalframes is total number of frames over all that need to be retrieved from the api
                    dispatch({
                        type: "SET_MEDIA_FRAMES",
                        payload: {
                            frames: framedata.frames,
                            currentframes: framedata.currentframes,
                            remainingframes: framedata.remainingframes,
                            totalframes: framedata.totalframes,
                            locationData: locationData
                        }
                    })
                } else {
                    ///////////////////////////////////////////////////
                    // metadata DOES NOT exist in indexDB, so retrieve it from API
                    let x = new xapi(`${payload.type}frame_get`);
                    x.add(`${payload.type}token`, payload.mediatoken);
                    x.add("framestart", 1);
                    x.add("maxframes", 99999); // get all frames, metadata

                    let p = x.fetch();
                    p.then(resp => resp.text())
                        .then(function (result) {
                            if (result.includes("result") || result.includes("errors")) {
                                const dataResult = JSON.parse(result);
                                const {success,error} = dataResult.result;
                                if (dataResult.result && success) {
                                    // parse out the location data  lat/lon points that are needed for location mapping
                                    const locationData = parseOutLocationData(dataResult.result);
                                    const optimizedFrames = optimizeFrames(dataResult.result.frames);
                                    const updatedResult = {...dataResult.result, frames: optimizedFrames};

                                    //DISPATCH FRAME DATA TO REDUCER
                                    dispatch({
                                        type: "SET_MEDIA_FRAMES",
                                        payload: {
                                            frames: optimizedFrames,
                                            currentframes: dataResult.result.currentframes,
                                            remainingframes: dataResult.result.remainingframes,
                                            totalframes: dataResult.result.totalframes,
                                            locationData: locationData
                                        }
                                    });

                                    /*
                                    create current date and data object. mediakey is primary key and
                                    used to reference framedata
                                    */

                                    const createddate = new Date().toLocaleDateString();
                                    const frameData = {
                                        mediakey: payload.key,
                                        framedata: updatedResult,
                                        framestoreddate: createddate.toString()
                                    };
                                    // Set the entire result, with frames and metadata to IndexDB in frames object store
                                    //Note: IndexDB is async, while local storage is synchronous
                                    db.table('frames').add(frameData).then((id) =>
                                        console.log(`Successfully stored frame data in frames at mediakey: ${id}`))
                                        .catch(error => console.log(error))
                                }
                                if(error){
                                    console.error("getStoredMetadata dataResult error message: " + error);
                                    dispatch({
                                        type: "GET_FRAME_DATA_ERROR",
                                        payload: "An error occurred retrieving media meta-data, please contact your Systems Administrator"
                                    })
                                }
                            }else{
                                throw new Error(result)
                            }
                        })
                        .catch(err => {
                            console.error("getStoredMetadata received the following error message: " + err.message);
                            dispatch({
                                type: "GET_FRAME_DATA_ERROR",
                                payload: "An error occurred retrieving meta-data, please contact your Systems Administrator"
                            })
                        });
                }
            }).catch((error) => {
                if (error.message.includes('framedata')) {
                    return console.error(`Index DB 'frames' does not contain an index for key: ${payload.key}`)
                }
                console.error("dbTable has thrown an error in getStoreMetadata action : " + error)
            })
        }
    };
}
//OLD FUNCTION DELETE ONCE INDEXDB VERIFIED AS VALID REPLACEMENT FOR localStorage
//--------------------------------------------------------------------------
// export function getStoreMetadata(payload) {
//
//     // getStoreVideoMetadata gets all the audioframes and metadata for a particular audiotoken
//     // and stores it in localStorage
//     // payload has two variables
//     // payload.key for videokey
//     // payload.mediatoken for videotoken
//     // payload.type for media type
//
//     return function dispatch(dispatch) {
//         dispatch({type:`FETCHING_${payload.type.toUpperCase()}_FRAMES`})
//         if (payload.key) {
//             //check if already set in localStorage
//             let existingMetadata = localStorage.getItem(payload.key);
//
//             if (existingMetadata) {
//                 /////////////////////////////////////////////////////////
//                 //metadata already EXISTS in localstorage, no need to retrieve it
//
//                 const mediaFrames = JSON.parse(existingMetadata);
//
//                 // parse out the location data lat/lon points that are needed for location mapping
//                 const locationData = parseOutLocationData(mediaFrames);
//
//                 //DISPATCH MEDIA FRAMES TO REDUCER
//                 //mediaFrames.frames contains all the objects of the all the frames
//                 //mediaFrames.currentframes is number of frames that have been retrieved during this call
//                 //mediaFrames.remainingframes is number of frames that still can be retrieved from API
//                  //mediaFrames.totalframes is total number of frames over all that need to be retrieved from the api
//                 dispatch({
//                     type: "SET_MEDIA_FRAMES",
//                     payload: {
//                         frames: mediaFrames.frames,
//                         currentframes: mediaFrames.currentframes,
//                         remainingframes: mediaFrames.remainingframes,
//                         totalframes: mediaFrames.totalframes,
//                         locationData: locationData
//                     }
//                 });
//             } else {
//                 ///////////////////////////////////////////////////
//                 // metadata DOES NOT exist in localstorage, so retrieve it
//
//                 let x = new xapi(`${payload.type}frame_get`);
//                 x.add(`${payload.type}token`, payload.mediatoken);
//                 x.add("framestart", 1);
//                 x.add("maxframes", 99999); // get all frames, metadata
//
//                 let p = x.fetch();
//                 p.then(resp => resp.json())
//                     .then(function (dataResult) {
//                         if (dataResult.result) {
//                             if (dataResult.result) {
//
//                                 dataResult.result.audiotoken = payload.mediatoken;
//                                 // parse out the location data  lat/lon points that are needed for location mapping
//                                 const locationData = parseOutLocationData(dataResult.result);
//
//                                 //DISPATCH RAW FRAME DATA TO REDUCER
//                                 dispatch({
//                                     type: "SET_MEDIA_FRAMES",
//                                     payload: {
//                                         frames: dataResult.result.frames,
//                                         currentframes: dataResult.result.currentframes,
//                                         remainingframes: dataResult.result.remainingframes,
//                                         totalframes: dataResult.result.totalframes,
//                                         locationData: locationData
//                                     }
//                                 });
//                                 // set the entire result, with frames and metadata to localStorage
//
//                                 localStorage.setItem(
//                                     payload.key,
//                                     JSON.stringify(dataResult.result)
//                                 );
//                             } else {
//                                 console.log("NO RESULT ");
//                             }
//                         }
//                     })
//                     .catch(err =>
//                         console.log(
//                             "getStoreMetadata dataResult error message: " + err.message
//                         )
//                     );
//             }
//         }
//     };
// };
//--------------------------------------------------------------------------

export function viewMedia(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("view");
        x.add("token", payload.token);
        x.add("mediatype", payload.type);
        x.add("type", 'media');
        let p = x.fetch();
        p.then(resp => resp.text())
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    const {success, error} = dataResult.result;
                    if (dataResult.result && success) {
                        console.log("Successfully logged media view")
                    }
                    if (error) {
                        console.error(error)
                    }
                } else {
                    throw new Error(result)
                }
            })
            .catch(err => {
                console.error("The viewMedia action has received the following error: " + err.message)
            });
    };
}

function parseOutLocationData(existingMedataData) {
    // console.log("parseOutLocationData action in MediaSingle/actions.js");

    //existingMedataData MUST BE all of the metadata like it's returned from the API call

    let toParseFramesData = existingMedataData.frames;
    let locationDataPoints = {};

    if (toParseFramesData) {
        let parseValues = Object.values(toParseFramesData);
        // console.log("parseValues:");
        // console.log(parseValues);

        Object.keys(parseValues).forEach((key, index) => {
            // console.log("value meta:");
            // console.log(key, parseValues[key].meta.gpslatitude);
            // console.log(key, parseValues[key].meta.gpslongitude);
            locationDataPoints[key] = {
                lat: parseValues[key].meta.gpslatitude,
                lng: parseValues[key].meta.gpslongitude
            };
        });
        // console.log("locationDataPoints:", locationDataPoints);
        return locationDataPoints;
    } else {
        // console.log(
        //     "MediaSingle/actions.js parseOutLocationData framesData is EMPTY, no location data to process."
        // );
        return;
    }
};

export function getTraceData(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("map");
        x.add("ipaddresses", payload);
        x.add("type", 'iplocation');
        let p = x.fetch();
        p.then(resp => resp.text())
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    const {ipaddresses, error} = dataResult.result;
                    if (dataResult.result && ipaddresses) {
                        dispatch({
                            type: "SET_TRACE_ROUTES",
                            payload: ipaddresses
                        });
                    }//Do we need an else clause to log no ip data to console?
                    if(error){
                        //if the error is on the response log to console
                        console.error("The getTraceData action has received the following error: " + error)
                    }
                }else{
                    //error is from server, throw error and log it to the console in catch
                    throw new Error(result)
                }
            })
            .catch(err => {
                console.error("The getTraceData action has received the following error: " + err.message)
            });
    };
}

export const toggleDisplayTools = () => {
    return {
        type: "TOGGLE_DISPLAY_TOOLS"
    }
};

export const metaDataIsSet = () => {
    return {
        type: "SET_METDATA"
    }
};

export const setSliderValue = (value) => {
    return {
        type: "SET_SLIDER_VALUE",
        payload: value
    }
};

export const handleDrawerToggle = () => {
    return {
        type: "TOGGLE_DRAWER"
    }
};

export const setDrawerOpacity = percentage => {
    return {
        type: "SET_OPACITY",
        payload: percentage
    }
};

export const toggleHalfView = () => {
    return {
        type: 'TOGGLE_HALF_SCREEN_VIEW'
    }
};

export const toggleFullScreenDesktopView = () => {
    return {
        type: 'TOGGLE_FULL_SCREEN_DESKTOP'
    }
};

export const setEncryption = (data) => {
    return {
        type: 'SET_ENCRYPTION_DATA',
        payload: data
    }
};

export const setOverlayData = (data) => {
    return {
        type: 'SET_OVERLAY_DATA',
        payload: data
    }
};

export const scaleHalfDrawer = payload => {
    return {
        type: "SCALE_HALF_DRAWER",
        payload: payload
    }
};

export const unsetMedia = () => {
    return {
        type: "UNSET_MEDIA"
    }
};

export const resetMediaDefaults = () => {
    return {
        type: "RESET_MEDIA_DEFAULTS"
    }
};

export const setPlaybarPercentage = (percentage) => {
    return {
        type: "SET_PLAYBAR_PERCENTAGE",
        payload: percentage
    }
};

const optimizeFrames = (frames) => {
    let newframes = {}
    //create an array of frame numbers
    const frameNumbers = Object.keys(frames);
    //loop through frame numbers and access data on frame object
    for (let i = 0; i < frameNumbers.length; i++) {
        const frameNumber = frameNumbers[i];
        //set previous frame
        const prevFrame = i === 0 ? frames[frameNumbers[0]] : frames[frameNumbers[i - 1]]
        //Merge previous frame meta with current frame meta
        //This will update property values to the current frame and add missing properties if they are absent
        //If the current frame is the frame missing values then the previous frame properties will persist into the new object "newMeta"
        //This is done separatly from the frame object merge as the spread operator merge is shallow
        const newMeta = { ...prevFrame['meta'], ...frames[frameNumber]['meta'] }

        frames[frameNumber]['meta'] = newMeta;//add the new meta data object back into the current frame
        newframes[frameNumber] = { ...prevFrame, ...frames[frameNumber] }//merge the previous frame with the current

    }
    return newframes
}

// iOS Testing, media should be cleared when edit unmounts
export const clearMediaObject = () => {
    return {
        type: "CLEAR_MEDIA_OBJECT",
        payload:{
            media: {},
            duration: '',
            apprequired:null,
            shareurl:''
        }
    }
};