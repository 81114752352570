import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import {sortMedia} from '../actions'
import "./SortModal.css"

class SortModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortToggled: false,
            activeSort: "Date published(newest)",
            sortOptions: [
                {
                    icon: "/sorticons/uparrow.png",
                    displayTitle: "Date published(newest)",
                    sortName: "mediapublisheddate",
                    sortDirection: false,
                },
                {
                    icon: "/sorticons/downarrow.png",
                    displayTitle: "Date published(oldest)",
                    sortName: "mediapublisheddate",
                    sortDirection: true,
                },
                {
                    icon: "/sorticons/heart.png",
                    displayTitle: "Most popular",
                    sortName: "mediaviewcount",
                },
                {
                    icon: "/sorticons/alphabet.png",
                    displayTitle: "Alphabetical by title",
                    sortName: 'mediatitle',
                }
            ]
        }
    }

    toggleSort = () => {
        this.setState({
            sortToggled: !this.state.sortToggled
        })
    }

    selectSort = (option) => {
        const {activeSort} = this.state
        //Set selected tab background grey in render jsx conditional

        if (activeSort !== option.displayTitle) {
            this.setState({
                activeSort: option.displayTitle
            })
        }
    }

    sortList = (option) => {
        const {sortMedia, allMedia} = this.props;
        const {activeSort} = this.state;
        const {selectSort} = this;
        let mediaArrayCopy = JSON.parse(JSON.stringify(allMedia))

        selectSort(option);

        if (option.sortName === 'mediaviewcount') {
            mediaArrayCopy.sort((a, b) => parseInt(b[option.sortName]) - parseInt(a[option.sortName]));
        } else {
            if (option.sortDirection) {
                mediaArrayCopy.sort((a, b) => {
                    return (a[option.sortName] === null) - (b[option.sortName] === null) || +(a[option.sortName] > b[option.sortName]) || -(a[option.sortName] < b[option.sortName]);
                })
            } else {
                mediaArrayCopy.sort((a, b) => {
                    return (a[option.sortName] === null) - (b[option.sortName] === null) || -(a[option.sortName] > b[option.sortName]) || +(a[option.sortName] < b[option.sortName]);
                })
            }
        }

        sortMedia(mediaArrayCopy);
    }

    render() {
        const {sortToggled, sortOptions, activeSort} = this.state
        const {toggleSort, sortList} = this;

        return (
            <div className="sortmodal-container">
                <div className="sortmodal-text-icon-wrapper">
                    <div className="sortmodal-icon-wrapper">
                        <img
                            onClick={() => toggleSort()}
                            className="sortmodal-icon"
                            src={`${process.env.PUBLIC_URL}/sort-by-icon.png`} alt=""/>
                    </div>
                    <div className="sortmodal-button-text">
                        SORT BY
                    </div>
                </div>
                {sortToggled &&
                <div className="sort-modal-body-container">
                    <ul>
                        {
                            sortOptions.map((option, i) =>
                                <li key={i}
                                    onClick={() => sortList(option)}
                                    style={{
                                        background: option.displayTitle === activeSort ? 'lightgrey' : 'white'
                                    }}
                                >
                                    <div
                                        className="sortmodal-list-icon-wrapper"
                                    >
                                        <img
                                            className="sortmodal-icon"
                                            src={`${process.env.PUBLIC_URL}` + option.icon} alt=""/>
                                    </div>
                                    <div>
                                        {option.displayTitle}
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </div>
                }
            </div>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            filter: state.navReducer.filter,
            clientid: state.loginReducer.authentication.clientid,
            clientchannel: state.loginReducer.authentication.clientchannel,
            loginToken: state.loginReducer.authentication.loginToken,
            allMedia: state.MediaReducer.allMedia,
            isSingleView: state.MediaReducer.isSingleView
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {
            sortMedia: (sortedArray) => {
                dispatch(sortMedia(sortedArray))
            },
        }
    };


export default withRouter(connect

(mapStateToProps, mapDispatchToProps)(
    SortModal
));
