import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './reputationscore.css'
import {toggleReputationModal} from "../actions";

class ReputationScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: 0,
            caution: 0,
            invalid: 0
        };
    }

    componentDidMount() {
        const {summaryBullets} = this.props;
        this.setSummaryBullets(summaryBullets);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {summaryBullets} = this.props;
        if (prevProps !== this.props) {
            this.setSummaryBullets(summaryBullets)
        }
    }

    setSummaryBullets = (bullets) => {
        if (bullets) {
            this.setState({
                valid: bullets.green ? bullets.green : 0,
                caution: bullets.yellow ? bullets.yellow : 0,
                invalid: bullets.red ? bullets.red : 0,
                trimmed: bullets.trimmed ? bullets.trimmed : 0
            })
        }
    };

    toggleModal = () => {
        const {toggleReputationModal} = this.props;
        toggleReputationModal();
    };

    render() {
        const {viewCss} = this.props;
        const {toggleModal} = this;

        return (
            <div>
                <div className={viewCss}>
                    {
                        parseInt(this.state.valid) > 0 ?
                            <div className="bullet-wrapper"
                                 onClick={() => toggleModal()}
                            >
                                <div className="valid">
                                    Valid {this.state.valid}
                                </div>
                                {parseInt(this.state.trimmed) > 0 || parseInt(this.state.invalid) > 0 || parseInt(this.state.caution) > 0 ?
                                    <div className="bullet-break"></div>
                                    :
                                    ''
                                }
                            </div>
                            : ''
                    }
                    {
                        parseInt(this.state.caution) > 0 ?
                            <div className="bullet-wrapper"
                                 onClick={() => toggleModal()}
                            >
                                <div className="caution">
                                    Caution {this.state.caution}
                                </div>
                                {parseInt(this.state.trimmed) > 0 || parseInt(this.state.invalid) > 0 ?
                                    <div className="bullet-break"></div>
                                    :
                                    ''
                                }
                            </div>
                            : ''
                    }
                    {
                        parseInt(this.state.invalid) > 0 ?
                            <div className="bullet-wrapper"
                                 onClick={() => toggleModal()}
                            >
                                <div className="invalid">
                                    Invalid {this.state.invalid}
                                </div>
                                {parseInt(this.state.trimmed) > 0 ?
                                    <div className="bullet-break"></div>
                                    :
                                    ''
                                }
                            </div>
                            : ''
                    }
                    {
                        parseInt(this.state.trimmed) > 0 ?
                            <div className="bullet-wrapper"
                                 onClick={() => toggleModal()}
                            >
                                <div className="trimmed">
                                    Trimmed {this.state.trimmed}
                                </div>
                            </div>
                            : ''
                    }
                    {/*<div*/}
                    {/*    className="reputation-modal-button"*/}
                    {/*    onClick={() => toggleModal()}*/}
                    {/*>*/}
                    {/*    <img src={`${process.env.PUBLIC_URL}/Help-Icon.png`} alt=""/>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        toggleReputationModal: () => {
            dispatch(toggleReputationModal())
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReputationScore));
