import React from "react";
import {openShareWindow} from '../utilityFunctions/openShareWindow'

const SocialShareComponent = ({socialNetwork, shareurl, icon, cssStyle, embed}) => {

  if (!embed) {

      return (
          <div
              className={cssStyle}
              onClick={socialNetwork ?() => openShareWindow(socialNetwork, encodeURIComponent(shareurl)):null}
              key={shareurl}
          >
              <img src={`${process.env.PUBLIC_URL}${icon}`} alt="Social Share Icon" />
          </div>
      )
  }
  return (
      <div className={cssStyle}>
          <a href={`${socialNetwork}${encodeURIComponent(shareurl)}`} rel="noopener noreferrer" >
              <img src={`${process.env.PUBLIC_URL}${icon}`} alt="Social Share Icon" />
          </a>
      </div>
  )
}

export default SocialShareComponent
