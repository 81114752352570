import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./location.css"
import Chart from "chart.js";
import ReactGoogleMaps from "./ReactGoogleMaps";

class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: 11
        };
    }

    componentDidMount() {
        const speedCtx = this.speedCanvas.getContext("2d");
        const traveledCtx = this.traveledCanvas.getContext("2d");
        const gpsaltitudeCtx = this.gpsaltitudeCanvas.getContext("2d");
        // const gpshorizontalaccuracyCtx = this.gpshorizontalaccuracyCanvas.getContext("2d");

        this.speedChart = new Chart(speedCtx, {
            type: "line",
            data: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: '',
                        fill: false,
                        backgroundColor: 'blue',
                        pointRadius: 4
                    },
                    {
                        data: [],
                        label: '',
                        fill: false,
                        spanGaps: true,
                        backgroundColor: 'red',
                        borderColor: "white",
                        pointRadius: 0
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1,
                animation: {},
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "white"
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: 'transparent',
                            display: false
                        },
                        gridLines: {
                            display: false,
                            color: "white"
                        }
                    }],
                },
                tooltips: {enabled: false},
                hover: {mode: null},
                showTooltips: false,
                elements: {
                    line: {
                        tension: 0
                    }
                },
                legend: {
                    display: false
                }
            }
        });

        this.traveledChart = new Chart(traveledCtx, {
            type: "line",
            data: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: '',
                        fill: false,
                        backgroundColor: 'blue',
                        pointRadius: 4
                    },
                    {
                        data: [],
                        label: '',
                        fill: false,
                        spanGaps: true,
                        backgroundColor: 'red',
                        borderColor: "white",
                        pointRadius: 0
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1,
                animation: {},
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "white"
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: 'transparent',
                            display: false
                        },
                        gridLines: {
                            display: false,
                            color: "white"
                        }
                    }],
                },
                tooltips: {enabled: false},
                hover: {mode: null},
                showTooltips: false,
                elements: {
                    line: {
                        tension: 0
                    }
                },
                legend: {
                    display: false
                }
            }
        });

        this.gpsaltitudeChart = new Chart(gpsaltitudeCtx, {
            type: "line",
            data: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: '',
                        fill: false,
                        backgroundColor: 'blue',
                        pointRadius: 4
                    },
                    {
                        data: [],
                        label: '',
                        fill: false,
                        spanGaps: true,
                        backgroundColor: 'red',
                        borderColor: "white",
                        pointRadius: 0
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1,
                animation: {},
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: 'transparent'
                        },
                        gridLines: {
                            display: false,
                            color: "white"
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: 'transparent',
                            display: false
                        },
                        gridLines: {
                            display: false,
                            color: "white"
                        }
                    }],
                },
                tooltips: {enabled: false},
                hover: {mode: null},
                showTooltips: false,
                elements: {
                    line: {
                        tension: 0
                    }
                },
                legend: {
                    display: false
                }
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setChartData(this.speedChart, 'locationSpeedArray')
        this.setChartData(this.traveledChart, 'distancetravelledArray')
        this.setChartData(this.gpsaltitudeChart, 'gpsaltitudeArray')
    }

    shouldComponentUpdate(nextProps) {
        const {equals} = this;
        return equals(nextProps);
    }

    equals = (next) => {
        // If incoming props are null or empty dont re-render
        if (next.sliderValue !== this.props.sliderValue) {
            return true
        }
        if (next.locationData.address === undefined) {
            return false
        } else {
            return true
        }
    };

    getLabelCount = (frames) => {
        let count = [];
        if (frames) {
            for (let i = 0; i < frames.length; i++) {
                count.push(i);
            }
        }

        return count
    };

    setChartData = (chart, chartArrayName) => {
        const {progress, duration, mediaType, lightView} = this.props;
        const locationData = this.props.locationData;
        if (!locationData) {
            return
        }
        let frames = 0;

        if (mediaType === 'image') {
            //On image media display single point of frame
            if (locationData[chartArrayName])
                chart.data.datasets[0].data = [locationData[chartArrayName][0]]
        } else {
            if (progress === duration && progress !== undefined && duration !== undefined) {
                // console.log(locationData)
                //Resets the blue dot back to orginal position after video plays through
                chart.data.datasets[0].data = [locationData[chartArrayName][0]]
            } else {
                if (locationData[chartArrayName]) {
                    //Sets amount of total frames
                    frames = locationData[chartArrayName].length;
                }
                let frameMath = (frames / duration);
                //Full array is used to draw white line
                chart.data.datasets[1].data = locationData[chartArrayName];

                if (locationData[chartArrayName]) {
                    //Blue dot is rendered as x and y values are added during media progress. The value is rounded to connect with actual array index
                    chart.data.datasets[0].data = [locationData[chartArrayName][Math.round(progress * frameMath)]];
                }
                chart.data.labels = this.getLabelCount(locationData.locationSpeedArray);
            }
        }

        ////update chart border colors
        chart.data.datasets[1].borderColor = !lightView ? 'white' : 'black'
        chart.update();
    };

    cleanCoordinateValues = (cordArray) => {
        // converts object of objects to array and exchanges strings for num values
        let convertedToArray = Object.values(cordArray);

        for (let cord of convertedToArray) {
            cord['lng'] = Number(cord['lng']);
            cord['lat'] = Number(cord['lat'])
        }

        //Prevents polyline from drawing for 0 : 0 which creates a starting point of the center of the world
        let removeZero = convertedToArray.filter((cord) => {
            return cord.lng !== 0
        });

        return removeZero
    };

    convertAndFix = (data) => {
        let cleanedString = data.split('.')
        return cleanedString[0] + ' °\n'
    }

    render() {
        const {convertAndFix} = this
        const {
            locationData: {
                heading,
                gpslatitude,
                gpslongitude,
                address,
                speed,
                gpsaltitude,
                distancetravelled,
                gpsaccuracy,
                compass,
                headingdirection,
                gpslatitudedeg,
                gpslatitudemin,
                gpslatitudesec,
                gpslatitudedir,
                gpslongitudedeg,
                gpslongitudemin,
                gpslongitudesec,
                gpslongitudedir,
                sliderValue,
                summaryData
            }
        } = this.props;

        let locationData = heading ? heading : '';
        let compassRotate = {
            transform: `rotate(${locationData}deg)`,
            transition: `transform 500ms ease`
        };

        let lightMode = 'black';
        let darkMode = 'white';

        return (
            <div className="location-container"
                 style={!this.props.lightView ? {background: `rgb(72,115,134,${sliderValue * .01})`} : {background: `white`}}>
                <div className="component-header-wrapper">
                    <div
                        className="component-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        LOCATION
                    </div>
                    <div className="component-icon">
                        <img src={`${process.env.PUBLIC_URL}/Icon-Pin.png`} alt=""/>
                    </div>
                </div>
                <div className="location-address-wrapper">
                    <div style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        {address !== 'NA' ? address : '. . .'}
                    </div>
                </div>
                <div className="location-map-container">
                    <div className="location-map">
                        <ReactGoogleMaps
                            initialCenter={{
                                lng: Number(gpslongitude),
                                lat: Number(gpslatitude)
                            }}
                            progress={this.props.progress}
                            polylineColor={summaryData}
                            polylineCoordinates={this.cleanCoordinateValues(this.props.locationDataPointsArray)}
                            iconUrl={`${process.env.PUBLIC_URL}/blue.png`}
                            zoom={17}
                        />
                    </div>
                </div>
                <div className="location-compass-container">
                    <div className="location-compass-wrapper">
                        <div style={compassRotate}>
                            <img
                                className="compass-back"
                                src={`${process.env.PUBLIC_URL}/Compass-Base.png`}
                                alt=""/>
                        </div>
                        <div className="compass-needle-outter-wrapper">
                        </div>
                        <div
                            className="location-compass-degrees">{convertAndFix(locationData)} {headingdirection ? headingdirection : compass}</div>
                    </div>
                    <div className="location-compass-wrapper">
                        <div className="reading-wrapper">
                            <div
                                className="reading-title"
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                Latitude
                            </div>
                            <div
                                className="reading"
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                {gpslatitude ? gpslatitudedeg + "°" + " " + gpslatitudemin + "'" + " " + gpslatitudesec + '"' + " " + gpslatitudedir : 0.0}
                            </div>
                            <div
                                className="reading-title"
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                Longitude
                            </div>
                            <div
                                className="reading"
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                {gpslongitude ? gpslongitudedeg + "°" + " " + gpslongitudemin + "'" + " " + gpslongitudesec + '"' + " " + gpslongitudedir : 0.0}
                            </div>
                            <div
                                className="reading-title"
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                GPS Accuracy
                            </div>
                            <div
                                className="reading"
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                +/- {gpsaccuracy ? gpsaccuracy : 0}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="location-graph-set">
                    <div className="location-graph">
                        <div
                            className="location-graph-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                        >
                            Speed
                        </div>
                        <div
                            className="location-graph-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                        >
                            {speed ? speed : 0.0}
                        </div>
                        <div className="chart-wrapper">
                            <canvas id="speedCanvas" ref={speedCanvas => {
                                this.speedCanvas = speedCanvas;
                            }}/>
                        </div>
                    </div>
                    <div className="location-graph">
                        <div
                            className="location-graph-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Distance
                        </div>
                        <div
                            className="location-graph-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                        >
                            {distancetravelled ? distancetravelled : '0.0'}mi
                        </div>
                        <div className="chart-wrapper">
                            <canvas id="traveledCanvas" ref={traveledCanvas => {
                                this.traveledCanvas = traveledCanvas;
                            }}/>
                        </div>
                    </div>
                    <div className="location-graph">
                        <div
                            className="location-graph-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Altitude
                        </div>
                        <div
                            className="location-graph-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {gpsaltitude ? isNaN(Math.round(gpsaltitude)) ? 0 : Math.round(gpsaltitude) + " feet" : 0}
                        </div>
                        <div>
                            <canvas id="gpsaltitudeCanvas" ref={gpsaltitudeCanvas => {
                                this.gpsaltitudeCanvas = gpsaltitudeCanvas;
                            }}/>
                        </div>
                    </div>
                    {/*<div className="location-graph">Altitude</div>*/}
                </div>
            </div>
        )
    }
}

const
    mapStateToProps = (state, {match: {params}}) => {
        return {
            mediaFrames: state.mediaSingleReducer.mediaFrames,
            locationDataPointsArray: state.mediaSingleReducer.locationData,
            sliderValue: state.mediaSingleReducer.sliderValue,
            mediaType: state.mediaSingleReducer.currentMedia.mediatype,
            color: state.mediaSingleReducer.color,
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {};
    };

export default withRouter(connect

(
    mapStateToProps
    ,
    mapDispatchToProps
)(
    Location
))
