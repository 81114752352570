import React from 'react'
import {connect} from "react-redux";
import './networks.css'

class ToggleAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        };
    }

    toggleDetails = () => {
        this.setState({
            toggled: !this.state.toggled
        })
    };

    render() {
        const {toggled} = this.state;
        const {contentBlock} = this.props;
        const {toggleDetails} = this;
        
        return (
            <div className='toggle-container'>
                <div className="toggle-controls-wrapper"
                     onClick={() => toggleDetails()}
                >
                    <div>
                        {toggled ?
                            <div className="toggle-triangle-down">
                            </div>
                            :
                            <div className="toggle-triangle-up">
                            </div>
                        }
                    </div>
                    <div className="details-title">Details</div>
                </div>
                {toggled &&
                <div className="toggle-content-wrapper">
                    {contentBlock()}
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleAccordion)