import React from 'react'
import './ModalComponent.css'

//Coupled with Edit, abstract out values and pass in props for
// p tag, button values and onClick event handlers.

const ModalComponent = (props) => (
    <div>
        <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModal"
             aria-hidden="true" onClick={props.handleDeleteCheckbox}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-body">
                        <p>Are you sure you want to delete your recording from VideoLock.com?</p>
                    </div>
                    <div className="delete-modal-footer">
                        <div className="row">
                            <div className="col col-sm-6 delete">
                                <button onClick={() => props.clickedFunction()} type="button" className="btn btn-light"
                                        data-dismiss="modal">Delete
                                </button>
                            </div>
                            <div className="col col-sm-6">
                                <button type="button" className="btn btn-light" data-dismiss="modal" onClick={props.handleDeleteCheckbox}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

);

export default ModalComponent
