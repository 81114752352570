import React from 'react'

import SocialShareComponent from '../SocialShareComponent/SocialSchareComponent'

const handleEmailIcon = (shareurl) => {
    if (navigator.share) {
        return (
            <div className="share-modal-media-icon-wrapper"
                 onClick={() => this.handleEmailSharing(shareurl)}
            >
                <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>
            </div>
        )
    }
    return (
        <div className="share-modal-media-icon-wrapper"
        >
            <a href="mailto:" target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>
            </a>
        </div>
    )
};

const DeskTopSocialShareModal = (props) => (
    <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
        >
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content social-share-modal-content">
                <div className="social-share-modal-header">
                    <div className="row header-row">
                        <div className="col-sm-6">
                            <span  className="modal-title" id="exampleModalLongTitle">Share</span>
                        </div>
                        <div className="col-sm-6">
                            <button type="button" onClick={()=>props.toggleMenu("hidden")} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="share-modal-media-wrapper ">
                                {handleEmailIcon(props.shareurl)}
                                {props.socialNetworksArray.map(({socialNetwork, icon}) =>
                                    <SocialShareComponent
                                        socialNetwork={socialNetwork}
                                        shareurl={props.shareurl}
                                        icon={icon}
                                        key={icon}
                                        cssStyle="share-modal-media-icon-wrapper"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="share-modal-body-wrapper">
                    <div className="row">
                        <div className={"col-sm-10"} >
                            <input type="text" value={props.shareurl} id="myInput" ref={props.inputRef} readOnly/>
                        </div>
                        <div className={"col-sm-2"}  >
                            <button type="button" onClick={() =>props.copyFunction(props.shareurl,props.setCopySuccess, props.inputRef)} className="btn btn-link">Copy</button>
                        </div>
                    </div>
                </div>
                <div className="modal-footer share-modal-body-footer">
                    <span style={{visibility:`${props.copySuccess}`}} className="modal-title" id="exampleModalLongTitle">Successfully Copied!</span>
                </div>
            </div>
        </div>
    </div>

);

export default DeskTopSocialShareModal
