import React from 'react'
import ReactDOM from 'react-dom'
import WaveSurfer from 'wavesurfer.js'

export default class Waveform extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audioPlaying: false
        }
    }

    componentDidMount() {
        this.$el = ReactDOM.findDOMNode(this);
        this.$waveform = this.$el.querySelector('.wave');
        this.wavesurfer = WaveSurfer.create({
            container: this.$waveform,
            waveColor: '#00bbff',
            progressColor: 'green',
            responsive: true,
            cursorWidth: 0,
            mediaType: "audio",
            pixelRatio: window.devicePixelRatio,
            backend: 'MediaElement',

        });
        this.wavesurfer.load(this.props.src)
    }

    playPause = () => {
        this.setState({
            audioPlaying: !this.state.audioPlaying
        });
        this.wavesurfer.playPause()
    };

    render() {
        return (
            <div className='waveform'>
                <div className='wave'>
                </div>
            </div>
        )
    }
}

Waveform.defaultProps = {
    src: ""
};
// import React from 'react'
// import ReactDOM from 'react-dom'
// import WaveSurfer from 'wavesurfer.js'
//
// export default class Waveform extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             audioPlaying: false
//         }
//     }
//
//     componentDidUpdate() {
//         // AUDIO CONTEXT
//         window.AudioContext = (window.AudioContext ||
//             window.webkitAudioContext ||
//             window.mozAudioContext ||
//             window.oAudioContext ||
//             window.msAudioContext);
//
//         let audioContext = new AudioContext();
//         let currentBuffer = null;
//
//         let canvasWidth = 900, canvasHeight = 120;
//         let newCanvas = createCanvas(this.waveFormCanvas,canvasWidth, canvasHeight);
//         let context = this.waveFormCanvas.getContext("2d");
//
//         let appendCanvas = () => {
//             this.waveFormCanvas = newCanvas.getContext("2d");
//             // document.body.appendChild(newCanvas);
//         };
//
//         appendCanvas();
//
//
//         function loadMusic(url) {
//             let req = new XMLHttpRequest();
//             req.open("GET", url, true);
//             req.responseType = "arraybuffer";
//             req.onreadystatechange = function (e) {
//                 alert(req.readyState)
//                 if (req.readyState == 4) {
//                     if (req.status == 200)
//                         audioContext.decodeAudioData(req.response,
//                             function (buffer) {
//                                 currentBuffer = buffer;
//                                 displayBuffer(buffer);
//                             }, onDecodeError);
//                     else
//                         alert('error during the load.Wrong url or cross origin issue');
//                 }
//             };
//             req.send();
//         }
//
//         function onDecodeError() {
//             alert('error while decoding your file.');
//         }
//
//         function displayBuffer(buff /* is an AudioBuffer */) {
//             var drawLines = 800;
//             var leftChannel = buff.getChannelData(0); // Float32Array describing left channel
//             var lineOpacity = canvasWidth / leftChannel.length;
//             context.save();
//             context.fillStyle = '#080808';
//             context.fillRect(0, 0, canvasWidth, canvasHeight);
//             context.strokeStyle = '#46a0ba';
//             context.globalCompositeOperation = 'lighter';
//             context.translate(0, canvasHeight / 2);
//             //context.globalAlpha = 0.6 ; // lineOpacity ;
//             context.lineWidth = 1;
//             var totallength = leftChannel.length;
//             var eachBlock = Math.floor(totallength / drawLines);
//             var lineGap = (canvasWidth / drawLines);
//
//             context.beginPath();
//             for (var i = 0; i <= drawLines; i++) {
//                 var audioBuffKey = Math.floor(eachBlock * i);
//                 var x = i * lineGap;
//                 var y = leftChannel[audioBuffKey] * canvasHeight / 2;
//                 context.moveTo(x, y);
//                 context.lineTo(x, (y * -1));
//             }
//             context.stroke();
//             context.restore();
//         }
//
//         function createCanvas(canvas, w, h) {
//             let newCanvas = canvas;
//             newCanvas.width = w;
//             newCanvas.height = h;
//             return newCanvas;
//         };
//
//         if (this.props.src) {
//             loadMusic(this.props.src);
//         }
//     }
//
//
//     playPause = () => {
//         this.setState({
//             audioPlaying: !this.state.audioPlaying
//         });
//         this.wavesurfer.playPause()
//     };
//
//     render() {
//         const {audioPlaying} = this.state;
//         return (
//             <div className='waveform'>
//                 <canvas id="waveFormCanvas" ref={waveFormCanvas => {
//                     this.waveFormCanvas = waveFormCanvas;
//                 }}/>
//             </div>
//         )
//     }
// }
//
// Waveform.defaultProps = {
//     src: ""
// };
