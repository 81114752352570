import React from 'react';
/*TODO*/
//Add checkbox value to state. When delete function is called or modal canceled change state value to false
const DeleteMediaComponent = ({deleteCheckboxValue, handleDeleteCheckbox}) => {
    return (
        <div className="delete-component-container">
            <div className="delete-component-wrapper">
                <div className="delete-check-box">
                    <input type="checkbox"
                           name="deletecheckbox"
                           data-toggle="modal"
                           data-target="#deleteModal"
                           checked={deleteCheckboxValue}
                           onChange={handleDeleteCheckbox}
                    />
                </div>
                <div className="left-side-wrapper">
                    <div className="delete-label">Delete</div>
                    <div className="delete-description">Permanently remove media from channel</div>
                </div>
            </div>
        </div>
    )
}

export default DeleteMediaComponent;
