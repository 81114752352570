const initialState ={
  posts:[]
}
const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_POST":
      return {
        ...state,
        posts:[...state.posts,action.data]
      }
    case "DELETE_POST":
      return {
        ...state,
        posts: state.posts.filter(post => post.id !== action.id)
      }
    case "EDIT_POST":
      return {
        ...state,
        posts: state.posts.map(post =>
          post.id === action.id ? { ...post, editing: !post.editing } : post
        )
      }
    default:
      return state;
  }
};
export default postReducer;
