import Dexie from 'dexie';
/*
https://dexie.org/docs/
https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
Create database videolockframedb
table='frames'
primary key for indexed data = mediakey
*/
const db = new Dexie('videolockframedb');
db.version(1).stores({ frames: 'mediakey' });

export default db;
