import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./desktopnavpopup.css"
import {forgotPassword} from "./actions";

class PasswordPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordError: ''
        };
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.type]: e.target.value
        })
    };

    validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    handleReset = (e, email) => {
        const {validateEmail} = this;
        const {back, forgotPassword} = this.props;
        const emailInput = this.getEmail.value;

        e.preventDefault();

        if (emailInput === ''){
            this.setState({
                passwordError: 'Email required'
            });
            return
        }
        
        if (validateEmail(emailInput)) {
        forgotPassword({email: email});
        back('Verify');
        } else {
            this.setState({
                passwordError: 'Invalid email address'
            });
        }
    };

    render() {
        const {back} = this.props;
        const {handleReset} = this;

        return (
            <div>
                <div onClick={() => back('Home')}>
                    <img className="back-arrow" src={`${process.env.PUBLIC_URL}/arrow_back.png`} alt=""/>
                </div>
            <div className="panel-message-wrapper-login">
                    <div className='panel-message-login'>Please enter your email address and a password reset code will
                        be sent to you.
                    </div>
        </div>
                <form>
                    <div className="panel-form-input-container-login">
                        <input
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            ref={input => (this.getEmail = input)}
                        />
                        <div className="password-error-wrapper">
                            {this.state.passwordError}
                        </div>
                    </div>
                    <div className="button-container-center">
                        <button
                            className="navpop-up-blue-btn remove-styles-btn"
                            type="submit"
                            onClick={(e) => handleReset(e, this.state.email)}
                        >
                            Send Reset Code
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        email: state.loginReducer.authentication.email,
        loginToken: state.loginReducer.authentication.loginToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        forgotPassword: payload => {
            dispatch(forgotPassword(payload))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordPanel))