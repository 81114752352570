let moment = require("moment")

export const formatTrimDuration = (mediaduration) =>{
    console.log("Duration to format: ", mediaduration)
    if(!mediaduration)return ""
    if (mediaduration && mediaduration.includes(":")) return mediaduration

    const parsedDuration = parseInt(mediaduration)
    const duration = moment.duration(parsedDuration);
    const hoursAndMinutes =  Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm")
    let d = moment.duration(parsedDuration, 'milliseconds');
    let seconds = Math.floor(d.asSeconds())
    let ms = Math.floor(d.asMilliseconds()) - seconds * 1000;
    const secondsString = seconds + "." + ms
    return hoursAndMinutes +":" + roundup(parseFloat(secondsString), 2)

}

const roundup = (num,precision) =>{
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}
