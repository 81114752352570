import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./desktopnavpopup.css"
import {resetPassword} from "./actions";

class ConfirmPassReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {passwordUpdateMessage, restAuth, back} = this.props;
        if (passwordUpdateMessage.includes("Successfully")) {
            setTimeout(() => back('Home'), 1500)
        }
    }

    handleInputChange = (e) => {
        const {placeholderInputTypeAction} = this;

        this.setState({
            [e.target.name]: e.target.value
        });

        placeholderInputTypeAction(e.target.name, e);
    };

    handleConfirmRest = (payload) => {
        const {resetPassword, restAuth, userEmail} = this.props;
        if (restAuth)
            resetPassword({
                password: payload.newPassword,
                confirmpassword: payload.confirmNewPassword,
                reset_authtoken: restAuth,
                email: userEmail
            })
    };

    placeholderInputTypeAction = (field, e) => {
        let value = e.target.value;
        let fieldName = field + 'Type';

        value === '' ?
            this.setState({
                [fieldName]: 'search'
            })
            :
            this.setState({
                [fieldName]: 'password'
            });
    };

    render() {
        const {passwordUpdateMessage, messageColor} = this.props;
        const {handleConfirmRest} = this;

        return (
            <div>
                <div className="panel-form-input-container">
                    <div className="form-space">
                    </div>
                    <input
                        type={this.state.newPasswordType}
                        name="newPassword"
                        placeholder="New Password"
                        value={this.state.newPassword}
                        onChange={this.handleInputChange}
                    />
                    <div className="form-space">
                    </div>
                    <input
                        type={this.state.confirmNewPasswordType}
                        placeholder="Confirm Password"
                        name="confirmNewPassword"
                        value={this.state.confirmNewPassword}
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="button-container-center">
                    <div
                        className="navpop-up-blue-btn"
                        onClick={() => handleConfirmRest({
                            newPassword: this.state.newPassword,
                            confirmNewPassword: this.state.confirmNewPassword
                        })}
                    >
                        Change Password
                    </div>
                </div>
                <div
                    className="password-change-message"
                    style={{color: messageColor}}>
                    {passwordUpdateMessage}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        email: state.loginReducer.authentication.email,
        passwordUpdateMessage: state.desktopNavReducer.passwordUpdateMessage,
        messageColor: state.desktopNavReducer.messageColor,
        restAuth: state.desktopNavReducer.restAuth,
        userEmail: state.desktopNavReducer.userEmail
    }
};

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: payload => {
            dispatch(resetPassword(payload))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPassReset))