import React from 'react'

const VerifiedModal = (props) => (
    <div className={props.version !== 'mobile' ? "verified-modal-container" : "verified-modal-mobile"}>
        {/*<div className="verified-modal-container">*/}
        <div className="verified-modal-header">
            <div>
                About
            </div>
            <div>
                <div
                    className={props.version !== 'mobile' ? "verified-modal-image-wrapper" : "verified-modal-image-mobile"}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/VerifiedIcon.png`}
                        alt=""/>
                </div>
            </div>
            <div>
                accounts
            </div>
        </div>
        <div className="verified-modal-content">
            <p>
                That green verified badge on VideoLock tells people that an account of public interest is authentic and
                that the user has been verified by VideoLock.
            </p>
            <p>
                The badge appears next to the channel name. It is always the same color and placed in the same location.
            </p>
        </div>
    </div>
);

export default VerifiedModal