import React from "react";
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom'

import EditFormComponent from './EditFormComponent'
import EditThumbnailComponent from './EditThumbnailComponent';

import {toggleEdit, clearQuery} from "../actions/index";
import MediaDisplayComponent from "./MediaDisplayComponent";
import VisibilityComponent from "./VisibilityComponent";
import MediaStatisticsComponent from "./MediaStatisticsComponent";
import DeleteMediaComponent from "./DeleteMediaComponent";



class MobileEditComponent extends React.Component {
    constructor(props) {
        super(props);
    };

    render() {
        const {media, createTimeStampArray, handleSelectImageThumbnail,
            handleEdit,clientchannel,handleDeleteCheckbox, error,updateMessage,
            handleUpdate, handleUndoChanges, clearQuery
        } = this.props;
        const {formFields, duration, deleteCheckboxValue, editing} = this.props.editState;
        return (
            <div className="edit-cont-center">
                <div className="edit-nav-header row">
                    <div className="return-nav-wrapper">
                        <Link to={`/${clientchannel ? clientchannel : "login"}`}
                        onClick={clearQuery}
                        >
                            <div className="return-nav btn">
                                <span className="return-arrow-icon">
                                    <i className="fa fa-arrow-left" aria-hidden="true">
                                    <span className="return-title">VIDEOLIST</span>
                                    </i>
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="save-changes-wrapper">
                        <div className="save-changes">
                            <button className={`btn btn1 ${editing ? "editing-button1": ""}`}  onClick={handleUndoChanges}>UNDO CHANGES</button>
                            <button className={`btn btn2 ${editing ? "editing-button2": ""}`} name="btn2" onClick={handleUpdate}>SAVE</button>
                            {/*<i className="fa fa-ellipsis-v fa-2x " aria-hidden="true"*/}
                            {/*   style={{color: 'darkgrey'}}> </i>*/}
                        </div>
                    </div>
                </div>
                <div className="main-content-wrapper">
                    <div className="">
                        <MediaDisplayComponent
                            media={media}
                            mediacoverurl={media.mediacoverurl}
                        />
                        {(error || updateMessage) &&
                        <div className="edit-errors-display">
                            {error ? error : updateMessage}
                        </div>
                        }
                        <div className="media-information-wrapper">
                            <EditFormComponent
                                mediatitle={formFields.mediatitle}
                                mediadescription={formFields.mediadescription}
                                changeField={handleEdit}

                            />
                            <VisibilityComponent
                                stateFormFields={formFields}
                                changeField={handleEdit}
                                name={"mediasharemethod"}
                            />
                            <EditThumbnailComponent
                                media={media}
                                createTimeStampArray={createTimeStampArray}
                                handleSelectImageThumbnail={handleSelectImageThumbnail}
                                duration={duration}
                            />
                            <MediaStatisticsComponent
                                media={media}
                            />
                            <DeleteMediaComponent
                                deleteCheckboxValue={deleteCheckboxValue}
                                handleDeleteCheckbox={handleDeleteCheckbox}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.MediaReducer.error,
        updateMessage:state.mediaSingleReducer.updateMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleEdit:()=>{
            dispatch(toggleEdit())
        },
        clearQuery: () => {
            dispatch(clearQuery())
        }
    }
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MobileEditComponent)
);
