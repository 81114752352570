const initialState = {
    images: [],
    videos: [],
    audio: [],
    allMedia: [],
    searchedMedia: [],
    currentMedia: [],
    toggleReputationModal: false,
    mediaKey: "",
    loading: false,
    error: null,
    // error: [],
    copySuccess:"hidden",
    isSingleView: false,
    clientchannelverified:false,
    query:''
};
const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_IMAGE":
            return {
                ...state,
                currentMedia: action.payload,
                searchedMedia: action.payload,
                loading: false
            };
        case "DELETE_IMAGE":
            return {
                ...state,
                images: state.images.filter(image => image.id !== action.id)
            };
        case "ADD_VIDEO":
            return {
                ...state,
                currentMedia: action.payload,
                searchedMedia: action.payload,
                loading: false
            };
        case "DELETE_VIDEO":
            return {
                ...state,
                videos: state.videos.filter(video => video.id !== action.id)
            };
        case "ADD_AUDIO":
            return {
                ...state,
                currentMedia: action.payload,
                searchedMedia: action.payload,
                loading: false
            };
        case "ADD_MEDIA":
            let verified = false;
            if(action.payload[0] && action.payload[0].hasOwnProperty('clientchannelverified')){
                verified = parseInt(action.payload[0]['clientchannelverified']) !== 0
            }
            return {
                ...state,
                allMedia: action.payload,
                currentMedia: action.payload,
                searchedMedia: action.payload,
                loading: false,
                clientchannelverified: verified
            };
        case 'FETCHING_MEDIA':
            return {
                ...state,
                loading: true
            };
        case 'MEDIA_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null
            }
        case 'UPDATE_MEDIA_FINISH':
            return {
                ...state,
                allMedia: [...state.allMedia.map(media => {
                    if (media.mediaid === action.payload.mediaid) {
                        media.mediadescription = action.payload.mediadescription
                        media.mediatitle = action.payload.mediatitle
                        return media
                    }
                    return media
                })]
            };
        case "SEARCH_MEDIA": {
            let currentMediaCopy = [...state.searchedMedia];

            //currently will only search if description and title exist

            return {
                ...state,
                query:action.payload,
                currentMedia: action.payload === '' ?
                    state.searchedMedia :
                    currentMediaCopy.filter(media => {
                        if (media.mediatitle && media.mediadescription) {
                            console.log('media title', media.mediatitle)
                            return media.mediatitle.toLowerCase().includes(action.payload.toLowerCase()) ||
                                media.mediadescription.toLowerCase().includes(action.payload.toLowerCase())
                        }
                    })
            };
        }
        case "CLEAR_QUERY" : {
            return {
                ...state,
                query:""
            }
        }
        case "TOGGLE_REPUTATION_MODAL": {
            return {
                ...state,
                toggleReputationModal: !state.toggleReputationModal
            };
        }
        case 'SORT_MEDIA' : {
            return {
                ...state,
                allMedia: [...action.payload],
                currentMedia: [...action.payload],
                searchedMedia: [...action.payload],
            }
        }
        case 'TOGGLE_SINGLE_VIEW': {
            return {
                ...state,
                isSingleView: action.payload
            }
        }
        case "SET_COPY_SUCCESS": {
            return {
                ...state,
                copySuccess: action.payload
            }
        }
        default:
            return state;
    }
};
export default mediaReducer;
