import React from 'react';
import {formatTrimDuration} from "../utilityFunctions/formatTrimDuration";
import ReputationScore from "../ReputationScore/ReputationScore";

const MediaDisplayComponent = ({media,mediacoverurl}) =>{
    return (
        <div className="edit-media-relative-wrapper">
            <div className="edit-media-component-media-wrapper">
                <div className="edit-media-component-timeblock">
                    <div className="edit-media-component-timeclock">
                        {formatTrimDuration(media.mediaduration)}
                    </div>
                </div>
                <div className="edit-media-component-cover">
                    <img
                        src={
                            mediacoverurl
                        }
                        alt="Video"
                    />
                </div>
                <div className="edit-media-component-subbarurl-wrapper">
                    <img src={media.subbarurl} alt={""}/>
                </div>
            </div>
            <ReputationScore summaryBullets={media.summarybullet} viewCss={'rep-score-edit'}/>
        </div>
    )
}
export default MediaDisplayComponent;
