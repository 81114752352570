import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {mobileMenuLoginOn, processauthtoken, toggleReputationModal, logout, setNavShare, toggleEdit, unsetMetData,setCopySuccess} from "./actions";
import {toggleUserMenu} from './DesktopNavPopUp/actions'
import {socialShareCopyFunction} from './ModalComponent/socialShareCopyFunction'
import SocialShareComponent from './SocialShareComponent/SocialSchareComponent'
import socialNetworksArray from './SocialShareComponent/SocialNetworks'
import "./hamburger-icon.css";
import "./navbar.css";

import NavBarSearch from "./NavBarSearch";
import MobileFooter from "./MobileFooter/MobileFooter";
import MobileNavbar from "./MobileNavbar/MobileNavbar";
import Footer from "./Footer/Footer";
import DesktopNavPopUp from "./DesktopNavPopUp/DesktopNavPopUp";
import ReputationSummary from "./ReputationScore/ReputationSummary";
import VerifiedModal from "./VerifiedModal";
import WarningModal from './ModalComponent/WarningModal'
import YoutubeSearch from "./YoutubeSearch/YoutubeSearch";
import ChannelBanner from "./ChannelBanner/ChannelBanner";
import UserMenuComponent from "./UserMenuComponent/UserMenuComponent";
import DeskTopSocialShareModal from "./ModalComponent/DeskTopSocialShareModal";

processauthtoken(localStorage.getItem("authtoken"));

class App extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            userMenuToggled: false,
            verificationMenuToggled: false,
        };
    }

    componentDidMount() {
        console.log("Remote access testing")
        const {processauthtoken, loginToken, setNavShare} = this.props;
        const {checkMobileLogin} = this;
        //Check for webshare api for mobile
        if(navigator.share){
            // alert(true)
            setNavShare(true)
        }
        processauthtoken(localStorage.getItem("authtoken"));
        //Checks for login and mobile to prompt login
        checkMobileLogin(loginToken);
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            // alert("iOS is true")
            // IF iOS attmept to negate pinch to zoom functionality without stopping scroll
            // *Not 100% effective*
            document.addEventListener("touchstart", event => {
                if (event.touches.length > 1 || event.scale !== 1) {
                    console.log("zoom plz stahp");
                    event.preventDefault();
                    event.stopPropagation(); // maybe useless
                }
            }, {passive: false});
        }

        // document.addEventListener("gesturestart", function (e) {
        //     e.preventDefault();
        //     document.body.style.zoom = 0.99;
        // });
        //
        // document.addEventListener("gesturechange", function (e) {
        //     e.preventDefault();
        //
        //     document.body.style.zoom = 0.99;
        // });
        //
        // document.addEventListener("gestureend", function (e) {
        //     e.preventDefault();
        //     document.body.style.zoom = 1;
        // });
    }

    checkMobileLogin = (loginToken) => {
        const {mobileMenuLoginOn} = this.props;

        if (!loginToken && window.innerWidth < 900) {
            mobileMenuLoginOn();
        }
    };

    handleChannel = () => {
        const {processauthtoken, loginToken} = this.props;
        localStorage.setItem("clientid", "my");
        processauthtoken(loginToken)
    };

    toggleUserMenu = () => {
        const {toggleUserMenu} = this.props;
        toggleUserMenu();
    };

    showGrid = () => {
        const {children, mediaIsSet, edit} = this.props;
        let windowWidth = window.innerWidth;
        if (windowWidth < 834) {
            return (
                <div className="container-fluid">
                    {children}
                </div>
            )
        }
        if (windowWidth > 834) {
            return (
                <div className="container-fluid"
                     style={{
                         top: mediaIsSet || edit ? '8vh' : '2vh'
                     }}
                >
                    {children}
                </div>
            )
        }
    };

    menuIsToggled = () => {
        const {userMenuToggled} = this.props;
        //fixes the page to screen when modal open
        if (userMenuToggled && window.innerWidth <= 414) {
            return 'fixed'
        }
    };

    toggleVerificationModal = () => {
        this.setState({
            verificationMenuToggled: !this.state.verificationMenuToggled
        })
    };

    handleEmailIcon = () => {
        const {shareurl, sharemethod} = this.props;
        if (navigator.share) {
            return (
                <div className="social-media-icon-wrapper"
                     onClick={() => this.handleEmailSharing(shareurl)}
                >
                    <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>
                </div>
            )
        }
        return (
            <div className="social-media-icon-wrapper"

            >
                <a href="mailto:" target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>
                </a>
            </div>
        )
    };

    handleEmailSharing = shareurl => {
        navigator.share({
            url: shareurl
        })
            .then(() => {
                console.log("Thanks for sharing")
            })
            .catch(error => console.error(error.message))
    };

    handleModalToggle = () => {
        const {sharemethod} = this.props;
        return sharemethod === 'private' ? "modal" : "none";
    };

    handlePrivate = (socialNetwork) => {
        const {sharemethod} = this.props;
        return sharemethod === 'private' ? "" : socialNetwork;
    };

    render() {
        const {
            userMenuToggled,
            mediaIsSet,
            clientchannel,
            toggleReputationModal,
            mediakey,
            shareurl,
            toggleModal,
            mediatype,
            embedded,
            isSingleView,
            edit,
            loginToken,
            clientchannelverified,
            copySuccess,
            setCopySuccess
        } = this.props;
        const {verificationMenuToggled} = this.state;
        const {
            toggleUserMenu, showGrid, menuIsToggled, handlePrivate,
            toggleVerificationModal, handleEmailIcon, handleEmailToggle, handleModalToggle, handleEditNavigation
        } = this;

        let navMenuOptionsLoggedIn = [
            {
                icon: "/usericons/staricon.png",
                displayTitle: "Your Channel (public)",
            },
            {
                icon: "/usericons/pencilicon.png",
                displayTitle: "Your Channel (private)",
                break: true
            },
            {
                icon: "/usericons/hammerwrenchicon.png",
                displayTitle: "Settings",
            },
            {
                icon: "/usericons/unlockedicon.png",
                displayTitle: "Sign out",
            },
            {
                icon: "/usericons/help.png",
                displayTitle: "Help",
            },
            {
                icon: "/usericons/sendfeedbackicon.png",
                displayTitle: "Send feedback",
            }
        ]

        let navMenuOptionsLoggedOut = [
            {
                icon: "/usericons/staricon.png",
                displayTitle: "Register account",
                break: true
            },
            {
                icon: "/usericons/pencilicon.png",
                displayTitle: "Sign in",
                break: false
            },

            {
                icon: "/usericons/forgotpassword.png",
                displayTitle: "Forgot password",
                break: true
            },
            {
                icon: "/usericons/help.png",
                displayTitle: "Help",
                break: false
            },
            {
                icon: "/usericons/sendfeedbackicon.png",
                displayTitle: "Send feedback",
                break: false
            },
        ]

        return (
            <div className="App"
                 style={{
                     position: menuIsToggled(),
                     width: '100%',
                    maxWidth: '100%',
                    // overflowX : 'hidden'
                 }}
            >
                <div className="navbar-top-fixed">
                    <div className="navbar-main-wrapper">
                        <div className="navbar-main-content">
                            <div className="navbar-main-logowrapper">
                                <div className="logo-image-wrapper">
                                    <img src={`${process.env.PUBLIC_URL}/Logo-VideoLock.png`} alt=""/>
                                </div>
                                <div className="nav-bar-underline-wrapper">
                                    <div className="nav-bar-logo-text">
                                        <a href='https://videolock.com'>
                                            VideoLock.com
                                        </a>
                                    </div>
                                    <div className="underline-bar">
                                    </div>
                                </div>
                                {
                                    clientchannel &&
                                    <React.Fragment>
                                        <div className="slash-placeholder">/</div>
                                        <div className="nav-bar-underline-wrapper">
                                            <Link to={`/${clientchannel ? clientchannel : "login"}`}>
                                                <div className="user-name">{clientchannel}</div>
                                            </Link>
                                            <div className="underline-bar">
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="slash-placeholder">/
                                    {/*<i className="fa fa-share" aria-hidden="true"></i>*/}
                                </div>
                                {
                                    mediakey &&
                                    <React.Fragment>
                                        <div className="nav-bar-underline-wrapper">
                                            <div className="user-name">{mediatype}</div>
                                            <div className="underline-bar">
                                            </div>
                                        </div>
                                        <div className="slash-placeholder">/</div>

                                        <div className="nav-bar-underline-wrapper">
                                            <div className="user-name">{mediakey}</div>
                                            <div className="underline-bar">
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {/*{(clientchannelverified && !mediaIsSet) &&*/}
                                {/*    <div className="verified-media-icon-wrapper">*/}
                                {/*        {verificationMenuToggled*/}
                                {/*            ?*/}
                                {/*            <VerifiedModal/> : null*/}
                                {/*        }*/}
                                {/*        <div>*/}
                                {/*            <img*/}
                                {/*                src={`${process.env.PUBLIC_URL}/VerifiedIcon.png`}*/}
                                {/*                alt=""*/}
                                {/*                className="verified-media-icon-wrapper-image"*/}
                                {/*                onMouseOver={() => toggleVerificationModal()}*/}
                                {/*                onMouseLeave={() => toggleVerificationModal()}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {mediaIsSet &&
                                <div className="social-media-top-wrapper" data-toggle={handleModalToggle()}
                                    data-target="#warningModal">
                                    {handleEmailIcon()}
                                    {socialNetworksArray.map(({socialNetwork, icon}) =>
                                        <SocialShareComponent
                                            socialNetwork={handlePrivate(socialNetwork)}
                                            shareurl={shareurl}
                                            icon={icon}
                                            key={icon}
                                            cssStyle="social-media-icon-wrapper"
                                        />
                                    )}
                                    {/*OLD ICON CODE-----------------------------------*/}
                                    {/*<div className="social-media-icon-wrapper">*/}
                                    {/*Facebook sharing, share url is on mediasingle reducer acquired from media object*/}
                                    {/*<a href={`https://www.facebook.com/sharer.php?u=${shareurl}`} target="_blank">
                                            <img src={`${process.env.PUBLIC_URL}/Icon-Facebook.png`} alt=""/>
                                          </a>
                                        </div>*/}
                                    {/*<div className="social-media-icon-wrapper">*/}
                                    {/*    <img src={`${process.env.PUBLIC_URL}/Icon-Instragram.png`} alt=""/>*/}
                                    {/*</div>*/}
                                    {/*TESTING TWITTER SHARE URL*/}
                                    {/*<div className="social-media-icon-wrapper">
                                          <a target="_blank"  href={`https://twitter.com/intent/tweet?url=${shareurl}`}>
                                           <img src={`${process.env.PUBLIC_URL}/Icon-Twitter.png`} alt=""/>
                                          </a>
                                        </div>*/}
                                    {/*<div className="social-media-icon-wrapper">
                                            <a target="_blank" href={`https://wa.me/?text=${shareurl}`}>
                                                <img src={`${process.env.PUBLIC_URL}/Icon-WhatsApp.png`} alt="WhatsApp Icon" />
                                            </a>
                                        </div>*/}
                                    {/*<div className="social-media-icon-wrapper">*/}
                                    {/*    <img src={`${process.env.PUBLIC_URL}/Icon-Email.png`} alt=""/>*/}
                                    {/*</div>*/}
                                    {/*OLD ICON CODE-----------------------------------*/}
                                </div>
                                }
                            </div>
        {
            <div className="width-set-search">
                <YoutubeSearch/>
            </div>
        }

                            <div className="navbar-main-content-right">
                                <div
                                    className="desktop-nav-user-icon-wrapper"
                                    onClick={() => toggleUserMenu()}
                                >
                                    <img src={`${process.env.PUBLIC_URL}/blue_login_icon.png`} alt=""/>
                                </div>
                                {/*<div className="navbar-main-options-wrapper">*/}
                                {/*    <ul>*/}
                                {/*        {*/}
                                {/*          loginToken &&*/}
                                {/*          <li><Link onClick ={this.handleChannel}to="/my"> My Media </Link> |</li>*/}
                                {/*        }*/}
                                {/*        <li><a target="_blank" href="https://videolock.com/deeptruth/"> &nbsp;About</a></li>*/}
                                {/*    </ul>*/}
                                {/*<div className="navbar-main-username">{this.props.email}</div>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                    {/*{window.innerWidth > 900 && <NavBarSearch/>}*/}
                </div>
                {
                    userMenuToggled &&
                    // <DesktopNavPopUp loginToken={this.props.loginToken}/>
                    <UserMenuComponent
                        mobileMenuToggle={toggleUserMenu}
                        notLoggedIn={loginToken === undefined || loginToken === "" || loginToken === null}
                        menuOptions={
                            loginToken === undefined || loginToken === "" || loginToken === null ?
                                navMenuOptionsLoggedOut
                                :
                                navMenuOptionsLoggedIn}
                    />
                }

                <MobileNavbar/>

                {mediaIsSet || edit ?
                    ''
                    : <ChannelBanner/>
                }
                {showGrid()}
                {/*{*/}
                {/*    mediaIsSet ?*/}
                {/*        '' : <Footer/>*/}
                {/*}*/}
                {/*{*/}
                {/*    isSingleView || mediaIsSet || embedded ?*/}
                {/*        '' : <MobileFooter/>*/}
                {/*}*/}
                {
                    toggleModal &&
                    <ReputationSummary
                        toggleReputationModal={toggleReputationModal}
                        mobile={window.innerWidth < 834}
                    />
                }
                <DeskTopSocialShareModal
                    copyFunction={socialShareCopyFunction}
                    shareurl={shareurl}
                    copySuccess={copySuccess}
                    inputRef={this.inputRef}
                    socialNetworksArray={socialNetworksArray}
                    toggleMenu={setCopySuccess}
                    setCopySuccess={setCopySuccess}
                />
                <WarningModal
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        processauthtoken: authtoken => {
            dispatch(processauthtoken(authtoken));
        },
        mobileMenuLoginOn: () => {
            dispatch(mobileMenuLoginOn())
        },
        toggleReputationModal: () => {
            dispatch(toggleReputationModal())
        },
        toggleUserMenu: () => {
            dispatch(toggleUserMenu())
        },
        setNavShare: value =>{
            dispatch(setNavShare(value))
        },
        logout: () => {
            dispatch(logout());
        },
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        },
        unsetMetData: () =>{
            dispatch(unsetMetData())
        },
        setCopySuccess: successvalue => {
            dispatch(setCopySuccess(successvalue))
        }
    };
};

const mapStateToProps = state => {
    return {
        clientchannel: state.loginReducer.authentication.clientchannel,
        clientid: state.loginReducer.authentication.clientid,
        loginToken: state.loginReducer.authentication.loginToken,
        drawerOpen: state.mediaSingleReducer.drawerOpen,
        videos: state.MediaReducer.videos,
        images: state.MediaReducer.images,
        audio: state.MediaReducer.audio,
        embedded: state.loginReducer.embed,
        userMenuToggled: state.desktopNavReducer.userMenuToggled,
        toggleModal: state.MediaReducer.toggleReputationModal,
        mediaType: state.navReducer.filter.filterMediaType,
        filter: state.navReducer.filter,
        edit: state.navReducer.edit,
        isSingleView: state.MediaReducer.isSingleView,
        mediaIsSet: state.mediaSingleReducer.mediaIsSet,
        mediakey: state.mediaSingleReducer.mediaKey,
        mediatype: state.mediaSingleReducer.mediaType,
        shareurl: state.mediaSingleReducer.shareurl,
        clientchannelverified:state.MediaReducer.clientchannelverified,
        sharemethod: state.mediaSingleReducer.sharemethod,
        sharemethod: state.mediaSingleReducer.sharemethod,
        copySuccess:state.MediaReducer.copySuccess
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
