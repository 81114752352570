import React from 'react'
import {connect} from 'react-redux'
import './halfviewcontainer.css'
import {withRouter} from 'react-router-dom'
import HalfViewDrawer from "./HalfViewDrawer";
import Encryption from "../DrawerComponents/Encryption/Encryption";
import ReputationScore from "../ReputationScore/ReputationScore";
import {scaleHalfDrawer, toggleHalfView} from "../MediaSingle/actions";

class HalfViewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: true,
            meta: false,
            encryption: false
        };
    }

    componentDidMount() {
    }

    handleTab = (selected) => {
        switch (selected) {
            case 'video':
                this.setState({
                    video: this.state.video ? this.state.video : !this.state.video,
                    meta: false,
                    encryption: false
                });
                break;
            case 'meta':
                this.setState({
                    video: false,
                    meta: this.state.meta ? this.state.meta : !this.state.meta,
                    encryption: false
                });
                break;
            case 'encryption':
                this.setState({
                    video: false,
                    meta: false,
                    encryption: this.state.encryption ? this.state.encryption : !this.state.encryption
                });
                break;
            default :
                return null
        }
    };

    getMediaData = (media, dataType) => {
        for (let k in media) {
            if (k.toLowerCase().indexOf(dataType.toLowerCase()) !== -1) {
                if (dataType === 'description' && media[k] === null) {
                    return 'No Description'
                }
                if (dataType === 'title' && media[k] === null) {
                    return 'No Title'
                } else {
                    return media[k];
                }
            }
        }
        return null;
    };

    capitalizeFirstLetter = (string) => {
        if (string)
            return string.charAt(0).toUpperCase() + string.slice(1);
    };

    _onMouseMove(e) {
        const {scaleHalfDrawer} = this.props;

        let direction = this.state.touchYValue > e.touches[0]['clientY'] ? 'up' : 'down';
        this.setState({
            touchYValue: e.touches[0]['clientY'],
            direction: direction
        });

        let value = this.state.touchYValue;
        if (value > 213 && value < 550) {
            scaleHalfDrawer({
                value: value,
                direction: direction
            })
        }
    }

    render() {
        const {video, meta, encryption} = this.state;
        const {media, toggleHalfView, frameError} = this.props;
        const {capitalizeFirstLetter, getMediaData} = this;

        console.log('render me how many',  window.innerHeight)

        return (
            <div style={this.props.style} className="halfview-container">

                <div
                    className="halfview-fullscreen-toggle"
                    onClick={() => toggleHalfView()}>
                    <img src={`${process.env.PUBLIC_URL}/toggle.png`} alt=""/>
                </div>

                <div className="scale-wrapper"
                     style={{bottom: this.state.direction === 'up' ? this.props.drawerHeightPercentage + "px" : this.props.drawerHeightPercentage + "px"}}
                     onTouchMove={this._onMouseMove.bind(this)}
                >
                    <div>
                        <div className="up-triangle"></div>
                        <div className="down-triangle"></div>
                    </div>
                </div>

                <ReputationScore viewCss={'halfview-percentage-wrapper'} summaryBullets={media.summarybullet}/>

                <div className="halfview-toggletab-wrapper">
                    <div
                        className={video ? "active tab-end-left" : "tab-end-left"}
                        onClick={() => this.handleTab('video')}
                    >
                        {capitalizeFirstLetter(media.mediatype)}
                    </div>
                    <div
                        className={meta ? "active tab-middle" : "tab-middle"}
                        onClick={() => this.handleTab('meta')}
                    >
                        Meta
                    </div>
                    <div
                        className={encryption ? "active tab-end-right" : "tab-end-right"}
                        onClick={() => this.handleTab('encryption')}
                    >
                        Blockchain
                    </div>
                </div>
                {video &&
                <div className="halfview-video-data-wrapper"
                     style={{height: (window.innerHeight - this.state.touchYValue) - 100}}
                >
                    <h5>{getMediaData(media, 'title')}</h5>
                    <div className="half-view-movie-description">
                        {getMediaData(media, 'description')}
                    </div>
                    <div className="half-view-views-date-wrapper">
                        <div>{media.videoviewcount} Views</div>
                        <div>|</div>
                        <div>{getMediaData(media, 'createddate')}</div>
                    </div>
                    {
                        frameError &&
                        <div style={{color:"red"}}>{`[${frameError}]`}</div>
                    }
                </div>
                }
                {
                    encryption &&
                    <div className="encryption-wrapper drawer-wrapper"
                         style={{height: (window.innerHeight - this.state.touchYValue) - 100}}
                    >
                        <Encryption
                            encryptionData={this.props.encryptionData}
                            lightView={false}
                        />
                    </div>
                }
                <HalfViewDrawer
                    currentFrame={this.props.currentFrame}
                    currentsequence={this.props.currentsequence}
                    duration={this.props.duration}
                    buttonUpdateDisplay={this.props.buttonUpdateDisplay}
                    height={(window.innerHeight - this.state.touchYValue) - 100}
                    // mediaHalfView={this.props.mediaHalfView}-
                    mediaHalfView={false}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        encryptionData: state.mediaSingleReducer.encryptionDataHalfView,
        subbarurl: state.mediaSingleReducer.currentMedia.subbarurl,
        media: state.mediaSingleReducer.currentMedia,
        frameError:state.mediaSingleReducer.frameError,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        scaleHalfDrawer: (payload) => {
            dispatch(scaleHalfDrawer(payload));
        },
        toggleHalfView: () => {
            dispatch(toggleHalfView());
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HalfViewContainer));
