import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom'
import {searchMedia, mediaFilter, sortMedia, toggleEdit} from '../actions'
import "./YoutubeSearch.css"

class YoutubeSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleQuery = (e) => {
        e.preventDefault();
        const {searchMedia} = this.props;
        searchMedia(e.target.value)
    };

    generatePlaceholder = () => {
        const {clientchannel} = this.props;

        let placeholderString = '';

        if (clientchannel) {
            placeholderString = 'Search in the ' + clientchannel + ' channel';
            return placeholderString
        } else {
            return 'Search...'
        }
    };

    // going have to add local save state

    render() {
        const {isSingleView, clientchannel, query, toggleEdit, edit} = this.props;
        const {generatePlaceholder} = this;
        const backGround = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/icon-search.png)`
        };

        return (
            <div className="youtube-search-container">
                <form className="search-youtube-case">
                    <input
                        style={backGround}
                        type="text"
                        className="youtube-search"
                        placeholder={generatePlaceholder()}
                        onChange={(e) => this.handleQuery(e)}
                        value={query}
                    />
                    <div
                        className="search-button-youtube"
                    >
                        <Link to={`/${clientchannel ? clientchannel : "login"}`} onClick={edit ? () => toggleEdit(false): null}>
                        <div
                            className="youtube-search-icon-wrapper"
                        >
                            <img src={`${process.env.PUBLIC_URL}/icon-search.png`} alt=""/>
                        </div>
                        </Link>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        filter: state.navReducer.filter,
        clientid: state.loginReducer.authentication.clientid,
        clientchannel: state.loginReducer.authentication.clientchannel,
        loginToken: state.loginReducer.authentication.loginToken,
        allMedia: state.MediaReducer.allMedia,
        isSingleView: state.MediaReducer.isSingleView,
        query: state.MediaReducer.query,
        edit: state.navReducer.edit
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchMedia: (query) => {
            dispatch(searchMedia(query))
        },
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        }
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YoutubeSearch));
