import React from "react";
import "./editInput.css";

const handleCharCount = (text) => {
    return text ? text.length : 0
}

const EditInputComponent = ({
                                stateFormFields,
                                changeField,
                                name,
                                placeholder,
                                maxLength,
                                setHeight
                            }) => {
    return (
        <div className="custom-input-wrapper"
             style={{
                 height: `${setHeight}vh`
             }}
        >
            <span className="placeholder-span">{placeholder}</span>
            <textarea
                maxLength={maxLength}
                name={name}
                key={name}
                style={{
                    height:`${setHeight - 1}vh`
                }}
                value={stateFormFields ? stateFormFields : ""}
                onChange={value => changeField(name, value.target.value)}
            />
            <span className="char-count-span">{`${handleCharCount(stateFormFields)}/${maxLength}`}</span>
        </div>
    )
}

export default EditInputComponent
