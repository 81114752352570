import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import moment from "moment";
import './encryption.css'

class Encryption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const {
            creationDate,
            encryptionData: {
                devicetime,
                mediaframedevicemetahashvalue,
                mediaframetransactionid,
                hashmethod,
                hashvalue
            },
            sliderValue,
            mediakey
        } = this.props;
        const date = moment(creationDate).format("MM/DD/YY");
        let lightMode = 'black';
        let darkMode = 'white';
        
        return (
            <div className="encryption-container"
                 style={{background: !this.props.lightView ? `rgb(157,64,95,${sliderValue * .01})` : 'white'}}>
                <div className="component-header-wrapper">
                    <div
                        className="component-title"
                        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                        BLOCKCHAIN
                    </div>
                    <div
                        className="encryption-component-icon">
                        <img src={`${process.env.PUBLIC_URL}/fingerprint_white_96x96.png`} alt=""/>
                    </div>
                </div>
                <div className="encryption-grid">
                    <div className="encryption-data-wrapper">
                        <div
                            className="encryption-label"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Date:
                        </div>
                        <div
                            className="encryption-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {date}
                        </div>
                    </div>
                    <div className="encryption-data-wrapper">
                        <div
                            className="encryption-label"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Time:
                        </div>
                        <div className="encryption-data"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {devicetime}
                        </div>
                    </div>
                    <div className="encryption-data-wrapper">
                        <div
                            className="encryption-label"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Blockchain ID:
                        </div>
                        <div
                            className="encryption-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {mediaframetransactionid}
                        </div>
                    </div>
                    <div className="encryption-data-wrapper">
                        <div
                            className="encryption-label"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Video Key:
                        </div>
                        <div
                            className="encryption-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {mediakey}
                        </div>
                    </div>
                    {/*<div className="encryption-data-wrapper">*/}
                    {/*    <div*/}
                    {/*        className="encryption-label"*/}
                    {/*        style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                    {/*        Block ID:*/}
                    {/*    </div>*/}
                    {/*    <div className="encryption-data"></div>*/}
                    {/*</div>*/}
                    <div className="encryption-data-wrapper">
                        <div
                            className="encryption-label"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Hash Formula:
                        </div>
                        <div
                            className="encryption-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {hashmethod}
                        </div>
                    </div>
                    <div className="encryption-data-wrapper">
                        <div className="encryption-label"
                             style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Media Hash:
                        </div>
                        <div
                            className="encryption-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {hashvalue}
                        </div>
                    </div>
                    <div className="encryption-data-wrapper">
                        <div
                            className="encryption-label"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            Meta Hash:
                        </div>
                        <div
                            className="encryption-data"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            {mediaframedevicemetahashvalue}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        creationDate: state.mediaSingleReducer.currentMedia.videocreateddate,
        sliderValue: state.mediaSingleReducer.sliderValue,
        mediakey:state.mediaSingleReducer.mediaKey
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Encryption))
