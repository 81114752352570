export const sortOptions = (props) => {
    const {loginToken,clientchannel, homechannel, mediasharemethod, edit} = props
    const sharemethodisprivate = mediasharemethod === "private";
    console.log(mediasharemethod)
    const options =[
        {
            icon: "/ui-update-search.png",
            displayTitle: "Watch",
            access:"public",
            click:() => handleSingleMediaNavigation(props)
        },
        {
            icon: "/sorticons/uparrow.png",
            displayTitle: "Share",
            dataToggle: "modal",
            dataTarget: handleShareDataToggle(sharemethodisprivate),
            access:"public",
            click:() => props.setShareUrl(props.shareurl, sharemethodisprivate)

        },
        {
            icon: "/usericons/pencilicon.png",
            displayTitle: "Edit",
            className: "edit-icon-style",
            access:"authorized",
            click:() => handleEditNavigation(props)
        }
    ]
    if(loginToken && clientchannel === homechannel){
        return edit ? options.filter(option => option.access ==="public"): options
    }
    return options.filter(option => option.access ==="public")
}

const handleEditNavigation = props =>{
    const {history, watchurl, toggleEdit,unsetMetData, edit } = props;
    toggleEdit(true);
    unsetMetData();
    history.push(watchurl)
}

const handleSingleMediaNavigation = props =>{
    const {toggleEdit, edit,history, watchurl} = props;
    if(edit){
        toggleEdit(false)
        return
    }
    history.push(watchurl)
}

const handleShareDataToggle = sharemethodisprivate =>{
    const isWebView = navigator.userAgent.includes('wv')
    /*
        If navigator share is available the modal will not be toggled and instead the
        native menu will be invoked
     */
    if(navigator.share || isWebView){
        return "";
    }
    return !sharemethodisprivate ? "#exampleModalCenter" : "#warningModal"
}
