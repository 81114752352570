import React from 'react'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import "./desktopnavpopup.css"
import LoginPanel from "./LoginPanel";
import PasswordPanel from "./PasswordPanel";
import {logout, updateClientChannel} from "../actions";
import {toggleUserMenu, clearMessage} from './actions'
import ProfileOptionsPanel from "./ProfileOptionsPanel";
import ChangePasswordPanel from "./ChangePasswordPanel";
import CreateAccountPanel from "./CreateAccountPanel";
import VerifyPanel from "./VerifyPanel";
import ConfirmPassReset from "./ConfirmPassReset";

class DesktopNavPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedOnHome: true,
            homeOn: true,
            loginOn: false,
            createAccountOn: false,
            resetPasswordOn: false,
            confirmationCodeOn: false,
            verifyOn: false,
            confirmPassResetOn: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {homeOn} = this.state;
        if (prevState.homeOn !== homeOn) {
            this.props.clearMessage();
        }
    }

    //what about a function that loops through the state and toggles everything but the choice false

    triggerPanel = (choice) => {
        switch (choice) {
            case 'Sign In' :
                this.setState({
                    loginOn: true,
                    homeOn: false,
                    createAccountOn: false,
                    resetPasswordOn: false,
                    confirmationCodeOn: false,
                    verifyOn: false
                });
                break;
            case 'Home' :
                this.setState({
                    loginOn: false,
                    loggedOnHome: true,
                    homeOn: true,
                    createAccountOn: false,
                    resetPasswordOn: false,
                    confirmationCodeOn: false,
                    confirmPassResetOn: false,
                    changePasswordOn: false,
                    verifyOn: false,
                    profileOptionsOn: false
                });
                break;
            case 'Reset' :
                this.setState({
                    loginOn: false,
                    homeOn: false,
                    createAccountOn: false,
                    resetPasswordOn: true,
                    confirmationCodeOn: false
                });
                break;
            case 'Edit Profile' :
                this.setState({
                    loggedOnHome: false,
                    homeOn: false,
                    changePasswordOn: false,
                    profileOptionsOn: true
                })
                break;
            case 'Change Password' :
                this.setState({
                    profileOptionsOn: false,
                    changePasswordOn: true,
                    homeOn: false,
                    loggedOnHome: false,
                })
                break;
            case 'Create Account' :
                this.setState({
                    createAccountOn: true,
                    loginOn: false,
                    homeOn: false,
                    loggedOnHome: false
                })
                break;
            case 'Verify' :
                this.setState({
                    createAccountOn: false,
                    loginOn: false,
                    homeOn: false,
                    loggedOnHome: false,
                    resetPasswordOn: false,
                    verifyOn: true,
                })
                break;
            case 'ConfirmPassReset':
                this.setState({
                    createAccountOn: false,
                    loginOn: false,
                    homeOn: false,
                    loggedOnHome: false,
                    resetPasswordOn: false,
                    verifyOn: false,
                    confirmPassResetOn: true
                })
                break;
            default:
                break;
        }
    };

    handleButtons = () => {
        const url = window.location.origin
        //EMBED AND WEB REQUIRE DIFFERENT BUTTON FUNCTIONALITY
        //THIS WILL RETURN THE CORRECT JSX DEPENDING ON EMBED BEING TRUE
        const {embed} = this.props
        const embedButtonStyle = {
            color: 'white',
            background: '#02bbef',
            fontFamily: ['Comfortaa', 'cursive'],
            border: 'none',
            cursor: 'pointer'
        }

        if (embed) {
            //Using form submit to force page refresha and server call
            return (
                <form action={`${url}/logout`} method="get" style={{"marginBottom": "1rem"}}
                      className="navpop-up-blue-btn">
                    <button type='submit' style={embedButtonStyle}>Log Off</button>
                </form>
            )
        } else {
            return (
                <div className="navpop-up-blue-btn"
                     onClick={this.handleLogout}
                >
                    Log Off
                </div>

            )
        }
    };

    handleLogout = e => {
        const {logout, history} = this.props;
        e.preventDefault();

        logout();
        history.push('/login')
    };

    handleHomeChannel = () => {
        //return login state and local storage to authorized user channel values
        const homeChannel = localStorage.getItem("homechannel");
        let homeId = localStorage.getItem("homeid");
        homeId = homeId ? homeId : "0"; // ensure home id is not null
        const {clientChannel, updateClientChannel, toggleUserMenu} = this.props;
        if (homeChannel !== clientChannel) {
            updateClientChannel(homeChannel, homeId);
        }
        toggleUserMenu();
    };

    render() {
        const homeChannel = localStorage.getItem("homechannel");
        const {triggerPanel} = this;
        const {
            changePasswordOn,
            // confirmationCodeOn,
            confirmPassResetOn,
            createAccountOn,
            homeOn,
            loginOn,
            loggedOnHome,
            profileOptionsOn,
            resetPasswordOn,
            verifyOn,
        } = this.state;
        const {loginToken} = this.props;

        return (
            <div>
                <div className="user-menu-wrapper">
                    <div className="relative-wrapper">
                        {
                            //Ternary deciphers users login status then displays
                            //the correct panel group options
                        }
                        {homeOn
                            ?
                            <div className="logo-logotype-container">
                                <div className="center-logo">
                                    <div className="videolock-logo-blue-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/VideoLock.comLogo.png`} alt=""/>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                        {
                            loginToken === undefined || loginToken === "" || loginToken === null ?
                                <div>
                                    {homeOn &&
                                    <div>
                                        <div className="button-container-center">
                                            <div className="navpop-up-blue-btn-create-account"
                                                 onClick={(e) => triggerPanel(e.target.textContent)}
                                            >
                                                Create Account
                                            </div>
                                        </div>
                                        <div className="login-questions-wrapper">
                                            <div>
                                                <div>Already have an account? <span
                                                    onClick={(e) => triggerPanel(e.target.textContent)}
                                                    className="login-questions-span">Sign In</span></div>
                                                {/*<div>Have a confirmation number? <span*/}
                                                {/*    onClick={(e) => triggerPanel(e.target.textContent)}*/}
                                                {/*    className="login-questions-span">Verify</span></div>*/}
                                                <div>Forgot your password?
                                                    <span
                                                        className="login-questions-span"
                                                        onClick={(e) => triggerPanel(e.target.textContent)}>
                                                 Reset
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {
                                        loginOn ? <LoginPanel back={triggerPanel}/> : ''
                                    }
                                    {
                                        resetPasswordOn ? <PasswordPanel back={triggerPanel}/> : ''
                                    }
                                    {
                                        createAccountOn &&
                                        <CreateAccountPanel back={triggerPanel}/>
                                    }
                                    {
                                        verifyOn &&
                                        <VerifyPanel back={triggerPanel}/>
                                    }
                                    {
                                        confirmPassResetOn &&
                                        <ConfirmPassReset back={triggerPanel}/>
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        loggedOnHome &&
                                        <div className="button-container-center">
                                            <div className="navpop-up-blue-btn"
                                                 onClick={(e) => triggerPanel(e.target.textContent)}
                                            >
                                                Change Password
                                            </div>
                                            {this.handleButtons()}
                                            <div className="navpop-up-blue-btn">
                                                <Link to={`/${homeChannel}`} onClick={this.handleHomeChannel}>
                                                    My Channel
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                    {
                                        profileOptionsOn &&
                                        <ProfileOptionsPanel back={triggerPanel} logout={this.handleLogout}/>
                                    }
                                    {
                                        changePasswordOn &&
                                        <ChangePasswordPanel back={triggerPanel}/>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        embed: state.loginReducer.embed
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logout());
        },
        updateClientChannel: (channel, id) => {
            dispatch(updateClientChannel(channel, id))
        },
        toggleUserMenu: () => {
            dispatch(toggleUserMenu())
        },
        clearMessage: () => {
            dispatch(clearMessage())
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopNavPopUp))
