import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {toggleEdit} from "../actions/index";
import "./PrivateMediaComponent.css";
import moment from "moment";
import ReputationScore from "../ReputationScore/ReputationScore";
import EditShareModal from "../EditShareModal/EditShareModal";

class MobilePrivateMediaComponent extends React.Component {
    componentDidMount() {
    }

    handleChannel = () => {
        const {clientchannel} = this.props
        if (clientchannel) {
            return `/${clientchannel}`
        }
        return ''
    };

    formatDate = date => {
        if (date !== null) {
            let dateSplit = date.split(" ");
            return (
                moment(dateSplit[0]).format("MM/DD/YY") +
                " " +
                moment(dateSplit[1], "HH:mm:ss").format("hh:mm A")

            );
        }
    };

    shortenLength = (str, type) => {
        let limit = type === "title" ? 40 : 70;
        if (str) {
            if (str.length > limit) {
                let shortenedDesc = str.substring(0, limit);
                return shortenedDesc + ' ...';
            } else {
                return str
            }
        }
    };

    addDefaultSrc = (ev) => {
        ev.target.src = `${process.env.PUBLIC_URL}/videoCoverTemp.png`
    };

    mediaShareMethod = (visibility) => {
        switch (visibility) {
            case 'private' :
                return (
                    `${process.env.PUBLIC_URL}/visibilityicons/privateicon.png`
                )
            case 'public' :
                return (
                    `${process.env.PUBLIC_URL}/visibilityicons/publicicon.png`
                )
            case 'unlisted' :
                return (
                    `${process.env.PUBLIC_URL}/visibilityicons/unlistedicon.png`
                )
        }
    }


    render() {
        const {media, toggleEdit, clientchannel} = this.props;
        const {formatDate, shortenLength, mediaShareMethod} = this;

        return (
            <div>
                <div className="mobile-private-container">
                    <div>
                        <div className="media-image-wrapper">
                            <Link
                                to={`${this.handleChannel()}/${media.mediatype[0]}/${
                                    media.mediakey
                                }`}
                                onClick={this.handleMediaToken}
                            >
                                <div className="left-media-image">
                                    <div className="mobile-private-media-wrapper">
                                        <img
                                            onError={this.addDefaultSrc}
                                            className="private-media-cover-image"
                                            src={
                                                media.mediacoverurl
                                                    ? media.mediacoverurl
                                                    : `${process.env.PUBLIC_URL}/videoCoverTemp.png`
                                            }
                                            alt="Video"
                                        />
                                        {media.mediatype !== 'image' &&
                                        <div className="list-media-component-timeclock">
                                            {media.mediaduration}
                                        </div>
                                        }
                                        {media.subbarurl &&
                                        <div className="list-subbar-wrapper">
                                            <img className="list-bar-img" src={media.subbarurl} alt=""/>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="center-content-wrapper">
                        <div className='mobile-listview-title-wrapper'>
                            {this.props.media.mediatitle ? shortenLength(this.props.media.mediatitle, "title") : 'No Title'}
                        </div>
                        <div className="views-vis-container">
                            <div className="private-visibility-wrapper"
                                 style={{
                                     marginRight: '10px'
                                 }}
                            >
                                <div className="visibility-image-wrapper">
                                    <img
                                        style={{
                                            marginBottom: '6px',
                                        }}
                                        src={`${process.env.PUBLIC_URL}/editicons/viewsicon.png`} alt=""/>
                                </div>
                                <div className="mobile-small-media-text ">
                                    <div>{media.mediaviewcount} views</div>
                                </div>
                            </div>
                            <div className="private-visibility-wrapper">
                                <div className="visibility-image-wrapper">
                                    <img src={mediaShareMethod(media.mediasharemethod)} alt=""/>
                                </div>
                                <div className="mobile-small-media-text ">
                                    {media.mediasharemethod}
                                </div>
                            </div>
                        </div>
                        <div className="mobile-small-media-text">Recorded {formatDate(media.mediacreateddate)}</div>
                        <div className="mobile-small-media-text">Uploaded {formatDate(media.mediapublisheddate)}</div>
                    </div>
                    <div className="private-edit-icon">
                        {/*<Link*/}
                        {/*    to={`${this.handleChannel()}/${media.mediatype[0]}/${*/}
                        {/*        media.mediakey*/}
                        {/*    }`}*/}
                        {/*    onClick={() =>toggleEdit(true)}*/}
                        {/*>*/}
                        {/*    <i className="fa fa-ellipsis-v fa-2x edit-ellipsis" aria-hidden="true"*/}
                        {/*       style={{*/}
                        {/*           color: 'black',*/}
                        {/*           fontSize: '18px'*/}
                        {/*       }}> </i>*/}
                        {/*</Link>*/}
                        <EditShareModal
                            watchurl={`/${clientchannel}/${media.mediatype[0] }/${media.mediakey}`}
                            shareurl={media.mediasocialshareurl}
                            mediasharemethod={media.mediasharemethod}
                            iconstyle={{color:"darkgrey",fontSize:'18px'}}
                            containerStyle={{justifyContent: "flex-end"}}
                        />
                    </div>
                </div>
                <div>
                    <ReputationScore summaryBullets={media.summarybullet} viewCss={'reputation-list-view'}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        params: params,
        loginToken: state.loginReducer.authentication.loginToken,
        clientchannel: state.loginReducer.authentication.clientchannel
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MobilePrivateMediaComponent)
);
