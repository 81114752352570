const initialState = {
  authentication: {},
  embed:null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROCESS_LOGIN":
      return {
        ...state,
        authentication: action.payload
      };
      case "SET_EMBED":
        return {
          ...state,
          embed:action.payload
        }
      case "UPDATE_CHANNEL":
        return {
          ...state,
          authentication: {...state.authentication,...action.payload}
        }
    case "UPDATE_CLIENTCHANNEL":
      return {
        ...state,
        authentication: {
          ...state.authentication,
          clientchannel: action.payload.channel,
          clientid: action.payload.id
        }
      };
    default:
      return state;
  }
};
export default loginReducer;
