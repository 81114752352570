const getBoxPositionOnWindowCenter = (width, height) => ({
    left: (window.outerWidth / 2)
      + (window.screenX || window.screenLeft || 0) - (width / 2),
    top: (window.outerHeight / 2)
      + (window.screenY || window.screenTop || 0) - (height / 2),
  });
  
  // const getBoxPositionOnScreenCenter = (width, height) => ({
  //   top: (window.screen.height - height) / 2,
  //   left: (window.screen.width - width) / 2,
  // });
  


export const openShareWindow = (socialNetwork,shareUrl) =>{

    const config = {
        height:400,
        width:550,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes',
        ...getBoxPositionOnWindowCenter(550, 400) 
      }
      const url = `${socialNetwork}${shareUrl}`
      window.open(url,
        '',
        Object.keys(config).map(key => `${key}=${config[key]}`).join(', '),
      );

}

