import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom'
import {searchMedia, mediaFilter, sortMedia, toggleEdit} from '../actions'
import "./MobileYoutubeSearch.css"

class MobileYoutubeSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: ''
        };
    }

    //TODO need to stop scroll with overylay open

    // handleQuery = () => {
    //     const {searchQuery} = this.state;
    //     const {searchMedia, toggleBack} = this.props;
    //     if (searchQuery !== '') {
    //         debugger
    //         searchMedia(searchQuery);
    //         toggleBack();
    //     }
    // };

    handleSearch = (e) => {
        const {searchMedia, toggleBack} = this.props;

        // this.setState({
        //     searchQuery: e.target.value
        // })

        searchMedia(e.target.value);
    };

    generatePlaceholder = () => {
        const {clientchannel} = this.props;

        let placeholderString = '';

        if (clientchannel) {
            placeholderString = 'Search in the ' + clientchannel + ' channel';
            return placeholderString
        } else {
            return 'Search...'
        }
    };

    render() {
        const {isSingleView, toggleBack,clientchannel, query, toggleEdit, edit} = this.props;
        const {generatePlaceholder, handleQuery, handleSearch} = this;

        return (
            <div>
                <div className="mobile-youtube-search-container">
                    <div className="mobile-search-youtube-case">
                        <div className="mobile-search-button-youtube">
                            <img
                                onClick={() => toggleBack()}
                                src={`${process.env.PUBLIC_URL}/backarrowsearch.png`} alt=""/>
                        </div>
                        <div className="mobile-right-side-container">
                            <input
                                onChange={(e) => handleSearch(e)}
                                className="popout-search-box"
                                type="search"
                                placeholder={generatePlaceholder()}
                                value={query}
                            >
                            </input>
                            <div
                                className="mobile-search-button-youtube"
                            >
                                <Link to={`/${clientchannel ? clientchannel : "login"}`} onClick={edit ? () => toggleEdit(false): null}>
                                <img
                                    alt={""}
                                    src={`${process.env.PUBLIC_URL}/ui-update-search.png`}
                                />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        filter: state.navReducer.filter,
        clientid: state.loginReducer.authentication.clientid,
        clientchannel: state.loginReducer.authentication.clientchannel,
        loginToken: state.loginReducer.authentication.loginToken,
        allMedia: state.MediaReducer.allMedia,
        isSingleView: state.MediaReducer.isSingleView,
        query:state.MediaReducer.query,
        edit: state.navReducer.edit
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchMedia: (query) => {
            dispatch(searchMedia(query))
        },
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        }
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileYoutubeSearch));
