import React from "react";
import {formatTrimDuration} from "../utilityFunctions/formatTrimDuration";

import ReputationScore from "../ReputationScore/ReputationScore";
import MediaStatisticsComponent from './MediaStatisticsComponent'
import VisibilityComponent from './VisibilityComponent'
import DeleteMediaComponent from './DeleteMediaComponent'
import MediaDisplayComponent from "./MediaDisplayComponent";

const MediaInformationComponent = ({
                                       media, mediacoverurl,stateFormFields,changeField,
                                       deleteCheckboxValue,handleDeleteCheckbox,error, updateMessage
}) => {
    return (
        <div>
            <MediaDisplayComponent
                media={media}
                mediacoverurl={mediacoverurl}
            />
            {(error || updateMessage) &&
            <div className="edit-errors-display">
                {error ? error : updateMessage}
            </div>
            }
            <MediaStatisticsComponent
                media={media}
            />
            <VisibilityComponent
                stateFormFields={stateFormFields}
                changeField={changeField}
                name={"mediasharemethod"}
            />
            <DeleteMediaComponent
                deleteCheckboxValue={deleteCheckboxValue}
                handleDeleteCheckbox={handleDeleteCheckbox}
            />
        </div>

    )
}

export default MediaInformationComponent;
