import React, {useState} from "react"
import "./ProfileMediaUploader.css"

let bannerDimensions = {
    height: 1440,
    width: 2560,
}

let profileDimensions = {
    height: 500,
    width: 500,
}

const UploadModal = (props) => {
    let fileUpload = (event) => {
        //TODO Create a flag to see what the upload associates with
        console.log(props.uploadType)
        console.log('fileuploaded', event.target.files[0])
    }

    return (
        <div className="upload-modal-container-background"
        >
            <div className="upload-modal-wrapper"
                 style={{
                     height: '200px',
                     width: '400px'
                 }}
            >
                <div className="top-wrapper-modal">
                    <div
                        onClick={() => props.toggleModal('')}
                    >
                        X
                    </div>
                </div>
                <div className="content-wrapper-upload-modal">

                    <div className="flex-center-content-upload">
                        <div className="recommended-size">
                            Recommended channel art size:
                        </div>
                        <div className="recommended-size">
                            {props.uploadSection === 'profile' ?
                                profileDimensions.width + ' X ' + profileDimensions.height :
                                bannerDimensions.width + ' X ' + bannerDimensions.height
                            }
                        </div>
                        <div className="recommended-size">
                            (Max file size: 6MB)
                        </div>
                        <label htmlFor="file-input">
                            <div
                                className="upload-button"
                                style={{
                                    width: '150px'
                                }}
                            >
                                Upload Photo
                            </div>
                        </label>
                        <input
                            onChange={(event) => fileUpload(event)}
                            id="file-input"
                            type="file"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadModal;