import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './summary.css'
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {formatDuration} from "../../utilityFunctions/formatDuration";
import moment from "moment";

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: this.props.progress,
            duration: 0,
            gpsNoSignal: 0,
            displayPercentage: 0,
            initialSet: false,
            flexState: '',
            frameStartJSX: {
                width: ''
            }
        };
    }

    componentDidMount() {
        const {generateTrimmCont} = this;

        if (this.durationBox) {
            let percentage = (this.durationBox.clientWidth / this.subar.clientWidth) * 100;
            this.setState({
                displayPercentage: percentage / 2,
                currentDurationWidth: this.durationBox.clientWidth
            })
        }

        Chart.plugins.unregister(ChartDataLabels);

        const videoAudioCtx = this.videoAudioCanvas.getContext("2d");

        let labels = [
            "Valid Frames",
            "Caution Frames",
            "Invalid Frames"
        ];

        this.videoAudioChart = new Chart(videoAudioCtx, {
                plugins: [ChartDataLabels],
                type: 'pie',
                data: {
                    min: 0,
                    max: 100,
                    count: 3,
                    anchor: 'center',
                    labels: labels,
                datasets: [{
                    borderColor: 'transparent',
                    backgroundColor: ['#4bd964', '#fccf10', '#ff4040'],
                    data: [],
                    hoverBackgroundColor: ['#4bd964', '#fccf10', '#ff4040']
                }]
            },
            options: {
                    layout: {
                        padding: {
                            left: 60,
                            right: 50,
                            top: 40,
                            bottom: 40
                        },
                    },
                    plugins: {
                        datalabels: {
                            labels: {
                                name: {
                                    anchor: 'end',
                                    align: 'end',
                                    offset: 8,
                                    color: ['#4bd964', '#fccf10', '#ff4040'],
                                    font: {
                                        family: 'Comfortaa',
                                    },
                                    formatter: function (value, ctx) {
                                        if (value)
                                        return ctx.chart.data.labels[ctx.dataIndex] + " " + ctx.chart.data.datasets[0].data[ctx.dataIndex];
                                    }
                },
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {display: false},
                    title: false,
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                elements: {
                    arc: {
                        borderWidth: .75,
                    }
                }
                },
            }
        );

        generateTrimmCont();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setDonutData(this.videoAudioChart);
        const {duration} = this.props;
        const {generateTrimmCont} = this;
        const {initialSet} = this.state;
        let percentage = '';

        if (prevProps !== this.props) {
            generateTrimmCont();
        }

        if (!initialSet && this.props.color === 'yellow' && this.playbarDurationReason) {
            //this checks if the first frame is yellow then we need calculate that differently then if the first frame is green
            if (this.playbarDurationReason.clientWidth > 60) {
                percentage = (this.playbarDurationReason.clientWidth / this.subar.clientWidth) * 100;
                console.log('2');
                this.setState(
                    {
                        displayPercentage: percentage / 2,
                        currentDurationWidth: this.durationBox.clientWidth,
                        initialSet: true
                    }
                );
                return
            }
            return
        }

        if (this.durationBox && !this.state.initialSet && this.props.color) {
            if (this.durationBox.clientWidth > 0) {
                percentage = (this.durationBox.clientWidth / this.subar.clientWidth) * 100;
                this.setState({
                    displayPercentage: percentage / 2,
                    currentDurationWidth: this.durationBox.clientWidth,
                    initialSet: true
                })
            }
        }

        if (this.props.color !== prevProps.color && duration > 0) {
            //If the color has changed or the duration is greater equals zero
            if (prevProps.color === 'green' && this.props.color === 'yellow') {
                percentage = (this.durationBox.clientWidth / this.subar.clientWidth) * 100 / 2;
                this.setState({
                    displayPercentage: percentage,
                    currentDurationWidth: this.durationBox.clientWidth,
                })
            } else if (prevProps.color === 'yellow' && this.props.color === 'green') {
                percentage = (this.durationBox.clientWidth / this.subar.clientWidth) * 100 / 2 - .75;
                this.setState({
                    displayPercentage: percentage,
                    currentDurationWidth: this.durationBox.clientWidth,
                })
            }

        }
    }

    componentWillUnmount() {
        this.setState({
            initialSet: false,
            displayPercentage: '',
            currentDurationWidth: '',
            dualTrimmPadding: 0
        });

        this.videoAudioChart.clear()
    }

    setDonutData = (chart) => {
        const {videosequencegrades, imagesequencegrades} = this.props;
        let grades = '';

        if (videosequencegrades) {
            grades = videosequencegrades
        } else if (imagesequencegrades) {
            grades = imagesequencegrades
        }

        if (chart.data.datasets[0] && grades) {
            //Valid Frames
            if (grades.green)
                chart.data.datasets[0].data[0] = grades.green;
            //Caution Frames
            if (grades.yellow)
                chart.data.datasets[0].data[1] = grades.yellow;
            //Invalid Frames
            if (grades.red)
                chart.data.datasets[0].data[2] = grades.red;
            //Trimmed Frames
            if (grades.grey)
                chart.data.datasets[0].data[2] = grades.grey;
        }

        chart.update();
    };

    formatStartTime = (dateTime) => {
        if (dateTime) {
            if (dateTime) {
                return moment(dateTime).format("MM/DD/YY hh:mm:ss A");
            }
        }
    };

    generateTrimmCont = () => {
        const {
            frameStart,
            frameEnd,
            totalSeqCount
        } = this.props;
        //Setting at 97 and 3 because it seems to match subbarurl better

        // could create a calc function for getting correct start end etc
        let frameStartJSX = {};
        let calcFrameStart = '';
        let calcFrameEnd = '';
        frameStartJSX['display'] = 'flex';

        if (frameStart && frameEnd) {
            calcFrameStart = ((totalSeqCount - frameStart) / totalSeqCount) * 100;
            calcFrameEnd = ((totalSeqCount - frameEnd) / totalSeqCount) * 100;
            frameStartJSX['width'] = (calcFrameStart - calcFrameEnd) + '%';

            this.setState({
                flexState: 'left',
                frameStartJSX: frameStartJSX,
                dualTrimmPadding: 96 - parseInt(calcFrameStart) + '%'
            });
            return
        }
        if (frameStart) {
            frameStartJSX['width'] = 100 - ((totalSeqCount - frameStart) / totalSeqCount) * 100 + '%';
            this.setState({
                flexState: 'right',
                frameStartJSX: frameStartJSX
            });
            return
        }
        if (frameEnd) {
            frameStartJSX['width'] = 100 - ((totalSeqCount - frameEnd) / totalSeqCount) * 100 + '%';
            this.setState({
                flexState: 'left',
                frameStartJSX: frameStartJSX
            });
        } else {
            this.setState({
                frameStartJSX: {
                    width: '100%'
                }
            })
        }
    };

    render() {
        const {
            summaryData,
            progress,
            duration,
            sliderValue,
            subbarurl,
            frames,
            media,
            playbarPercentage
        } = this.props;
        const {
            flexState,
            frameStartJSX,
            dualTrimmPadding
        } = this.state;
        const {formatStartTime} = this;
        let lightMode = 'black';
        let darkMode = 'white';

        let playBoxProgressWidth = (progress * parseInt(frameStartJSX.width) / duration) + this.state.displayPercentage;

        // (videoPlayer.currentTime * parseInt(frameStartJSX.width) / videoPlayer.duration) + this.state.displayPercentage + '%'
        console.log('position calculation', (progress * parseInt(frameStartJSX.width) / duration) + this.state.displayPercentage);


        return (
            <div className="summary-container"
                 style={{background: !this.props.lightView ? `rgb(21,88,141,${sliderValue * .01})` : 'white'}}>
                <div>
                    <div className="component-header-wrapper">
                        <div
                            className="component-title"
                            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                            SUMMARY
                        </div>
                        <div className="summary-component-icon">
                            <img src={`${process.env.PUBLIC_URL}/Icon-Summary.png`} alt=""/>
                        </div>
                    </div>
                    <div className="summary-recording-data-container">
                        {media.mediatype !== 'image' &&
                        <div className="summary-recording-data-wrapper">
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                Start Time:
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                {summaryData.frameGradeData ? formatStartTime(summaryData.frameGradeData.startTime) : ''}
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                End Time:
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                {summaryData.frameGradeData ? formatStartTime(summaryData.frameGradeData.endTime) : ''}
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                Length:
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                {media.videoduration}
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                Frames:
                            </div>
                            <div
                                style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}
                            >
                                {frames}
                            </div>
                        </div>}
                    </div>
                    {/*<div className="summary-recording-data-container">*/}
                    {/*    <div className="summary-recording-data-title">Trimmed Recording</div>*/}
                    {/*    <div className="summary-recording-data-wrapper">*/}
                    {/*        <div>Start Time:</div>*/}
                    {/*        <div>Placeholder</div>*/}
                    {/*        <div>End Time:</div>*/}
                    {/*        <div>Placeholder</div>*/}
                    {/*        <div>Length</div>*/}
                    {/*        <div>Placeholder</div>*/}
                    {/*        <div>Frames</div>*/}
                    {/*        <div>Placeholder</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div>
                    <div className="summary-graphs-wrapper">
                        <div className="summary-graph-wrapper">
                            <div className="summary-graph">
                                <canvas id="videoAudioCanvas"
                                        ref={videoAudioCanvas => {
                                            this.videoAudioCanvas = videoAudioCanvas;
                                        }}/>
                            </div>
                        </div>
                        {/*<div className="summary-graph-datas-wrapper">*/}
                        {/*    <div className="summary-graph-data-wrapper">*/}
                        {/*        <div className="graph-color-block-green">*/}
                        {/*        </div>*/}
                        {/*        <div*/}
                        {/*            className="graph-data"*/}
                        {/*            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}*/}
                        {/*        >*/}
                        {/*            Valid: {summaryData['summaryFrameData'] ? countFrameColor(summaryData['summaryFrameData'], 'green') : 0} Frames*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="summary-graph-data-wrapper">*/}
                        {/*        <div className="graph-color-block-yellow">*/}
                        {/*        </div>*/}
                        {/*        <div*/}
                        {/*            className="graph-data"*/}
                        {/*            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                        {/*            Caution: {summaryData['summaryFrameData'] ? countFrameColor(summaryData['summaryFrameData'], 'yellow') : 0} Frames*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="summary-graph-data-wrapper">*/}
                        {/*        <div className="graph-color-block-red">*/}
                        {/*        </div>*/}
                        {/*        <div*/}
                        {/*            className="graph-data"*/}
                        {/*            style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                        {/*            Invalid: {summaryData['summaryFrameData'] ? countFrameColor(summaryData['summaryFrameData'], 'red') : 0} Frames*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="summary-recording-data-container">
                            <div className="summary-recording-data-wrapper-btm">
                                <div
                                    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                    GPS Low Quality:
                                </div>
                                <div
                                    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                    {summaryData.frameGradeData ? summaryData.frameGradeData.gpsLowQuality : ''} Frames
                                </div>
                                <div
                                    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                    GPS No Signal:
                                </div>
                                <div
                                    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>
                                    {summaryData.frameGradeData ? summaryData.frameGradeData.gpsNoSignal : ''} Frames
                                </div>
                                {/*<div*/}
                                {/*    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                                {/*    Slow Data Collection:*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    style={!this.props.lightView ? {color: darkMode} : {color: lightMode}}>*/}
                                {/*    {summaryData.frameGradeData ? summaryData.frameGradeData.slowDataCollection : ''}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="summary-graph-data-bar-wrapper"
                         ref={(subar) => {
                             this.subar = subar
                         }}
                    >
                        {subbarurl &&
                        <div>
                            {!isNaN(duration) &&
                            <div style={{
                                'display': 'flex',
                                justifyContent: flexState,
                                marginLeft: dualTrimmPadding
                            }}
                            >
                                <div
                                    className='should be same on mobile'
                                    style={{'width': frameStartJSX.width}}
                                >
                                    <div className="playbar-wrapper-group"
                                         style={{
                                             width: playBoxProgressWidth + '%',
                                             top: this.props.color === 'yellow' ? '-63px' : '-43px'
                                         }}
                                    >
                                        <div className="playbar-box-wrapper"
                                        >
                                            <div className="playbar-duration-reason-summary"
                                                 style={{background: this.props.color === 'yellow' ? '#f4db3f' : ''}}
                                                 ref={(playbarDurationReason) => {
                                                     this.playbarDurationReason = playbarDurationReason
                                                 }}
                                            >
                                                {this.props.reason ? this.props.reason : ''}
                                            </div>
                                            <div className="summary-playbar-duration-box"
                                                 ref={(durationBox) => {
                                                     this.durationBox = durationBox
                                                 }}
                                            >
                                                {progress ? formatDuration(progress) : '00:00:00'}
                                            </div>
                                            <div className="arrow-wrapper">
                                                <div className="bottom-arrow-centered">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div
                                style={{width: '100%'}}
                            >
                                <img className="summary-graph-data-bar" src={subbarurl} alt="">
                                </img>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        sliderValue: state.mediaSingleReducer.sliderValue,
        subbarurl: state.mediaSingleReducer.currentMedia.subbarurl,
        media: state.mediaSingleReducer.currentMedia,
        totalFrames: state.mediaSingleReducer.totalFrames,
        reason: state.mediaSingleReducer.reason,
        color: state.mediaSingleReducer.color,
        frameStart: state.mediaSingleReducer.currentMedia.framestart,
        frameEnd: state.mediaSingleReducer.currentMedia.frameend,
        totalSeqCount: state.mediaSingleReducer.currentMedia.videosequencecount,
        videosequencegrades: state.mediaSingleReducer.currentMedia.videosequencegrades,
        imagesequencegrades: state.mediaSingleReducer.currentMedia.imagesequencegrades,
        playbarPercentage: state.mediaSingleReducer.playbarPercentage
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary))
