import React, {Component} from "react";
import {connect} from "react-redux";
import { toggleEdit, unsetMetData,setShareUrl} from "../actions";
import {withRouter} from 'react-router-dom'
import {sortOptions} from "./sortOptions";
import {toggleMobileSocialShare} from "./toggleMobileSocialShare";
import "./EditShareModal.css"

class EditShareModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortToggled: false,
            sortOptions:sortOptions(props)
        }
    }

    toggleSort = () => {
        this.setState({
            sortToggled: !this.state.sortToggled
        })
    }

    render() {
        const {sortToggled, sortOptions} = this.state
        const {toggleSort} = this;

        return (
            <div className="editmodal-container"  style={this.props.containerStyle}>
                <div className="editmodal-text-icon-wrapper">
                    <div className="editmodal-icon-wrapper">
                        <i  className="fa fa-ellipsis-v fa-2x edit-ellipsis editmodal-icon" aria-hidden="true"
                            style={this.props.iconstyle}
                            onClick={() => toggleSort()}
                        >
                        </i>
                    </div>
                </div>
                {sortToggled &&
                <div className="edit-modal-body-container">
                    <ul>
                        {
                            sortOptions.map((option, i) =>
                                <li key={i}
                                    data-toggle={option.dataToggle}
                                    data-target={option.dataTarget}
                                    onClick={option.click}
                                    className={option.className}
                                >
                                    <div
                                        className="editmodal-list-icon-wrapper"
                                    >
                                        <img
                                            className="editmodal-icon"
                                            src={`${process.env.PUBLIC_URL}` + option.icon} alt=""/>
                                    </div>
                                    <div>
                                        {option.displayTitle}
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </div>
                }
            </div>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            loginToken: state.loginReducer.authentication.loginToken,
            clientchannel: state.loginReducer.authentication.clientchannel,
            homechannel: localStorage.getItem("homechannel"),
            edit: state.navReducer.edit,
            socialShareUrl:state.mediaSingleReducer.shareurl
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {
            toggleEdit:(value)=>{
                dispatch(toggleEdit(value))
            },
            unsetMetData: () =>{
                dispatch(unsetMetData())
            },
            setShareUrl:(shareurl,mediasharetype) =>{
                dispatch(setShareUrl(shareurl,mediasharetype))
                    .then((response)=>{
                        toggleMobileSocialShare(response.socialshareurl,response.mediasharetype)
                    })
            }
        }
    };


export default withRouter(connect

(mapStateToProps, mapDispatchToProps)(
    EditShareModal
));
