import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {metaDataIsSet} from '../MediaSingle/actions'

import '../Drawer/drawer.css'

import Location from "../DrawerComponents/Location/Location";
import Time from "../DrawerComponents/Time/Time";
import Phone from "../DrawerComponents/Phone/Phone";
import Connection from "../DrawerComponents/Connection/Connection";
import Summary from "../DrawerComponents/Summary/Summary";
import Networks from "../DrawerComponents/Networks/Networks";

// import Sound from "../DrawerComponents/Sound/Sound";

class HalfViewDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {displayData,drawerOpen,mediaFrames} = this.props;

        return (
            <div className="half-view-drawer-container"
                 style={{'height': this.props.height + 'px'}}
            >
                <div className="drawer-wrapper"
                     style={{background: drawerOpen ? '' : 'black'}}
                >
                    {/*<div>{this.props.currentsequence}</div>*/}
                    <Summary
                        lightView={this.props.mediaHalfView}
                        summaryData={displayData.summaryData}
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        frames={Object.keys(mediaFrames)[Object.keys(mediaFrames).length - 1]}
                    />
                    <Location
                        summaryData={displayData.summaryData}
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        locationData={displayData.locationData}/>
                    <Time
                        timeData={displayData.timeData}
                        lightView={this.props.mediaHalfView}
                        connectionData={displayData.connectionData}
                    />
                    <Phone
                        phoneData={displayData.phoneData}
                        lightView={this.props.mediaHalfView}
                    />
                    {/*{mediaType !== 'image' &&*/}
                    {/*<Sound*/}
                    {/*    progress={this.props.currentsequence}*/}
                    {/*    duration={this.props.duration}*/}
                    {/*    soundData={displayData.soundData}*/}
                    {/*    lightView={this.props.mediaHalfView}*/}
                    {/*/>}*/}
                    <Connection
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        connectionData={displayData.connectionData}
                        lightView={this.props.mediaHalfView}
                    />
                    <Networks
                        progress={this.props.currentsequence}
                        duration={this.props.duration}
                        connectionData={displayData.connectionData}
                        lightView={this.props.mediaHalfView}
                        encryptionData={displayData.encryptionData}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        params: params,
        player: state.player,
        drawerOpen: state.mediaSingleReducer.drawerOpen,
        mediaIsSet: state.mediaSingleReducer.mediaIsSet,
        mediaFrames: state.mediaSingleReducer.mediaFrames,
        mediaType: state.mediaSingleReducer.currentMedia.mediatype,
        totalFrames: state.mediaSingleReducer.totalFrames,
        displayData:state.drawerDisplayReducer.displayData

    }
};

const mapDispatchToProps = dispatch => {
    return {
        metaDataIsSet: () => {
            dispatch(metaDataIsSet())
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HalfViewDrawer));
