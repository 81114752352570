import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import "./ProfileCircle.css"
import {toggleUploadModal} from "../actions"
import ProfileMediaUploader from "../ProfileMediaUploader/ProfileMediaUploader";
import UploadModal from "../ProfileMediaUploader/UploadModal";

class ProfileCircle extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {clientchannel, loginToken, uploader, dimensions, toggleUploadModal, uploadToggle} = this.props;
        const homechannel = localStorage.getItem("homechannel")

        return (
            <div className="profilecircle-container">
                <div
                    className="profilecircle-wrapper"
                    style={dimensions}
                >
                    <img src={`${process.env.PUBLIC_URL}/default-user.png`}

                         alt=""/>
                </div>
                {loginToken && clientchannel === homechannel &&
                <div className="profilecircle-uploader-container"
                     style={{
                         right: window.innerWidth > 900 ? '24px' : '8px'
                     }}
                >
                    {uploader &&
                    <ProfileMediaUploader
                        uploadType={'profile'}
                        uploadToggle={uploadToggle}
                        toggleModal={toggleUploadModal}
                    />
                    }
                </div>
                }
            </div>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            clientchannel: state.loginReducer.authentication.clientchannel,
            loginToken: state.loginReducer.authentication.loginToken,
            uploadToggle: state.navReducer.uploadToggle,
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {
            toggleUploadModal: (payload) => {
                dispatch(toggleUploadModal(payload))
            }
        }
    };


export default withRouter(connect

(mapStateToProps, mapDispatchToProps)(
    ProfileCircle
));
