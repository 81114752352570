

/**
 * React Video Thumbnail Component
 * Original content @ https://github.com/brothatru/react-video-thumbnail
 * @author mike trieu
 */
import React from 'react';
import PropTypes from 'prop-types';
import "./thumbnail.css"




/**
 * Simple component that renders thumbnail url
 * @param {string} snapshot
 * @param thumbnailHandler
 */
const ThumbnailImage = ({ displayImage,snapshot, thumbnailHandler, imageRef }) => {
    return (
        <div className="img-wrapper"  ref={imageRef} onClick={() =>thumbnailHandler(snapshot, imageRef)}>
            <img src={displayImage} className="thumbnail-cover-image" alt="my video thumbnail" />
        </div>
    );
};

export default class VideoThumbnail extends React.Component {

    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.videoEl = React.createRef();
        this.imageWrap = React.createRef();
        this.state = {
            dataLoaded: false,      // boolean
            metadataLoaded: false,  // boolean
            seeked: false,          // boolean
            snapshot: false,        // Blob thumbnail  || false
            displayImage:null,      // Image url created from blob
            suspended: false,       // boolean
            // props
            cors: props.cors,                           // boolean
            width: props.width,                         // number
            height: props.height,                       // number
            renderThumbnail: props.renderThumbnail,     // boolean
            snapshotAtTime: props.snapshotAtTime,       // number
            thumbnailHandler: props.thumbnailHandler,   // callback function
            videoUrl: props.videoUrl,                   // string
        }
    }

    render() {
        const { renderThumbnail, snapshot, videoUrl,thumbnailHandler,displayImage } = this.state;

        if (!snapshot) {
            return (
                <div className="img-wrapper" >
                    <div className="thumbnail-loading-message">Loading...</div>
                    <canvas className="thumbnail-cover-image" ref={this.canvas} style={{"visibility": "hidden"}} ></canvas>
                    <video muted={true} style={{"visibility": "hidden"}}
                           className="thumbnail-cover-image"
                           ref={this.videoEl}
                           src={videoUrl}
                           playsInline={true}
                           preload={"auto"}// does not work on iOS

                           onLoadedMetadata={() => {
                               this.setState({ metadataLoaded: true })
                           }}
                           onLoadedData={() => {
                               this.setState({ dataLoaded: true })
                           }}
                        // onSuspend={() => {
                        //Does not fire on Edge, inconsistent on iOS Safari
                        //Not really necessary due to other checks
                        //     this.setState({ suspended: true })
                        // }}
                           onSeeked={() => {
                               this.setState({ seeked: true })
                           }}
                    >
                    </video>
                </div>
            )
        } else {
            if (renderThumbnail) {
                return <ThumbnailImage imageRef={this.imageWrap} snapshot={snapshot} displayImage={displayImage} thumbnailHandler={thumbnailHandler}/>;
            } else {
                return null;
            }
        }
    }

    /**
     * Update any props that may have changed
     */
    componentWillReceiveProps(nextProps) {
        let stateChanged = false;
        const data = {};
        for (let prop in nextProps) {
            if (nextProps[prop] !== this.props[prop]) {
                data[prop] = nextProps[prop];
                if (!stateChanged) {
                    stateChanged = true;
                }
            }
        }
        if (stateChanged) {
            this.setState(data);
        }
    }

    componentDidMount() {
        if (!this.state.cors) this.videoEl.current.setAttribute('crossOrigin', 'Anonymous');
    }

    /**
     * (fires every time setState() gets called)
     */
    componentDidUpdate(prevProps, prevState) {
        const {videoEl}=this;
        if (!this.state.snapshot) {
            const { metadataLoaded, dataLoaded, seeked, snapshot, snapshotAtTime } = this.state;

            // check if 2 required events fired
            if (metadataLoaded && dataLoaded && !snapshot ) {

                videoEl.current.currentTime = snapshotAtTime;

                if (seeked && !snapshot) {
                    // attempt to generate thumbnail
                    this.getSnapShot();
                }
            }
        }
    }
    componentWillUnmount() {
        // static method releases an existing object URL which was previously created by calling URL.createObjectURL().
        // Call this method when you've finished using an object URL to let the browser know not to keep the reference to the file any longer.
        URL.revokeObjectURL(this.state.displayImage)
    }

    /**
     * Create a canvas and video element to "draw" the
     * image, then convert it to a data url
     */
    getSnapShot = () => {
        try {
            const { width, height } = this.props;
            const video = this.videoEl.current;
            const canvas = this.canvas.current;
            // canvas.height = video.videoHeight;
            // canvas.width = video.videoWidth;

            canvas.height = height;
            canvas.width = width;


            // resize thumbnail or no ?
            if (!width || !height) {
                canvas.getContext('2d').drawImage(video, 0, 0);
            } else {
                canvas.getContext('2d').drawImage(video, 0, 0, width, height);
            }

            if(HTMLCanvasElement.prototype.toBlob){
                canvas.toBlob(blob => this.setState({
                    snapshot: blob,
                    displayImage:URL.createObjectURL(blob)
                }), 'image/jpeg', 0.95); // JPEG at 95% quality
            }else{
                const thumbnailbase64 = canvas.toDataURL('image/jpeg', 0.5); //base64 string
                this.setState({
                    snapshot: this.convertThumbnail(thumbnailbase64),
                    displayImage:URL.createObjectURL(this.convertThumbnail(thumbnailbase64))
                })
            }

            // Remove video & canvas elements (no longer needed)
            video.src = "";  // setting to empty string stops video from loading
            video.remove();
            canvas.remove();

        } catch (e) {
            console.error(e);
        }
    }

    convertThumbnail = thumbnail =>{
        let byteString =window.atob( thumbnail.split(',')[1]);
        let mimeString = thumbnail.split(',')[0].split(':')[1].split(';')[0];

        // let decodedData = window.atob(test);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        let newCoverImage = new Blob([ab], { type: mimeString });
        return newCoverImage

    }
}

/**
 * Property Types
 */
VideoThumbnail.propTypes = {
    cors: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    renderThumbnail: PropTypes.bool,
    snapshotAtTime: PropTypes.number,
    thumbnailHandler: PropTypes.func,
    videoUrl: PropTypes.string,
};

/**
 * Default Properties
 */
VideoThumbnail.defaultProps = {
    cors: false,
    renderThumbnail: true,
    snapshotAtTime: 2,
};

