const initialState = {
    search: "",
    filter: {
        mediaShareType: '',
        filterMediaType: ''
    },
    layout: "grid",
    edit: false,
    uploadToggle: false,
    bannerType: '',
    userView: 'public'
};
const navReducer = (state = initialState, action) => {
    // console.log(action);
    switch (action.type) {
        case "UPDATE_NAV":
            return {
                ...state,
                videos: action.payload
            };
        case "TOGGLE_UPLOAD_MODAL":
            return {
                ...state,
                uploadToggle: !state.uploadToggle,
                bannerType: action.payload
            }
        case "USER_VIEW":
            let layoutChange = 'grid'
            let mobile = window.innerWidth < 900;

            if (action.payload === 'private') {
                if (mobile) {
                    layoutChange = 'mobile-private'
                } else {
                    layoutChange = 'desktop-private'
                }
            }

            return {
                ...state,
                layout: layoutChange,
                userView: action.payload
            }
        case 'TOGGLE_DISPLAY':
            return {
                ...state,
                layout: action.payload
            }
        case 'MEDIA_SEARCH':
            return {
                ...state,
                search: action.payload
            }
        case 'MEDIA_FILTER':
            return {
                ...state,
                filter: {...state.filter, ...action.payload}
            }
        case 'TOGGLE_EDIT':
            return {
                ...state,
                edit: action.payload
            }
        default:
            return state;
    }
};
export default navReducer;
