import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {toggleEdit} from "../actions/index";
import "./mediacomponent.css";
import "./PrivateMediaComponent.css"
import moment from "moment";
import ReputationScore from "../ReputationScore/ReputationScore";
import EditShareModal from "../EditShareModal/EditShareModal";

class PrivateMediaComponent extends React.Component {
    componentDidMount() {
    }

    handleChannel = () => {
        const {clientchannel} = this.props
        if (clientchannel) {
            return `/${clientchannel}`
        }
        return ''
    };

    //TODO lets update this function to return a block of two stacked divs

    formatDate = date => {
        if (date !== null) {
            let dateSplit = date.split(" ");
            // return (
            //     moment(dateSplit[0]).format("MM/DD/YY") +
            //     " " +
            //     moment(dateSplit[1], "HH:mm:ss").format("hh:mm A")
            //
            // );
            return (
                <div>
                    <div className="private-view-time-date">
                        {moment(dateSplit[0]).format("MMM DD, YYYY")}
                    </div>
                    <div className="private-view-time-date">
                        {moment(dateSplit[1], "HH:mm:ss").format("hh:mm A")}
                    </div>
                </div>
            )
        }
    };

    shortenLength = (str, type) => {
        let limit = type === "title" ? 40 : 70;
        if (str) {
            if (str.length > limit) {
                let shortenedDesc = str.substring(0, limit);
                return shortenedDesc + ' ...';
            } else {
                return str
            }
        }
    };

    capitalizeFirstLetter = (string) => {
        if (string)
            return string.charAt(0).toUpperCase() + string.slice(1);
    };

    addDefaultSrc = (ev) => {
        ev.target.src = `${process.env.PUBLIC_URL}/videoCoverTemp.png`
    };

    mediaShareMethod = (visibility) => {
        switch (visibility) {
            case 'private' :
                return (
                    `${process.env.PUBLIC_URL}/visibilityicons/privateicon.png`
                )
            case 'public' :
                return (
                    `${process.env.PUBLIC_URL}/visibilityicons/publicicon.png`
                )
            case 'unlisted' :
                return (
                    `${process.env.PUBLIC_URL}/visibilityicons/unlistedicon.png`
                )
        }
    }

    render() {
        const {media, toggleEdit, clientchannel} = this.props;
        const {formatDate, shortenLength, capitalizeFirstLetter, mediaShareMethod} = this;

        return (
            <div className="listview-container">
                <div className="private-grid-container">

                    <div className="left-media-image">
                        <Link
                            to={`${this.handleChannel()}/${media.mediatype[0]}/${
                                media.mediakey
                            }`}
                            onClick={this.handleMediaToken}
                        >
                            <div className="private-media-wrapper">
                                <img
                                    onError={this.addDefaultSrc}
                                    className="private-media-cover-image"
                                    src={
                                        media.mediacoverurl
                                            ? media.mediacoverurl
                                            : `${process.env.PUBLIC_URL}/videoCoverTemp.png`
                                    }
                                    alt="Video"
                                />
                                {media.mediatype !== 'image' &&
                                <div className="list-media-component-timeclock">
                                    {media.mediaduration}
                                </div>
                                }
                                {media.subbarurl &&
                                <div className="list-subbar-wrapper">
                                    <img className="list-bar-img" src={media.subbarurl} alt=""/>
                                </div>
                                }
                            </div>
                        </Link>
                    </div>

                    <div className="private-title-container">
                        <div className='listview-title-wrapper'>
                            {this.props.media.mediatitle ? shortenLength(this.props.media.mediatitle, "title") : 'No Title'}
                        </div>
                    </div>

                    <div className="private-reputation-container">
                        <ReputationScore summaryBullets={media.summarybullet} viewCss={'private-reputation'}/>
                    </div>

                    <div className="private-visibility-container">
                        <div className="private-visibility-wrapper">
                            <div className="visibility-image-wrapper">
                                <img src={mediaShareMethod(media.mediasharemethod)} alt=""/>
                            </div>
                            <div className="media-share-title-uppercasefirst">
                                {media.mediasharemethod}
                            </div>
                        </div>
                    </div>

                    <div className="private-recorded-container">
                        {formatDate(media.mediacreateddate)}
                    </div>

                    <div className="private-uploaded-container">
                        <div>
                            {formatDate(media.mediapublisheddate)}
                        </div>
                    </div>

                    <div className="private-published-container">

                    </div>

                    <div className="private-views-container">
                        <div className="private-view-time-date">
                            {media.mediaviewcount} views
                        </div>
                    </div>

                    <div className="private-edit-icon">
                        {/*<Link*/}
                        {/*    to={`${this.handleChannel()}/${media.mediatype[0]}/${*/}
                        {/*        media.mediakey*/}
                        {/*    }`}*/}
                        {/*    onClick={toggleEdit}*/}
                        {/*>*/}
                        {/*    <i className="fa fa-ellipsis-v fa-2x edit-ellipsis" aria-hidden="true"*/}
                        {/*       style={{*/}
                        {/*           color: 'black',*/}
                        {/*           fontSize: '18px'*/}
                        {/*       }}> </i>*/}
                        {/*</Link>*/}
                        <EditShareModal
                            watchurl={`/${clientchannel}/${media.mediatype[0] }/${media.mediakey}`}
                            shareurl={media.mediasocialshareurl}
                            mediasharemethod={media.mediasharemethod}
                            iconstyle={{color:"darkgrey",fontSize:'18px'}}
                            containerStyle={{justifyContent: "flex-end",width: "100%"}}
                        />
                    </div>

                </div>
            </div>

        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        params: params,
        loginToken: state.loginReducer.authentication.loginToken,
        clientchannel: state.loginReducer.authentication.clientchannel
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleEdit: () => {
            dispatch(toggleEdit())
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PrivateMediaComponent)
);
