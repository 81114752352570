export const generateCoordinates = (frameNumbers, mediaFrames, dataTitle) => {
    let output = [];
    for (let i = 0; i < frameNumbers.length; i++) {
        const number = frameNumbers[i];
        const value = mediaFrames[number].meta[dataTitle];
        const yAxis = parseFloat(value, 10);
        output.push({x: i + 1, y: yAxis})
    }
    return output
};

export const generateDataTimeDelay = (frameNumbers, mediaFrames, dataTitle) => {
    let output = [];
    for (let i = 0; i < frameNumbers.length; i++) {
        const number = frameNumbers[i];
        const value = mediaFrames[number][dataTitle];
        const yAxis = parseFloat(value, 10);
        output.push({x: i + 1, y: yAxis})
    }
    return output
};

export const generateFrameValidData = (frameNumbers, mediaFrames) => {
    let output = [];
    for (let i = 0; i < frameNumbers.length; i++) {
        const number = frameNumbers[i];
        if (mediaFrames[number]['audioframegrade']) {
            let value = mediaFrames[number]['audioframegrade'];
            output.push(value)
        }
        if (mediaFrames[number]['videoframegrade']) {
            let value = mediaFrames[number]['videoframegrade'];
            output.push(value)
        }
        if (mediaFrames[number]['imageframegrade']) {
            let value = mediaFrames[number]['imageframegrade'];
            output.push(value)
        }
    }
    return output
};

export const generateFrameGradeReasons = (frameNumbers, mediaFrames) => {
    let len = frameNumbers.length;
    let output = [];
    let results = {
        gpsNoSignal: 0,
        gpsLowQuality: 0,
        slowDataCollection: 0,
        startTime: 0,
        endTime: 0
    };

    for (let i = 0; i < len; i++) {
        const number = frameNumbers[i];
        if ('videoframegradereason' in mediaFrames[number]) {
            let value = mediaFrames[number]['videoframegradereason'];
            results['startTime'] = mediaFrames[frameNumbers[0]]['videoframedevicedatetime'];
            results['endTime'] = mediaFrames[frameNumbers[len - 1]]['videoframedevicedatetime'];
            output.push(value)
        }
        if ('audioframegradereason' in mediaFrames[number]) {
            let value = mediaFrames[number]['audioframegradereason'];
            results['startTime'] = mediaFrames[frameNumbers[0]]['audioframedevicedatetime'];
            results['endTime'] = mediaFrames[frameNumbers[len - 1]]['audioframedevicedatetime'];
            output.push(value)
        }
    }

    for (let i = 0; i < output.length; i++) {
        if (output[i]) {
            if (output[i].includes('Missing GPS Coordinates')) {
                results['gpsNoSignal'] += 1
            }
            if (output[i].includes('GPS Quality Low')) {
                results['gpsLowQuality'] += 1
            }
        }
    }
    return results
};

export const additionalFrameData = (mediaFrames) => {
    let summaryData = ["videoframedevicedatetime", "audioframedevicedatetime", "imageframedevicedatetime", 'color', 'colorreason'];
    let additionalData = {};
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (mediaFrames.hasOwnProperty(element)) {
            //convertMedia creates general property value to access value in componentData
            //"media" vs. video, audio, image.
            additionalData[convertMedia(element)] = mediaFrames[element]
        }
    }
    return {...additionalData, ...parseSisterMetric(mediaFrames)}
};

export const gatherEncryptionData = (mediaFrames, media) => {
    const mediaType = assignMediaType(media)
    let summaryData = [
        "devicetime", `hashmethod`,
        `hashvalue`, `${mediaType}frametransactionid`,
        `${mediaType}framedevicemetahashvalue`
    ];
    let encryptionData = {}
    for (let i = 0; i < summaryData.length; i++) {
        const element = summaryData[i];
        if (mediaFrames.hasOwnProperty(element)) {
            //convert media creates general property value to access value in componentData
            //"media" vs. video, audio, image.
            encryptionData[convertMedia(element)] = mediaFrames[element]
        }
    }
    return encryptionData
}

const assignMediaType = (media) => {
    switch (media) {
        case 'v':
            return "video"
        case 'a':
            return "audio"
        case 'i':
            return 'image'
        default :
            return null
    }
};

const convertMedia = media => {
    return media.replace(/image|video|audio/g, 'media')
};

const parseSisterMetric = (mediaFrames) => {
    //iPhone satellite data is on "sister metric" property, android is not
    let sisterMetricData = {};

    if (mediaFrames.meta.hasOwnProperty('sistermetric') && mediaFrames.meta["sistermetric"] !== '' && mediaFrames.meta["sistermetric"] !== 'NA') {
        const sistermetric = JSON.parse(mediaFrames.meta["sistermetric"]).metrics;

        return {
            ...sisterMetricData,
            satellitedate: sistermetric["satellitedate"],
            satellites: sistermetric["satellites"],
            sunMoonData: {
                sunmoonisodate: sistermetric["sunmoonisodate"],
                sunposition: sistermetric["sunposition"],
                moonposition: sistermetric["moonposition"],
                sunmoonpositiondate: sistermetric["sunmoonpositiondate"]
            }
        }
    }
    return {
        ...sisterMetricData,
        satellitedate: '',
        satellites: [],
        sunMoonData: {},
    }
};
