import React from "react"
import {compose, withProps} from "recompose"
import {adjustMap} from "./mapHelperFunctions/index"
import {Polyline, withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"

const API_KEY = "AIzaSyDz6O9SSK16WR6h3xA_h4SsS1MdkkwF_lI";

class TraceRoutesMap extends React.PureComponent {
    state = {};

    handleMount = map => {
        const {polylineCoordinates} = this.props;
        this.map = map;

        if (this.map) {
            adjustMap(polylineCoordinates, this.map)
        }
    };

    render(props) {
        return (
            <TraceMap
                zoom={this.props.zoom}
                initialCenter={this.props.initialCenter}
                handleMount={this.handleMount}
                polyLines={this.props.polylineCoordinates}
                startFinishMarkers={this.props.startFinishMarkers}
            />
        )
    }
}

const TraceMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `100%`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <div>
        <GoogleMap
            defaultZoom={props.zoom}
            center={props.initialCenter}
            ref={props.handleMount}
        >
            {props.startFinishMarkers.map((pin, i) =>
                <Marker
                    position={{
                        lat: parseFloat(pin.lat),
                        lng: parseFloat(pin.lng)
                    }}
                    key={Math.random()}
                    icon={{
                        scaledSize: new window.google.maps.Size(25, 35),
                        url: pin.iconUrl ? pin.iconUrl : '',
                        anchor: new window.google.maps.Point(8, 34),
                    }}
                />
            )}
            <Polyline
                path={props.polyLines}
                options={{
                    strokeColor: "blue",
                    strokeOpacity: 0.8,
                    strokeWeight: 5
                }}
            />
        </GoogleMap>
    </div>
);

export default TraceRoutesMap
