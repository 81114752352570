import React from "react";

import EditInputComponent from "../EditInputComponent/EditInputComponent";


const EditFormComponent = ({mediatitle, changeField, mediadescription}) => {
    return (
        <div className="media-component-info-list">
            <EditInputComponent
                stateFormFields={mediatitle}
                changeField={changeField}
                name='mediatitle'
                placeholder="Title (required)"
                maxLength={"140"}
                setHeight={20}
            />
            <EditInputComponent
                stateFormFields={mediadescription}
                changeField={changeField}
                name='mediadescription'
                placeholder="Description"
                maxLength={"400"}
                setHeight={40}
            />
        </div>
    )
}

export default EditFormComponent;
