import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {setMediaToken, toggleEdit} from "../actions/index";
import {formatTrimDuration} from "../utilityFunctions/formatTrimDuration";
import "./mediacomponent.css";
import moment from "moment";
import ReputationScore from "../ReputationScore/ReputationScore";
import EditShareModal from "../EditShareModal/EditShareModal";


class MediaComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playButton: false
        };
    }

    componentDidMount() {
        if (window.innerWidth <= 411) {
            this.setState({
                playButton: true
            })
        }
    }

    formatDate = date => {
        if(date === null)return;
        let dateSplit = date.split(" ");
        return (
            moment(dateSplit[0]).format("MM-DD-YY") +
            " " +
            moment(dateSplit[1], "HH:mm:ss").format("hh:mm A")
        );
    };

    togglePlay = () => {
        this.setState({
            playButton: !this.state.playButton
        });
    };

    handleMediaToken = () => {
        const {media, setMediaToken} = this.props
        setMediaToken(media.mediatoken)
    };

    getMediaData = (media, dataType) => {
        for (let k in media) {
            if (k.toLowerCase().indexOf(dataType.toLowerCase()) !== -1) {
                return this.formatDate(media[k]);
            }
        }
        return null;
    };
    handleChannel = () => {
        const {clientchannel} = this.props
        if (clientchannel) {
            return `/${clientchannel}`
        }
        return ''
    };

    shortenLength = (str, type) => {
        let limit = type === "title" ? 40 : 70;
        if (str) {
            if (str.length > limit) {
                let shortenedDesc = str.substring(0, limit);
                return shortenedDesc + ' ...';
            } else {
                return str
            }
        }
    };

    addDefaultSrc = (ev) => {
        ev.target.src = `${process.env.PUBLIC_URL}/videoCoverTemp.png`
    };

    generateCoverImagePos = (w,h) => {
      if (w && h){
          if (w < h){
              return "-50%"
          }
          else {
              return "0"
          }
      }
    };

    render() {
        const homechannel = localStorage.getItem("homechannel")
        const {media, toggleEdit, loginToken, clientchannel, setShareUrl} = this.props;
        const {
            getMediaData,
            // formatDate,
            shortenLength,
            generateCoverImagePos
        } = this;
        const {playButton} = this.state;

        return (
            <div className="media-component-wrapper">
                <div className="media-component-wrapper ">
                    <div
                        className="media-component-media-wrapper"
                        onMouseEnter={this.togglePlay}
                        onMouseLeave={this.togglePlay}
                        onClick={this.togglePlay}
                    >
                        {/*<div className="media-component-videosharemethod">*/}
                        {/*What should be the default display if Null?*/}
                        {/*    <div>*/}
                        {/*        {media.mediasharemethod*/}
                        {/*            ? media.mediasharemethod.toUpperCase()*/}
                        {/*            : ""}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {media.mediatype !== 'image' &&
                        <div className="media-component-timeblock">
                            <div className="media-component-timeclock">
                                {formatTrimDuration(media.mediaduration)}
                            </div>
                        </div>
                        }
                        <div className="media-component-media-play-list">
                            {playButton && (
                                <Link
                                    to={`${this.handleChannel()}/${media.mediatype[0]}/${
                                        media.mediakey
                                    }`}
                                    onClick={this.handleMediaToken}
                                >
                                    <i className="fa fa-play-circle" aria-hidden="true"/>
                                </Link>
                            )}
                        </div>
                        <div className="media-component-cover">
                            <img
                                onError={this.addDefaultSrc}
                                src={
                                    media.mediacoverurl
                                        ? media.mediacoverurl
                                        : `${process.env.PUBLIC_URL}/videoCoverTemp.png`
                                }
                                style={{
                                    top: generateCoverImagePos(media.mediacoverurlwidth, media.mediacoverurlheight)
                                }}
                                alt="Video"
                            />
                        </div>
                        <div className="media-component-subbarurl-wrapper">
                            {media.subbarurl &&
                            <img src={media.subbarurl} alt={""}/>
                            }
                        </div>
                    </div>
                    <div className="media-component-bottom-wrapper">
                        {/*<div className="media-component-title-time-wrapper row">*/}
                        {/*    <div className="media-component-creator col-6 nopadding">*/}
                        {/*        <img src={`${process.env.PUBLIC_URL}/icon-author.png`}/>*/}
                        {/*        <div>{media.clientname}</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="media-component-created-time col-6 nopadding">*/}
                        {/*        <img src={`${process.env.PUBLIC_URL}/icon-date.png`}/>*/}
                        {/*        <div>{formatDate(media.mediacreateddate)}</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <ReputationScore summaryBullets={media.summarybullet} viewCss={'rep-score-desktop'}/>
                        <div className="media-top-bar-wrapper">
                            <div className="media-component-media-title">
                                <h4>{media.mediatitle ? shortenLength(media.mediatitle, "title") : 'No Title'}</h4>
                            </div>
                            {
                            (
                                <div className="edit-kabob-wrapper"
                                     data-toggle="tooltip"
                                     title="Click To Edit"
                                >
                                    <EditShareModal
                                        watchurl={`${this.handleChannel()}/${media.mediatype[0]}/${
                                            media.mediakey}`}
                                        shareurl={media.mediasocialshareurl}
                                        setShareUrl={setShareUrl}
                                        mediasharemethod={media.mediasharemethod}
                                        containerStyle={{width: "100%"}}
                                        iconstyle={{color:"darkgrey",fontSize:'1.4rem'}}
                                    />
                                </div>
                            )
                            }
                        </div>
                        <div className="media-component-media-description">
                            {shortenLength(media.mediadescription) ? shortenLength(media.mediadescription, "description") : 'No Description'}
                        </div>
                        <div className="bottom-positioning">
                            <div className="half-view-views-date-wrapper">
                                <div>{media.mediaviewcount ? media.mediaviewcount : 0} Views</div>
                                <div>|</div>
                                <div>{getMediaData(media, 'publisheddate')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {
        params: params,
        loginToken: state.loginReducer.authentication.loginToken,
        clientchannel: state.loginReducer.authentication.clientchannel
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMediaToken: token => {
            dispatch(setMediaToken(token));
        },
        toggleEdit: (value) => {
            dispatch(toggleEdit(value))
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MediaComponent)
);
