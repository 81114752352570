export const formatDuration = (time) => {

    console.log("time", time);
    //takes in duration divide with reminder
    // Hours, minutes and seconds
    let hrs = ~~(time / 3600);
    let mins = ~~((time % 3600) / 60);
    let secs = ~~time % 60;

    let milliSecs = (Math.round(time * 100) / 100).toFixed(2);
    let timeSplit = milliSecs.split(".");

    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");

    if (time < 60) {
        ret += "" + timeSplit[0] + ':' + timeSplit[1];
    }

    if (time > 60) {
        ret += "" + secs + ":" + milliSecs.split(".")[1];
    } else {
        ret = ["0", ret].join('')
    }

    return ret;
};