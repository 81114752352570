import xapi from "../xapi";

/*
RESPONSE HEADERS CONTENT-TYPE IS ALWAYS TEXT/HTML
TO CATCH SERVER ERRORS WE PARSE TEXT FIRST AND
VALIDATE CONTENTS BEFORE PARSING AS JSON
*/

export function forgotPassword(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("forgotpassword");
        x.add("email", encodeURIComponent(payload.email));
        x.add("type", "client");
        let p = x.fetch();
        p.then(resp => resp.text()) // Transform the data into json
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    if (dataResult.result) {
                        let clientid = dataResult.result.clientid;
                        dispatch({type: "SET_CLIENT_ID", payload: clientid});
                        dispatch({type: "SET_USER_EMAIL", payload: payload.email});
                    } else {
                        //User really should be held at submit email until success
                        //and provided with "no email found" message otherwise.
                        dispatch({type: "", payload: []});
                    }
                } else {
                    throw new Error(result)
                }
            })
            .catch(err => {
                    console.error("The forgotPassword action has received the following error: " + err.message)
                }
            );
    };
}

export function verifyForgotPassword(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("verifyforgotten");
        x.add("clientid", payload.clientid);
        x.add("code", payload.code);
        x.add("type", "client");
        let p = x.fetch();
        p.then(resp => resp.text()) // Transform the data into json
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    //Currently limits user if they dont have client id
                    //Doesnt inform wrong verification
                    const {success, error} = dataResult.result;
                    if (dataResult.result && success) {
                        let reset_authtoken = dataResult.result.reset_authtoken;
                        dispatch({type: "SET_RESET_AUTH", payload: reset_authtoken});
                    }
                    if (error) {
                        dispatch({type: "VERIFICATION_ERROR", payload: error})
                    }
                } else {
                    throw new Error(result)
                }
            })
            .catch(err => {
                dispatch({
                    type: "VERIFICATION_ERROR",
                    payload: "A server Error has occurred. Please contact your Systems Administrator"
                });
                console.error("The verifyForgotPassword action has received the following error: " + err.message)
            });
    };
}

export function resetPassword(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("resetpassword");
        x.add("password", encodeURIComponent(payload.password));
        x.add("confirmpassword", encodeURIComponent(payload.confirmpassword));
        x.add("email", encodeURIComponent(payload.email));
        x.add("reset_authtoken", payload.reset_authtoken);
        x.add("type", "client");
        let p = x.fetch();
        p.then(resp => resp.text()) // Transform the data into json
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    if (dataResult.result) {
                        dispatch({
                            type: 'PASSWORD_UPDATE_MESSAGE',
                            payload: {
                                passwordUpdateMessage: 'Password Successfully Updated',
                                messageColor: '#28a745'
                            }
                        });
                        dispatch({type: 'CLEAR_RESET_AUTH'})
                    } else {
                        dispatch({
                            type: 'PASSWORD_UPDATE_MESSAGE',
                            payload: {
                                passwordUpdateMessage: dataResult.error,
                                messageColor: '#dc3545'
                            }
                        });
                    }
                } else {
                    throw new Error(result);
                }
            })
            .catch(err => {
                    dispatch({
                        type: 'PASSWORD_UPDATE_MESSAGE',
                        payload: {
                            passwordUpdateMessage: "A server Error has occurred. Please contact your Systems Administrator",
                            messageColor: '#dc3545'
                        }
                    });
                    console.error("The resetPassword action has received the following error: " + err.message)
                }
            );
    };
}

export function updatePassword(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("updatepassword");
        x.add("type", "client");
        x.add("email", encodeURIComponent(payload.email));
        x.add("currentpassword", encodeURIComponent(payload.currentPassword));
        x.add("password", encodeURIComponent(payload.newPassword));
        x.add("confirmpassword", encodeURIComponent(payload.confirmNewPassword));
        x.add("authtoken", localStorage.getItem("authtoken"));
        let p = x.fetch();
        p.then(resp => resp.text())
            .then(function (result) {
                if (result.includes("result")) {
                    const dataResult = JSON.parse(result);
                    let clientid = dataResult.result.clientid;
                    dispatch({type: "SET_CLIENT_ID", payload: clientid});
                    dispatch({type: "SET_CURRENT_STEP", payload: 2});
                    dispatch({
                        type: 'PASSWORD_UPDATE_MESSAGE',
                        payload: {
                            passwordUpdateMessage: 'Password Successfully Updated',
                            messageColor: '#28a745'
                        }
                    });
                }
                if (result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                        dispatch({
                        type: "CREATE_MESSAGE",
                        payload: dataResult.errors
                        });
                }
            })
            .catch(err => {
                    dispatch({
                        type: 'PASSWORD_UPDATE_MESSAGE',
                        payload: {
                            passwordUpdateMessage: "A server Error has occurred. Please contact your Systems Administrator",
                            messageColor: '#dc3545'
                        }
                    });
                    console.error("The updatePassword action has received the following error: " + err.message)
                }
            );
    };
}

export function accountCreateStepOne(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("create");
        x.add("type", "client");
        x.add("name", payload.name);
        x.add("email", encodeURIComponent(payload.email));
        x.add("password", encodeURIComponent(payload.password));
        x.add("confirmpassword", encodeURIComponent(payload.confirmpassword));
        x.add("channel", encodeURIComponent(payload.channel));
        let p = x.fetch();
        p.then(resp => resp.text()) // Transform the data into json
            .then(function (result) {
                    if (result.includes("result")) {
                        const dataResult = JSON.parse(result);
                            let clientid = dataResult.result.clientid;
                            dispatch({type: "SET_CLIENT_ID", payload: clientid});
                            dispatch({type: "SET_CURRENT_STEP", payload: 2});
                    }
                    if (result.includes("errors")) {
                        const dataResult = JSON.parse(result);
                            dispatch({
                                type: "CREATE_MESSAGE",
                                payload: dataResult.errors
                            });
                        }
                }
            )
            .catch(err => {
                    dispatch({
                        type: "CREATE_MESSAGE",
                        payload: "A server Error has occurred. Please contact your Systems Administrator"
                    });
                    console.error("The accountCreateStepOne action has received the following error: " + err.message)
                }
            );
    };
}

export function verifyCreate(payload) {
    return function dispatch(dispatch) {
        let x = new xapi("verify");
        x.add("clientid", payload.clientid);
        x.add("code", payload.code);
        x.add("type", "client");
        let p = x.fetch();
        p.then(resp => resp.text()) // Transform the data into json
            .then(function (result) {
                if (result.includes("result") || result.includes("errors")) {
                    const dataResult = JSON.parse(result);
                    if (dataResult.result) {
                        let reset_authtoken = dataResult.result.reset_authtoken;
                        dispatch({type: "SET_RESET_AUTH", payload: reset_authtoken});
                        dispatch({type: "SET_CURRENT_STEP", payload: 3})
                    } else {
                        dispatch({type: "SET_CURRENT_STEP", payload: 2});
                        dispatch({
                            type: "CREATE_MESSAGE",
                            payload: dataResult.errors
                        });
                    }
                } else {
                    throw new Error(result)
                }
            })
            .catch(err => {
                    dispatch({
                        type: "CREATE_MESSAGE",
                        payload: "A server Error has occurred. Please contact your Systems Administrator"
                    });
                    console.error("The verifyCreate action has received the following error: " + err.message)
                }
            );
    };
}

export const toggleUserMenu = () => {
    return {
        type: "TOGGLE_USER_MENU"
    };
};

export const clearMessage = () => {
    return {
        type: "CLEAR_USER_MESSAGE"
    }
};

export const clearPasswordMessage = () => {
    return {
        type: "CLEAR_PASSWORD_MESSAGE"
    };
};
