const initialState = {
    currentFrames: 0,
    currentMedia: {},
    duration: "",
    drawerOpen: false,
    error: null,
    frameError:null,
    fetchingMedia: false,
    framesRemaining: 0,
    locationData: {},
    mediaFrames: {},
    mediaHalfView: true,
    mediaKey: "",
    mediaType: '',
    mediaIsSet: false,
    mediaToken: "",
    sliderValue: 100,
    totalFrames: 0,
    requestFullfilled: false,
    apprequired: null,
    privateContent: false,
    sharemethod:null,
    videosequencegrades: {},
    imagesequencegrades: {},
    drawerHeightPercentage: 450,
    videoHeightPercentage: 450,
    displayTools: true,
    color: '',
    reason: '',
    traceRoutes: {},
    traceRoutesCoord: [],
    updateMessage: "",
    webshare: false,
    playbarPercentage: ''
};

const mediaSingleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCHING_SINGLE_MEDIA": {
            return {
                ...state,
                fetchingMedia: true,
                mediaIsSet: false,
                requestFullfilled: false,
                error: null,
                frameError: null,
                privateContent: false,
                updateMessage:""
            };
        }
        case 'RESET_REQUEST_FULLFILLED': {
            return {
                ...state,
                requestFullfilled: false
            }
        }
        case 'SET_PLAYBAR_PERCENTAGE' : {
            return {
                ...state,
                playbarPercentage: action.payload / 2
            }
        }
        case 'SET_TRACE_ROUTES': {
            let polylinesArray = [];

            for (let route in action.payload) {
                let routeObj = action.payload[route];
                polylinesArray.push({
                    lat: routeObj.lat,
                    lng: routeObj.long
                })
            }
            //Remove first value which comes in null
            polylinesArray.splice(0, 1);

            return {
                ...state,
                traceRoutes: action.payload,
                traceRoutesCoord: polylinesArray
            }
        }
        case 'SET_PRIVATE_FLAG': {
            return {
                ...state,
                privateContent: true,
                fetchingMedia: false
            }
        }
        case 'RESET_MEDIA_DEFAULTS': {
            return {
                ...state,
                mediaHalfView: true,
                drawerOpen: false,
                fullscreenDesktopOn: false,
                sliderValue: 100,
                drawerHeightPercentage: 450,
                videoHeightPercentage: 450,
            }
        }
        case 'TOGGLE_DISPLAY_TOOLS': {
            return {
                ...state,
                displayTools: !state.displayTools
            }
        }
        case 'UPDATE_SUCCESS': {
            return {
                ...state,
                requestFullfilled: true,
                updateMessage:""
            }
        }
        case "FETCH_MEDIA_ERROR":
            return {
                ...state,
                error: action.payload,
                fetchingMedia: false
            }
        case "SET_MEDIA_OBJECT": {
            return {
                ...state,
                currentMedia: action.payload.media,
                duration: action.payload.duration,
                fetchingMedia: false,
                apprequired: action.payload.apprequired,
                shareurl: action.payload.shareurl,
                sharemethod: action.payload.sharemethod
            };
        }
        case "SET_MEDIA_KEY": {
            return {
                ...state,
                mediaKey: action.payload.key,
                mediaType: action.payload.type,
            };
        }
        case "SET_MEDIA_FRAMES": {
            return {
                ...state,
                mediaFrames: action.payload.frames,
                currentFrames: action.payload.currentframes,
                totalFrames: action.payload.totalframes,
                locationData: action.payload.locationData
            };
        }
        case "SET_METDATA":
            return {
                ...state,
                mediaIsSet: true
            }
        case "UNSET_METDATA":
            return {
                ...state,
                mediaIsSet:false
            };
        case "SET_SOCIAL_SHARE_URL":
            return {
                ...state,
                shareurl: action.payload
            }
        case "SET_SLIDER_VALUE": {
            return {
                ...state,
                sliderValue: action.payload
            }
        }
        case "TOGGLE_DRAWER": {
            return {
                ...state,
                drawerOpen: !state.drawerOpen
            }
        }
        case "SET_TOKEN": {
            return {
                ...state,
                mediaToken: action.payload
            }
        }
        case 'SET_OPACITY': {
            return {
                ...state,
                sliderValue: action.payload
            }
        }
        case 'TOGGLE_HALF_SCREEN_VIEW' : {
            return {
                ...state,
                mediaHalfView: !state.mediaHalfView,
                sliderValue: 75
            }
        }
        case 'SET_ENCRYPTION_DATA' : {
            return {
                ...state,
                encryptionDataHalfView: action.payload
            }
        }
        case 'TOGGLE_FULL_SCREEN_DESKTOP' : {
            return {
                ...state,
                fullscreenDesktopOn: !state.fullscreenDesktopOn
            }
        }
        case 'UNSET_MEDIA' : {
            return initialState
        }
        case 'SET_OVERLAY_DATA' : {
            return {
                ...state,
                color: action.payload.color,
                reason: action.payload.reason
            }
        }
        case 'SCALE_HALF_DRAWER' : {
            return {
                ...state,
                drawerHeightPercentage: action.payload.value,
                videoHeightPercentage: action.payload.direction === 'up' ?
                    state.videoHeightPercentage - action.payload.value * .01 : state.videoHeightPercentage + action.payload.value * .01,
            }
        }
        case "CLEAR_MEDIA_OBJECT" : {
            return {
                ...state,
                currentMedia: action.payload.media,
                duration: action.payload.duration,
                apprequired: action.payload.apprequired,
                shareurl: action.payload.shareurl
            };
        }
        case "GET_FRAME_DATA_ERROR" :{
            return {
                ...state,
                frameError: action.payload
            }
        }
        case 'UPDATE_MEDIA_START':{
            return {
                ...state,
                updateMessage: action.payload,
                fetchingMedia:true
            }
        }
        case "SET_WEB_SHARE":{
            return {
                ...state,
                webshare:action.payload
            }
        }
        default:
            break;
    }
    return state;
};
export default mediaSingleReducer;
