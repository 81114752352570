import React, {useState} from "react"
import "./ProfileMediaUploader.css"
import UploadModal from "./UploadModal";

const ProfileMediaUploader = (props) => {


        console.log('incoming props', props.uploadType)

        return (
            <div>
                <div className="profilemedia-uploader"
                     style={{
                         width: window.innerWidth > 900 ? '24px' : '20px',
                         height: window.innerWidth > 900 ? '24px' : '20px'
                     }}
                     onClick={() => props.toggleModal(props.uploadType)}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/upload-camera-icon.png`}
                        alt=""
                    />
                </div>
                {
                    props.uploadToggle &&
                    <UploadModal
                        toggleModal={props.toggleModal}
                        uploadSection={props.uploadType}
                    />
                }
            </div>

        );
    }
;

export default ProfileMediaUploader;