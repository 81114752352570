import React from "react";


const ReturnRestrictedContentMessage = () => (
    <div className="icon-wrapper" style={{paddingTop: "3em"}}>
<div>The VideoLock app is required to view this content. <br/>Download for IOS or Android</div>
<i className="fa fa-android" aria-hidden="true"></i>
    <i className="fa fa-apple" aria-hidden="true"></i>
    </div>
);

export default ReturnRestrictedContentMessage;