import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'

import {searchMedia, mediaFilter, sortMedia} from './actions'
// import DisplayGridOrList from './DisplayGridOrList'
import './navbar.css'
let moment = require('moment');

class NavBarSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false,
            mediaShareType: null,
            filterMediaType: null,
            currentToggle: "dateToggle",
            direction: false
        };
    }

    handleQuery = (e) => {
        e.preventDefault();
        const {searchMedia} = this.props;
        searchMedia(e.target.value)
    };

    // tempViewSort = () => {
    //     this.setState((prevState, props) => {
    //         return {viewsToggle: !prevState.viewsToggle}
    //     })
    // };

    dateSort = (key, toggleType) => {
        //NULL VALUES AND MISSING PROPERTY ARE SORTED TO BOTTOM
        let direction = this.state['direction'];
        const {allMedia, sortMedia} = this.props;
        console.log(allMedia)
        let sortedArray = allMedia.sort((a, b) => {
            if (key) {
                if (a[key] === null) {
                    return 1;
                }
                // nulls sort after anything else
                else if (b[key] === null) {
                    return -1;
                }
                // otherwise, if we're ascending, lowest sorts first
                else if (!direction) {
                    // return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1;
                    return  new moment(a[key]) - new moment(b[key]);
                }
                // if descending, highest sorts first
                else {
                    // return a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1;
                    return  new moment(b[key]) - new moment(a[key]);
                }
            }
            return ''
        });
        this.setState({
            currentToggle: toggleType,
            direction: !this.state.direction
        });
        console.log(sortedArray.map(media => media.mediapublisheddate))
        sortMedia(sortedArray);
    };

    titleSort = (key, toggleType) => {
        //NULL VALUES AND MISSING PROPERTY ARE SORTED TO BOTTOM
        let direction = this.state['direction'];
        const {allMedia, sortMedia} = this.props;
        let sortedArray = allMedia.sort((a, b) => {
            if (key) {
                if (a[key] === null) {
                    return 1;
                }
                // nulls sort after anything else
                else if (b[key] === null) {
                    return -1;
                } else if (a[key].toUpperCase() === b[key].toUpperCase()) {
                    return 0;
                }
                // otherwise, if we're ascending, lowest sorts first
                else if (direction) {
                    return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1;
                }
                // if descending, highest sorts first
                else {
                    return a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1;
                }
            }
            return ''
        });
        this.setState({
            currentToggle: toggleType,
            direction: !this.state.direction
        });
        sortMedia(sortedArray);
    };

    sortViews = (key, toggleType) => {
        //NULL VALUES AND MISSING PROPERTY ARE SORTED TO BOTTOM
        let direction = this.state['direction'];
        const {allMedia, sortMedia} = this.props;
        let sortedArray = allMedia.sort((a, b) => {
            if (a[key] === b[key]) {
                return 0;
            }
            // nulls sort after anything else
            else if (a[key] === null || !a.hasOwnProperty(key)) {
                return 1;
            } else if (b[key] === null || !b.hasOwnProperty(key)) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (!direction) {
                return a[key] - b[key]
            }
            // if descending, highest sorts first
            else {
                return b[key] - a[key]
            }
        });

        this.setState({
            currentToggle: toggleType,
            direction: !this.state.direction
        });
        sortMedia(sortedArray);
    };


    render() {
        const {isSingleView, clientchannel} = this.props;
        const backGround = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/icon-search.png)`
        };

        return (
            <div className="row justify-content-center mainTopNavFilter ">
                <form className="mainFormSearchFilter">
                    <div className={`row ${!isSingleView && clientchannel ? `media-set` : `hide-opacity`}`}>
                        <div className="navbar-search-wrapper">
                            <div className="filter-component-wrapper"
                                 onClick={() => this.dateSort('mediapublisheddate', "dateToggle")}
                            >
                                <div className="filter-title">Date
                                </div>
                                <div>
                                    {this.state.currentToggle === 'dateToggle' &&
                                    <i
                                        className={this.state.direction ? "fa fa-angle-up" : "fa fa-angle-down"}
                                        aria-hidden="true"
                                    >
                                    </i>}
                                </div>
                            </div>
                            <div className="filter-component-wrapper"
                                 onClick={() => this.titleSort('mediatitle', "titleToggle")}
                            >
                                <div className="filter-title">Title
                                </div>
                                <div>
                                    {this.state.currentToggle === 'titleToggle' &&
                                    <i
                                        className={this.state.direction ? "fa fa-angle-up" : "fa fa-angle-down"}
                                        aria-hidden="true"
                                    >
                                    </i>}
                                </div>
                            </div>
                            <div className="filter-component-wrapper"
                                 onClick={() => this.sortViews('mediaviewcount', "viewsToggle")}
                            >
                                <div className="filter-title">
                                    Views
                                </div>
                                <div>
                                    {this.state.currentToggle === 'viewsToggle' &&
                                    <i
                                        className={this.state.direction ? "fa fa-angle-up" : "fa fa-angle-down"}
                                        aria-hidden="true"
                                    >
                                    </i>}
                                </div>
                            </div>

                        </div>
                        {/*<div*/}
                        {/*    className="form-group col-md-4"*/}
                        {/*    onMouseEnter={this.handleMouseHover}*/}
                        {/*    onMouseLeave={this.handleMouseHover}*/}
                        {/*>*/}
                        {/*    <div className="filterOptionsHover">*/}
                        {/*        <div*/}
                        {/*            id="selectMediaPublicPrivate"*/}
                        {/*            className="form-control mainFilterFormInput"*/}
                        {/*        >*/}
                        {/*            <div>Filter...</div>*/}
                        {/*            <div><img alt="" src={`${process.env.PUBLIC_URL}/icon-filter.png`}/></div>*/}
                        {/*        </div>*/}
                        {/*        {this.state.isHovering && (*/}
                        {/*            <div>*/}
                        {/*                <div className="navbar-drop-row filterOptions">*/}
                        {/*                    <div className="navbar-radio-wrapper">*/}
                        {/*                        <div>*/}
                        {/*                            {mediaOptions.map((option, i) =>*/}
                        {/*                                <div className="form-check form-check-inline"*/}
                        {/*                                key={i}*/}
                        {/*                                >*/}
                        {/*                                    <input*/}
                        {/*                                        className="form-check-input"*/}
                        {/*                                        type="radio"*/}
                        {/*                                        name={option.name}*/}
                        {/*                                        id={option.id}*/}
                        {/*                                        value={option.value.toLocaleLowerCase()}*/}
                        {/*                                        defaultChecked={filter[option.name] === option.value.toLowerCase()}*/}
                        {/*                                        onClick={(e) => this.handleFilter(e, option.value)}*/}
                        {/*                                    />*/}
                        {/*                                    <label*/}
                        {/*                                        className="form-check-label"*/}
                        {/*                                        htmlFor={option.id}*/}

                        {/*                                    >*/}
                        {/*                                        {option.value}*/}
                        {/*                                    </label>*/}
                        {/*                                </div>*/}
                        {/*                            )*/}
                        {/*                            }*/}
                        {/*                        </div>*/}
                        {/*                        <div>*/}
                        {/*                            {filterOptions.map((option, i) => {*/}
                        {/*                                return (*/}
                        {/*                                    <div key ={i} className="form-check form-check-inline">*/}
                        {/*                                        <input*/}
                        {/*                                            className="form-check-input"*/}
                        {/*                                            type="radio"*/}
                        {/*                                            name={option.name}*/}
                        {/*                                            id={option.id}*/}
                        {/*                                            value={option.value[0].toLocaleLowerCase()}*/}
                        {/*                                            defaultChecked={filter[option.name] === option.value[0].toLowerCase()}*/}
                        {/*                                            onClick={(e) => this.handleFilter(e, option.value[0])}*/}
                        {/*                                        />*/}
                        {/*                                        <label*/}
                        {/*                                            className="form-check-label"*/}
                        {/*                                            htmlFor={option.id}*/}
                        {/*                                        >*/}
                        {/*                                            {option.value}*/}
                        {/*                                        </label>*/}
                        {/*                                    </div>*/}
                        {/*                                )*/}
                        {/*                            })*/}
                        {/*                            }*/}
                        {/*                        </div>*/}

                        {/*                    </div>*/}
                        {/*                    <div className="col-4">*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<DisplayGridOrList/>*/}
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        filter: state.navReducer.filter,
        clientid: state.loginReducer.authentication.clientid,
        clientchannel: state.loginReducer.authentication.clientchannel,
        loginToken: state.loginReducer.authentication.loginToken,
        allMedia: state.MediaReducer.allMedia,
        isSingleView: state.MediaReducer.isSingleView
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchMedia: (query) => {
            dispatch(searchMedia(query))
        },
        mediaFilter: (filter) => {
            dispatch(mediaFilter(filter))
        },
        sortMedia: (sortedArray) => {
            dispatch(sortMedia(sortedArray))
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarSearch));
