import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import "./desktopnavpopup.css"

class ProfileOptionsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {back, logout} = this.props;

        return (
            <div>
                <div onClick={() => back('Home')}>
                    <img className="back-arrow" src={`${process.env.PUBLIC_URL}/arrow_back.png`} alt=""/>
                </div>
                <div className="button-container-center">
                    {/*<div*/}
                    {/*    className="navpop-up-blue-btn"*/}
                    {/*    onClick={() => resetPassword({})}*/}
                    {/*>*/}
                    {/*    Change Email*/}
                    {/*</div>*/}
                    <div
                        className="navpop-up-blue-btn"
                        onClick={(e) => back(e.target.textContent)}
                    >
                        Change Password
                    </div>
                    {
                        //No current api for channel change
                    }
                    {/*<button*/}
                    {/*    className="navpop-up-blue-btn remove-styles"*/}
                    {/*    type="submit"*/}
                    {/*    onClick={() => resetPassword({})}*/}
                    {/*>*/}
                    {/*    Change Channel Name*/}
                    {/*</button>*/}
                    {/*<span*/}
                    {/*    className="login-questions-span"*/}
                    {/*    onClick={(e) => logout(e)}*/}
                    {/*>*/}
                    {/*    Logout*/}
                    {/*</span>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, {match: {params}}) => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileOptionsPanel))
