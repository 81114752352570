export default class xapi {
  constructor(action, params, url, lasturl, resolve, reject, sendpromise) {

    const baseUrl = "https://dev.api.deeptruth.com/xapi.php";
    // const baseUrl = "https://prod.api.deeptruth.com/xapi.php";
    this.action = null;
    this.params = {};
    this.url = origin.includes("localhost:") || origin.includes("dev")
        ? baseUrl
        : "https://prod.api.deeptruth.com/xapi.php";
    this.lasturl = null;
    this.resolve = null;
    this.reject = null;
    this.sendpromise = null;

    // console.log(this.params);

    if (typeof fetch === "undefined")
      alert(
        "You must include the 'fetch' library to use xapi \nhttps://github.com/github/fetch"
      );
    this.action = action;
  }

  add(param, val) {
    this.params[param] = val;
  }

  getFullUrl = () => {
    let fullUrl = this.url + "?action=" + this.action;
    for (let i in this.params) fullUrl += "&" + i + "=" + this.params[i];
    // console.log("THIS IS THE FULL URL: " + fullUrl);
    return fullUrl;
  };

  static parse(response) {
    return JSON.parse(response);
  }

  getHeaders = function(h) {
    if (!h) h = {};
    return h;
  };

  fetch() {
    return fetch(this.getFullUrl(), this.getHeaders()).then(function(response) {
      // let responseClone = response.clone(); // Convert to JSON
      // responseClone.json().then(function (myobj) {
      //   if (document.getElementById("developerconsole") != null) {
      //     document.getElementById("developerconsole").innerHTML = document.getElementById("developerconsole").innerHTML + myobj.devoutput
      //   }
      // });
      return response;
    });
  }
}
